import {createAsyncThunk} from '@reduxjs/toolkit'
import {$api, $authApi} from '.'
import {apiRoutes} from '../config/api'
import socket from '../config/socket'
import {setAuth, setToken, setUser} from '../store/reducers/authSlice'

const authRegister = async (data) => {
    const response = await $api.post(apiRoutes.REGISTRATION, data)
    return response
}

const loginTelegram = async (data) => {
    const response = await $api.post(apiRoutes.LOGIN_TELEGRAM, data)
    return response?.data
}

const loginVk = async (data) => {
    const response = await $api.post(apiRoutes.LOGIN_VK, data)
    return response?.data
}

const authActivate = async (data) => {
    const response = await $authApi.post(apiRoutes.ACTIVATE, data)
    return response?.data
}

const authNewKeyActivate = async (data) => {
    const response = await $authApi.post(apiRoutes.NEW_KEY_ACTIVATE, data)
    return response?.data
}

const authPasswordRecovery = async (data) => {
    const response = await $api.post(apiRoutes.RECOVERY, data)
    return response?.data
}

const authNewKeyRecovery = async (data) => {
    const response = await $authApi.post(apiRoutes.NEW_KEY_RECOVERY, data)
    return response?.data
}

const authEditEmail = async (data) => {
    const response = await $authApi.post(apiRoutes.EDIT_EMAIL, data)
    return response?.data
}

const checkAuth = async () => {
    const response = await $authApi.post(apiRoutes.CHECK)
    if (response && response.status === 200) {
        socket.io.opts.query = {userId: response.data.id ?? false}
        socket.connect()
    }
    return response?.data
}

const updateUser = async () => {
    const response = await $authApi.post(apiRoutes.CHECK)
    return response?.data
}

const login = async (payloads) => {
    const response = await $api.post(apiRoutes.LOGIN, payloads)
    return response?.data
}

const logout = async () => await $authApi.post(apiRoutes.LOGOUT)

const refreshAuth = createAsyncThunk('auth/refresh', async (payloads, thunkAPI) => {
    try {
        const response = await $authApi.post(apiRoutes.REFRESH)
        if (response?.data && response.status === 200) {
            thunkAPI.dispatch(setToken(response.data.token))
        }
        return response.data
    } catch (error) {
        thunkAPI.dispatch(setUser(false))
        thunkAPI.dispatch(setAuth(false))
        thunkAPI.dispatch(setToken(false))
        socket.disconnect()

        return thunkAPI.rejectWithValue(error)
    }
})

export {
    authActivate,
    authEditEmail,
    authNewKeyActivate,
    authNewKeyRecovery,
    authPasswordRecovery,
    authRegister,
    checkAuth,
    login,
    loginTelegram,
    loginVk,
    logout,
    refreshAuth,
    updateUser,
}
