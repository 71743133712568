import React, {memo, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {IoCreateOutline, IoTrashOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import ImageCropper from './Cropper'

const DragDropFile = memo(
    ({
        accept = false,
        onChange,
        max = 10,
        maxAspect = false,
        aspect = false,
        file,
        multiple = false,
        title = 'Выберите изображение',
        onDelete,
        className,
    }) => {
        const {t} = useTranslation()
        const [dragActive, setDragActive] = useState(false)
        const [images, setImages] = useState([])
        const [image, setImage] = useState({
            show: false,
            data: false,
        })
        const inputRef = useRef(null)

        const handleDrag = function (e) {
            e.preventDefault()
            e.stopPropagation()
            if (e.type === 'dragenter' || e.type === 'dragover') {
                setDragActive(true)
            } else if (e.type === 'dragleave') {
                setDragActive(false)
            }
        }

        const handleDrop = function (e) {
            e.preventDefault()
            e.stopPropagation()
            setDragActive(false)
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                if (e.dataTransfer.files.length > max) {
                    return NotificationManager.error(`${t('Максимально')} ${max} ${t('файлов')}`)
                }
                onChange(e.dataTransfer.files)
            }
        }

        const handleChange = function (e) {
            e.preventDefault()
            if (e.target.files && e.target.files[0]) {
                if (e.target.files.length > max) {
                    return NotificationManager.error(`${t('Максимально')} ${max} ${t('файлов')}`)
                }
                onChange(e.target.files)
            }
        }

        const onButtonClick = () => {
            inputRef.current.click()
        }

        const onButtonClickDelete = (index) => {
            let fileListArr = Array.from(file)
            let newMedias = fileListArr.filter((e, index2) => index2 != index)
            let newDelete = fileListArr.find((e, index2) => index2 === index)
            onDelete && onDelete({file: newMedias, delete: newDelete})
        }

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file)

                fileReader.onload = () => {
                    resolve(fileReader.result)
                }

                fileReader.onerror = (error) => {
                    reject(error)
                }
            })
        }

        useEffect(() => {
            console.log(124124)
            async function processArray() {
                setImages([])
                if (file && file[0]?.name) {
                    Array.from(file).forEach(async (item, index) => {
                        await convertBase64(file[index]).then(
                            (e) =>
                                e &&
                                setImages((prev) =>
                                    multiple
                                        ? [...prev, {file: file[index], media: e}]
                                        : [{file: file[index], media: e}]
                                )
                        )
                    })
                } else if (file?.name) {
                    await convertBase64(file).then(
                        (e) =>
                            e && setImages((prev) => (multiple ? [...prev, {file, media: e}] : [{file, media: e}]))
                    )
                }
            }
            processArray()
        }, [file, multiple])

        return (
            <>
                <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    className="d-none"
                    multiple={multiple}
                    accept={accept ?? 'image/*'}
                    onChange={handleChange}
                />
                {file && images?.length > 0 ? (
                    <>
                        <div className={'upload-box product ' + (className ? className : '')}>
                            {images?.length > 0 &&
                                images.map((e, index) => (
                                    <>
                                        <img
                                            src={e.media}
                                            className={multiple ? 'upload-box-img multiple' : 'upload-box-img'}
                                        />
                                        <div className="tool bottom-0 p-2 position-absolute w-100 d-flex flex-row justify-content-between">
                                            <a
                                                onClick={() =>
                                                    setImage({
                                                        show: true,
                                                        data: {index, file: e.file},
                                                    })
                                                }
                                            >
                                                <IoCreateOutline size={20} />
                                            </a>
                                            <a onClick={() => onButtonClickDelete(index)}>
                                                <IoTrashOutline size={20} />
                                            </a>
                                        </div>
                                    </>
                                ))}
                            {images?.length === 0 && (
                                <div className="d-flex justify-content-center">
                                    <a className="btn btn-sm btn-primary-outline" onClick={onButtonClick}>
                                        {t(multiple ? 'Выбрать файлы' : 'Выбрать файл')}
                                    </a>
                                </div>
                            )}
                        </div>
                        <ImageCropper
                            file={image.data}
                            show={image.show}
                            maxAspect={maxAspect}
                            aspect={aspect}
                            setShow={(e) => setImage((info) => ({...info, show: e}))}
                            onComplete={(e) => {
                                images[e.index] = {file: e.file, media: e.blob}
                                setImages(images)
                                onChange(images.map((e) => e.file))
                            }}
                        />
                    </>
                ) : (
                    <div
                        className={className ? className : ''}
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <div
                            htmlFor="input-file-upload"
                            className={
                                'upload-box d-flex flex-column align-items-center justify-content-center' +
                                (dragActive ? ' active' : '')
                            }
                        >
                            <img src="/images/upload-file.svg" height={100} />
                            <h5 className="mt-3 mb-2 fs-08 fw-7">{t(title)}</h5>
                            <p className="text-muted text-center fs-07">
                                {t('Перетащите файл сюда или нажмите')}{' '}
                                <a className="text-success" onClick={onButtonClick}>
                                    {t('обзор')}
                                </a>{' '}
                                {t('чтобы загрузить файл с компьютера')}
                            </p>
                            <a className="btn btn-sm btn-primary-outline" onClick={onButtonClick}>
                                {t(multiple ? 'Выбрать файлы' : 'Выбрать файл')}
                            </a>
                        </div>
                        {dragActive && (
                            <div
                                className="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                            ></div>
                        )}
                    </div>
                )}
            </>
        )
    }
)

export default DragDropFile
