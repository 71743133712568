import moment from 'moment'
import React, {useCallback, useLayoutEffect, useRef, useState} from 'react'
import Col from 'react-bootstrap/Col'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Row from 'react-bootstrap/Row'
import {useTranslation} from 'react-i18next'
import {IoChatbubblesOutline, IoChevronForwardOutline, IoEyeOutline, IoLayersOutline, IoStar} from 'react-icons/io5'
import {useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import Age from '../components/Age'
import ChapterItem from '../components/ChapterItem'
import Empty from '../components/Empty'
import Meta from '../components/Meta'
import Tags from '../components/Tags'
import Genre from '../components/Genre'
import Button from '../components/UI/Button'
import {customImage, generateNumber} from '../helpers/all'
import {createBookmark, getBook} from '../services/book'
import Loader from '../components/UI/Loader'
import Type from '../components/Type'
import {Badge} from 'react-bootstrap'
import {NotificationManager} from 'react-notifications'
import CustomModal from '../components/CustomModal'
import Stars from '../components/Stars'
import {AiFillStar} from 'react-icons/ai'
import Textarea from '../components/UI/Textarea'

const Book = ({auth}) => {
    const {bookId} = useParams()
    const {state} = useLocation()
    const {t} = useTranslation()

    const isAuth = useSelector((state) => state.auth.isAuth)
    const birthday = useSelector((state) => state.auth.user.birthday)

    const refBookmark = useRef()
    const [review, setReview] = useState({
        show: false,
        value: null,
        comment: null,
    })
    const [book, setBook] = useState({
        loading: true,
        item: state,
    })
    const [chapters, setChapters] = useState(false)
    const index =
        book?.item?.chapters?.length > 0 ? book.item.chapters.findIndex((item) => item?.orders?.length > 0) : -1

    const progress = useSelector(
        (state) =>
            state?.chapter?.data?.length > 0 &&
            book?.item?.id &&
            state?.chapter?.data.find((e) => e.bookId === book.item.id)
    )
    const image = customImage({item: book.item})

    const onLoad = useCallback(() => {
        getBook(bookId, auth)
            .then((res) => setBook({loading: false, item: res}))
            .catch(() => setBook((data) => ({...data, loading: false})))
    }, [bookId, auth])

    const onBookmark = useCallback(() => {
        createBookmark(bookId).then((res) => {
            refBookmark.current.className = res ? 'bookmark activeView' : 'bookmark hideView'

            NotificationManager.success(res ? 'Публикация добавлена в избранные' : 'Публикация удалена из избранных')
        })
    }, [book, bookId])

    const onReview = useCallback(() => {
        createReview(bookId).then((res) => {
            refBookmark.current.className = res ? 'bookmark activeView' : 'bookmark hideView'

            NotificationManager.success(res ? 'Оценка успешно добавлена' : 'Ваша оценка удалена')
        })
    }, [book, bookId])

    useLayoutEffect(() => {
        onLoad()
    }, [bookId, auth])

    if (book?.loading && !state?.id) {
        return <Loader full />
    }

    if (!book?.item?.id) {
        return (
            <Empty
                text={t('Такой публикации нет')}
                desc={t('Попробуйте зайти позже')}
                button={
                    <Link className="btn-primary" to="/">
                        {t('Перейти в каталог')}
                    </Link>
                }
            />
        )
    }

    if (
        auth &&
        ((!birthday && book.item.age > 16) ||
            (moment().diff(moment(birthday), 'year') < book.item.age && book.item.age > 16))
    ) {
        return (
            <>
                <Meta
                    title={`${book.item.title} читать онлайн на Gikami`}
                    image={image}
                    description={book.item.desc}
                />
                <Empty
                    text={`${t('Контент')} ${book.item.age}+`}
                    desc={t(
                        'Данное произведение содержит информацию, не предназначенную для просмотра лицам не достигшими возраста 18 лет'
                    )}
                    button={
                        <Link className="btn-primary" to="/profile/edit">
                            {t('Указать возраст')}
                        </Link>
                    }
                />
            </>
        )
    }

    return (
        <>
            <Meta title={`${book.item.title} читать онлайн на Gikami`} image={image} description={book.item.desc} />
            <Row>
                <Col md={12} xl="auto">
                    <div className="position-relative d-flex book-img align-self-center mb-3 flex-column m-auto">
                        <div className="info-item d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                {book?.item?.type && <Type fixed value={book.item.type} />}
                                {book?.item?.age && <Age fixed value={book.item.age} />}
                            </div>
                        </div>
                        <img
                            src={image}
                            alt={book.item.title}
                            className="book-img mb-2 d-flex m-auto align-self-center"
                        />
                        <div className="d-flex flex-row align-items-center justify-content-center pb-2 line-height-100">
                            <a
                                onClick={() => isAuth && setChapters((prev) => ({...prev, show: true}))}
                                className="d-flex flex-row align-items-center text-center align-self-center me-3 line-height-100"
                            >
                                <IoLayersOutline size={20} />
                                <span className="ms-1 fw-6 fs-08">{generateNumber(book?.item?.chapterCount)}</span>
                            </a>
                            <div className="d-flex flex-row align-items-center text-center align-self-center line-height-100 me-3">
                                <IoChatbubblesOutline size={20} />
                                <span className="ms-1 fw-6 fs-08">{generateNumber(book?.item?.commentCount)}</span>
                            </div>
                            <div className="d-flex flex-row align-items-center text-center align-self-center line-height-100">
                                <IoEyeOutline size={20} />
                                <span className="ms-1 fw-6 fs-08">{generateNumber(book?.item?.viewCount)}</span>
                            </div>
                        </div>
                    </div>
                    {book.item?.user?.id && (
                        <div>
                            <Link
                                className="box p-2 mb-3 d-flex flex-row align-items-center justify-content-between"
                                to={'/user/' + book.item?.user?.id}
                            >
                                <div className="d-flex flex-row align-items-center">
                                    <div className="avatar-min pe-2">
                                        <img
                                            src={customImage({item: book.item?.user, type: 'user', size: 'mini'})}
                                        />
                                    </div>
                                    <div>
                                        <div className="fs-07 text-muted">
                                            {book.item?.user?.options?.type == 'translator'
                                                ? t('Переводчик')
                                                : book.item?.user?.options?.type == 'author'
                                                ? t('Автор')
                                                : t('Читатель')}
                                        </div>
                                        <div className="fs-08 line-height-100">
                                            {book.item?.user?.firstName ?? book.item?.user?.nickname}
                                            {book.item?.user?.options?.type == 'translator' && (
                                                <img
                                                    title="Подтвержденный аккаунт"
                                                    className="ms-1"
                                                    width={15}
                                                    src="/images/verified.svg"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <IoChevronForwardOutline className="text-muted" />
                                </div>
                            </Link>
                        </div>
                    )}
                </Col>
                <Col>
                    <div className="d-flex mb-3 justify-content-between">
                        <div>
                            <h2 className="mb-0">{book.item.title}</h2>
                            <div className="text-muted fs-08">
                                {book.item?.options?.title_en}
                                {book.item?.options?.year ? ' ' + book.item.options.year + 'г' : null}
                                {book.item?.options?.country ? ' ' + book.item.options.country : null}
                            </div>
                        </div>
                        {isAuth ? (
                            <div className="d-flex align-items-center">
                                <a
                                    onClick={() => setReview((prev) => ({...prev, show: !prev?.show}))}
                                    className="box star d-flex align-items-center line-height-100 me-3"
                                >
                                    <AiFillStar size={18} className="icon" />
                                    <span className="fs-09 fw-6 d-flex ms-1">{book?.item?.rating ?? 0}</span>
                                </a>
                                <a
                                    ref={refBookmark}
                                    className={'bookmark ' + (book.item?.bookmarkStatus ? ' active' : '')}
                                    onClick={onBookmark}
                                >
                                    <svg viewBox="0 0 32 32">
                                        <g>
                                            <path d="M27 4v27a1 1 0 0 1-1.625.781L16 24.281l-9.375 7.5A1 1 0 0 1 5 31V4a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <div className="box star d-flex align-items-center line-height-100 me-3">
                                    <IoStar size={18} className="icon" />
                                    <span className="fs-09 fw-6 d-flex ms-1">{book?.item?.rating ?? 0}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <p className="mb-2 fs-09 fw-bold">{t('Жанр')}</p>
                    <Genre data={book.item.categories} />

                    {book?.item?.desc?.length > 0 && (
                        <div className="my-2">
                            <p className="mb-2 fs-09 fw-bold">{t('Описание')}</p>
                            <p className="fs-09 text-muted">{book.item.desc}</p>
                        </div>
                    )}
                    {book?.item?.options?.public?.length > 0 && (
                        <div className="my-2">
                            <span className="fs-09 fw-5 me-2">{t('Статус тайтла')}:</span>
                            {!book.item.options.public || book.item.options.public == 'continues' ? (
                                <Badge bg="secondary">Продолжается</Badge>
                            ) : book.item.options.public == 'end' ? (
                                <Badge bg="success">Завершен</Badge>
                            ) : (
                                book.item.options.public == 'stopped' && <Badge bg="danger">Заморожен</Badge>
                            )}
                        </div>
                    )}
                    {book?.item?.options?.translation?.length > 0 && (
                        <div className="my-2">
                            <span className="fs-09 fw-5 me-2">{t('Статус перевода')}:</span>
                            {!book.item.options.translation || book.item.options.translation == 'continues' ? (
                                <Badge bg="secondary">Продолжается</Badge>
                            ) : book.item.options.translation == 'end' ? (
                                <Badge bg="success">Завершен</Badge>
                            ) : (
                                book.item.options.translation == 'stopped' && <Badge bg="danger">Заморожен</Badge>
                            )}
                        </div>
                    )}
                    {book?.item?.options?.maxChapter > 0 && (
                        <div className="my-2">
                            <span className="fs-09 fw-5 me-2">{t('Всего глав')}:</span>
                            <span className="fs-09">{book.item.options.maxChapter}</span>
                        </div>
                    )}
                    {book?.item?.author && (
                        <div className="my-2">
                            <span className="fs-09 fw-5 me-2">{t('Автор')}:</span>
                            <span className="fs-09">{book.item.author}</span>
                        </div>
                    )}
                    {book?.item?.tags && (
                        <div className="mb-4">
                            <p className="mb-2 fs-09 fw-bold">{t('Теги')}</p>
                            <Tags data={book.item.tags} />
                        </div>
                    )}
                    {isAuth ? (
                        <div className="book-bottom-container">
                            <div className="d-flex book-bottom">
                                <div className="d-flex pe-2">
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => setChapters((prev) => ({...prev, show: true}))}
                                    >
                                        <IoLayersOutline size={20} />
                                    </Button>
                                </div>

                                <div className="d-flex w-100">
                                    {progress?.id ? (
                                        <Link
                                            to={`/public/${book.item?.uid ?? book.item?.id}/read/${
                                                book.item.chapters.find((e) => e.id === progress.id)?.id
                                            }`}
                                            className="btn btn-primary"
                                            state={{
                                                book: book.item,
                                                item: book.item.chapters.find((e) => e.id === progress.id),
                                            }}
                                        >
                                            {t('Продолжить')}
                                        </Link>
                                    ) : book.item?.chapters?.length > 0 && index != -1 ? (
                                        <Link
                                            to={`/public/${book.item?.uid ?? book.item?.id}/read/${
                                                book.item.chapters[index].id
                                            }`}
                                            className="btn btn-primary"
                                            state={{
                                                book: book.item,
                                                item: book.item.chapters[index],
                                            }}
                                        >
                                            {t('Продолжить')}
                                        </Link>
                                    ) : book.item?.chapters?.length === 0 ? (
                                        <button className="btn btn-secondary">{t('Пока нет глав')}</button>
                                    ) : book?.loading ? (
                                        <Button className="btn-secondary" isLoading={true}></Button>
                                    ) : (
                                        <Link
                                            to={`/public/${book.item?.uid ?? book.item?.id}/read/${
                                                index === -1 && book.item.chapters?.length > 0
                                                    ? book.item.chapters[book.item.chapters.length - 1].id
                                                    : ''
                                            }`}
                                            className="btn btn-primary"
                                            state={
                                                index === -1 && book.item.chapters?.length > 0
                                                    ? {
                                                          item: book.item.chapters[book.item.chapters.length - 1],
                                                          book: book.item,
                                                      }
                                                    : null
                                            }
                                        >
                                            {t('Читать')}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="book-bottom-container">
                            <div className="d-flex book-bottom">
                                <Link className=" btn btn-secondary" to="/login">
                                    {t('Войти в профиль')}
                                </Link>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            <CustomModal
                show={review.show}
                setShow={(e) => setReview((prev) => ({...prev, show: e}))}
                title={t('Оставить оценку')}
            >
                <Stars value={review.value} onClick={(e) => setReview((prev) => ({...prev, value: e}))} />
                <div className="mt-3">
                    <Textarea placeholder={t('Введите текст отзыва')} />
                </div>
                <div className="w-100 d-flex justify-content-center w-100">
                    <Button disabled={!review.value} className="btn-sm mt-3 w-100" onClick={() => onReview()}>
                        {t('Добавить оценку')}
                    </Button>
                </div>
            </CustomModal>
            <Offcanvas show={chapters} onHide={() => setChapters(false)} placement="end" name="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fw-bold">{t('Главы')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="custom-scroll">
                    {book.item?.chapters?.length > 0 ? (
                        book.item.chapters.map((e) => <ChapterItem key={e.id} item={e} book={book.item} />)
                    ) : (
                        <Empty
                            text={t('Нет публикаций')}
                            desc={t('Попробуйте зайти позже')}
                            button={
                                <Link className="btn-primary" to="/">
                                    {t('Перейти в каталог')}
                                </Link>
                            }
                        />
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Book
