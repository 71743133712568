import moment from 'moment'
import React, {memo} from 'react'
import {Link} from 'react-router-dom'
import {customPrice} from '../helpers/all'
import {useTranslation} from 'react-i18next'

const TransactionItem = memo(({item}) => {
    const {t} = useTranslation()
    return (
        <div className="transaction-item d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <div className="img">
                    {item?.status == 'ok' ? <img src="/images/yes.png" /> : <img src="/images/no.png" />}
                </div>
                <div className="ps-3">
                    <Link className="stretched-link">
                        <p className="fw-6 mb-2">
                            {item.price > 0
                                ? item.paymentId == 'ads'
                                    ? t('Вознагрождение за рекламу')
                                    : item.fromId
                                    ? t('Пополнение от пользователя')
                                    : t('Пополнение')
                                : item.bookId > 0 || item.chapterId > 0
                                ? t('Списание за покупку')
                                : t('Списание')}{' '}
                        </p>
                        <p className="fs-09 text-muted mb-0">
                            #{item.id} - {moment(item.createdAt).format('DD.MM.YYYY HH:mm')}
                        </p>
                    </Link>
                </div>
            </div>
            <div className={'fw-6 ' + (item.price > 0 ? 'text-success' : 'text-danger')}>
                {customPrice({value: item.price, size: 19, color: item.price > 0 ? '#47cf6e' : '#ff5252'})}
            </div>
        </div>
    )
})

export default TransactionItem
