import React from 'react'
import {Col, Row} from 'react-bootstrap'
import Meta from '../components/Meta'
import Loader from '../components/UI/Loader'
import BookItem from '../components/BookItem'
import {useGetCatalogQuery} from '../services/cache'
import Empty from '../components/Empty'

const Manhwa = ({auth = false}) => {
    const {data, error, isLoading} = useGetCatalogQuery({type: 'manhwa', auth})

    if (isLoading) {
        return <Loader full />
    }

    if (!data || data?.length === 0) {
        return <Empty />
    }

    return (
        <>
            <Meta title="Манхва" />
            {data.map((item) => (
                <div key={item.id}>
                    <h3 className="mb-3">{item.title}</h3>
                    {item?.items?.length > 0 && (
                        <Row xxl={6} xl={6} lg={5} md={4} sm={3} xs={3} className="gx-3">
                            {item.items.map((e) => (
                                <Col key={e.id}>
                                    <BookItem item={e} />
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            ))}
        </>
    )
}

export default Manhwa
