import {createAsyncThunk} from '@reduxjs/toolkit'
import {$authApi} from '.'
import {apiRoutes} from '../config/api'
import {deleteAddressSlice, mainAddressEdit, setAddress, updateAddress} from '../store/reducers/addressSlice'

const editAccount = async (data) => {
    const response = await $authApi.post(apiRoutes.USER_EDIT, data)
    return response?.data
}

const getAddresses = async (page, limit) => {
    const response = await $authApi.get(apiRoutes.ADDRESSES, {params: {page, limit}})
    return response?.data
}

const getAddress = async (addressId) => {
    if (!addressId) {
        return false
    }
    const response = await $authApi.get(apiRoutes.ADDRESS, {params: {addressId}})
    return response?.data
}

const mainAddress = createAsyncThunk('address/main', async (payloads, thunkAPI) => {
    try {
        thunkAPI.dispatch(mainAddressEdit(payloads))
        const response = await $authApi.put(apiRoutes.ADDRESS_MAIN, payloads)
        return response?.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const editAddress = createAsyncThunk('address/edit', async (payloads, thunkAPI) => {
    const response = await $authApi.put(apiRoutes.ADDRESS_EDIT, payloads)
    if (response && response.status === 200) {
        thunkAPI.dispatch(updateAddress(payloads))

        return response?.data
    }
})
const deleteAddress = createAsyncThunk('address/delete', async (addressId, thunkAPI) => {
    thunkAPI.dispatch(deleteAddressSlice(addressId))

    const response = await $authApi.delete(apiRoutes.ADDRESS_DELETE, {data: {addressId}})
    return response?.data
})

const createAddress = createAsyncThunk('address/create', async (payloads, thunkAPI) => {
    try {
        const response = await $authApi.post(apiRoutes.ADDRESS_CREATE, payloads)

        if (response.status === 200 && response?.data) {
            thunkAPI.dispatch(setAddress(response.data))
            return response.data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const getOrders = async (page, limit) => {
    const response = await $authApi.get(apiRoutes.ORDERS, {params: {page, limit}})
    return response?.data
}

const getOrder = async (orderId) => {
    if (!orderId) {
        return false
    }
    const response = await $authApi.get(apiRoutes.ORDER, {params: {orderId}})
    return response?.data
}

const getNotifications = async (data) => {
    const response = await $authApi.get(apiRoutes.NOTIFICATIONS, {params: data})
    return response?.data
}

const deleteNotification = async (notificationId) => {
    const response = await $authApi.delete(apiRoutes.NOTIFICATIONS, {data: {notificationId}})
    return response?.data
}

const deleteAccount = async (data) => {
    const response = await $authApi.delete(apiRoutes.USER, {data})
    return response?.data
}

const savePushToken = async (token) => {
    const response = await $authApi.post(apiRoutes.SAVE_PUSHTOKEN, {token})
    return response?.data
}

export {
    createAddress,
    deleteAccount,
    deleteAddress,
    deleteNotification,
    editAccount,
    editAddress,
    getAddress,
    getAddresses,
    getNotifications,
    getOrder,
    getOrders,
    mainAddress,
    savePushToken,
}
