import moment from 'moment'
import {memo} from 'react'

const Status = memo(({value}) => {
    if (!value) {
        return null
    }
    const isPublic = value ? moment(value).isBefore() : true
    const hourPublic = moment().diff(value, 'hour')

    if (hourPublic >= 0 && hourPublic <= 16 && isPublic) {
        return <div className="status success" />
    }
    return null
})
export default Status
