import moment from 'moment'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Element, Link as LinkScroll} from 'react-scroll'
import {customImage} from '../../helpers/all'
import {Dropdown} from 'react-bootstrap'
import {IoChevronDownOutline, IoHeart, IoHeartOutline} from 'react-icons/io5'
import {Link} from 'react-router-dom'

const CommentItem = memo(({onAnswer, onEdit, onDelete, onReport, onLike, like = true, menu = true, ...item}) => {
    const {t} = useTranslation()
    let user = useSelector((state) => state.auth.user)
    let time = item?.createdAt
        ? moment(item.createdAt).format('DD MMMM YYYY kk:mm')
        : moment().format('DD MMMM YYYY kk:mm')
    let image = customImage({item: item?.user, type: 'user', size: 'mini'})

    const CustomToggle = React.forwardRef(({onClick}, ref) => {
        return (
            <a
                ref={ref}
                onClick={(e) => {
                    e.preventDefault()
                    onClick(e)
                }}
            >
                <IoChevronDownOutline size={18} className="text-muted" />
            </a>
        )
    })

    return user?.id === item?.userId ? (
        <Element key={item?.id} name={item?.id} className="comment-item yes-select d-flex align-self-end mb-3">
            <div className="text my">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <Link to="/profile" className="fw-6 fs-09 d-flex align-items-center">
                            {item?.user?.firstName ?? item?.user?.nickname ?? 'id' + item.user.id}
                            {item.user?.options?.type == 'translator' && (
                                <img
                                    title="Подтвержденный аккаунт"
                                    className="ms-1"
                                    width={15}
                                    src="/images/verified.svg"
                                />
                            )}
                        </Link>
                    </div>
                    {menu && (onEdit || onDelete) && (
                        <div>
                            <Dropdown drop="end">
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu variant="dark">
                                    <Dropdown.Item onClick={() => onEdit(item)}>{t('Редактировать')}</Dropdown.Item>
                                    <Dropdown.Item className="text-danger" onClick={() => onDelete(item)}>
                                        {t('Удалить')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </div>
                {item?.answerText && item?.answerId && (
                    <LinkScroll to={item.answerId} containerId="containerElement" className="answer">
                        <div className="text-danger fw-6">{t('Ответ')}</div>
                        <div className="answer-text">{item.answerText}</div>
                    </LinkScroll>
                )}
                <p className="mb-0 fs-08">{item.text}</p>
                <div className="d-flex justify-content-between align-items-center pt-1">
                    {like && (
                        <a className="fs-08 text-muted d-flex align-items-center line-height-100 me-2">
                            {item?.likeStatus ? (
                                <IoHeart className="me-1 text-danger" />
                            ) : (
                                <IoHeartOutline className="me-1" />
                            )}
                            <span className="fs-09">{item?.likeCount ?? 0}</span>
                        </a>
                    )}

                    <time className="fs-07 text-muted">{time}</time>
                </div>
            </div>
        </Element>
    ) : (
        <Element key={item.id} name={item.id} className="comment-item yes-select d-flex mb-3">
            <Link to={'/user/' + (item?.user?.nickname ? item.user.nickname : item.user.id)} className="pe-3">
                <img src={image} className="avatar" />
            </Link>
            <div className="text">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <Link
                            to={'/user/' + (item?.user?.nickname ? item.user.nickname : item.user.id)}
                            className="fw-7"
                        >
                            {item?.user?.firstName ?? item?.user?.nickname ?? 'id' + item.user.id}
                            {item.user?.options?.type == 'translator' && (
                                <img
                                    title="Подтвержденный аккаунт"
                                    className="ms-1"
                                    width={15}
                                    src="/images/verified.svg"
                                />
                            )}
                        </Link>
                    </div>
                    {menu && (onReport || onAnswer) && (
                        <div>
                            <Dropdown drop="start">
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu variant="dark">
                                    <Dropdown.Item onClick={() => onAnswer && onAnswer(item)}>
                                        {t('Ответить')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() => onReport && onReport(item)}
                                    >
                                        {t('Пожаловаться')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </div>
                {item?.answerText && item?.answerId && (
                    <LinkScroll to={item.answerId} containerId="containerElement" className="answer">
                        <div className="text-danger fw-6">{t('Ответ')}</div>
                        <div className="answer-text">{item.answerText}</div>
                    </LinkScroll>
                )}
                <p className="mb-0 fs-09">{item.text}</p>
                <div className="d-flex justify-content-between align-items-center pt-1">
                    {onLike && like && (
                        <a
                            onClick={() => onLike(item)}
                            className="fs-08 text-muted d-flex align-items-center line-height-100 me-2"
                        >
                            {item?.likeStatus ? (
                                <IoHeart className="me-1 text-danger" />
                            ) : (
                                <IoHeartOutline className="me-1" />
                            )}
                            <span className="fs-09">{item?.likeCount ?? 0}</span>
                        </a>
                    )}
                    <time className="fs-07 text-muted">{time}</time>
                </div>
            </div>
        </Element>
    )
})

export default CommentItem
