import React, {useCallback, useEffect, useState} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Meta from '../../../components/Meta'
import {editAccount} from '../../../services/account'
// import {authEditEmail, logout} from '../../services/auth'
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {setUser} from '../../../store/reducers/authSlice'
// import {Link} from 'react-router-dom'
import moment from 'moment'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {IoCameraOutline} from 'react-icons/io5'
import InputMask from 'react-input-mask'
import {NotificationManager} from 'react-notifications'
import {Link} from 'react-router-dom'
import MenuProfile from '../../../components/MenuProfile'
import Button from '../../../components/UI/Button'
import Select from '../../../components/UI/Select'
import {avatars, countries, customImage, locales} from '../../../helpers/all'
import useDebounce from '../../../hooks/useDebounce'
import {getDadataStreets} from '../../../services/dadata'

const ProfileEdit = () => {
    const {t, i18n} = useTranslation()

    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const theme = useSelector((state) => state.settings.theme)
    // const [modalDeleteAccount, setModalDeleteAccount] = useState(false)
    // const [openEditEmail, setOpenEditEmail] = useState(false)
    // const [newEmail, setNewEmail] = useState({email: '', step: 1, key: '', error: false, loading: false})
    const [modalLocale, setModalLocale] = useState(false)
    // const [openEditPhone, setOpenEditPhone] = useState(false)
    // const [newPhone, setNewPhone] = useState({phone: '', step: 1, key: '', error: false})
    // const [isVisible, setVisible] = useState(false)
    const image = customImage({item: user, type: 'user', size: 'mini'})
    const {
        control,
        formState: {isValid, errors},
        handleSubmit,
        register,
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: user.firstName ?? '',
            phone: user.phone ?? '',
            sex: user.sex ?? '',
            nickname: user.nickname ?? '',
            about: user?.about ?? '',
            birthday: user?.birthday ?? '',
            telegram: {
                notification: user?.telegram?.notification ?? false,
            },
            options: {
                lang: user?.options?.lang ?? 'ru',
                city: user?.options?.city ?? '',
                country: user?.options?.country ?? '',
                avatar: user?.options?.avatar ?? 0,
            },
        },
    })
    const data = useWatch({control})

    const [countryMask, setCountryMask] = useState()
    const [streetsDadata, setStreetsDadata] = useState([])
    const [countriesDadata, setCountriesDadata] = useState([])
    const [editAvatar, setEditAvatar] = useState(false)
    const streetText = useDebounce(data?.options?.city, 1000)
    const countryText = useDebounce(data?.options?.country, 1000)

    const getLang = useCallback(() => {
        let lang = locales.find((e) => e.lang.includes(data?.options?.lang ?? 'ru'))
        return lang?.title
    }, [data?.options?.lang])

    const onSubmit = useCallback(
        (data) => {
            editAccount(data)
                .then((data) => {
                    data?.id && dispatch(setUser(data))
                    NotificationManager.success(t('Изменения успешно сохранены'))
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
            setEditAvatar(false)
        },
        [data]
    )

    const onTelegram = useCallback(
        (data) => {
            let phone = data?.phone?.length > 0 ? data.phone.replace(/[^\d]/g, '') : false
            if (!phone || phone?.length === 0) {
                return NotificationManager.error(t('Укажите номер телефона'))
            }

            let isValidPhone = countryMask?.variant
                ? countryMask.variant.find((e) => e === phone.slice(0, e.length ?? 4))
                : true
            isValidPhone = countryMask?.length ? countryMask.length === phone.length : true

            if (!isValidPhone) {
                return NotificationManager.error(t('Неверный формат телефона. Проверьте еще раз.'))
            }

            data.phone = phone

            editAccount(data)
                .then((data) => {
                    data?.id && dispatch(setUser(data))
                    window.open('https://t.me/gikami_bot?start=start', '_blank', 'noreferrer')
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
        },
        [countryMask]
    )

    const editLang = useCallback(
        (lang) => {
            lang = Array.isArray(lang) ? lang[0] : lang
            editAccount({...data, options: {...data.options, lang}})
                .then((data) => {
                    data?.id && dispatch(setUser(data))
                    NotificationManager.success(t('Язык успешно изменен'))
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
            i18n.changeLanguage(lang)
            moment.locale(lang)
            setValue('lang', lang)
        },
        [modalLocale, i18n, data]
    )

    useEffect(() => {
        setCountryMask(
            data?.options?.country
                ? countries.find((e) => e.title.toLowerCase() === data.options.country.toLowerCase()) ?? countries[0]
                : countries[0]
        )
    }, [data?.options?.country])

    useEffect(() => {
        if (streetText) {
            getDadataStreets({
                query: streetText,
                locations: [{city_type_full: 'город'}],
                restrict_value: false,
                from_bound: {value: 'city'},
                to_bound: {value: 'city'},
            }).then((res) => {
                if (res?.data?.suggestions) {
                    setStreetsDadata(res.data.suggestions)
                }
            })
        }
    }, [streetText])

    useEffect(() => {
        if (countryText) {
            getDadataStreets({
                url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country',
                query: countryText,
            }).then((res) => {
                if (res?.data?.suggestions) {
                    setCountriesDadata(res.data.suggestions)
                }
            })
        }
    }, [countryText])

    // const onDeleteAccount = useCallback(() => {
    //     deleteAccount({password: data.password}).then(() => dispatch(logout()))
    // }, [data])

    // const onNewEmail = useCallback(() => {
    //     setNewEmail({...newEmail, loading: true})
    //     if (newEmail.email && newEmail.step == 1) {
    //         authEditEmail(newEmail)
    //             .then(() => setNewEmail({...newEmail, step: 2, error: false, loading: false}))
    //             .catch((error) =>
    //                 setNewEmail({
    //                     ...newEmail,
    //                     error:
    //                         typeof error?.response?.data?.error === 'string'
    //                             ? error.response.data.error
    //                             : 'Неизвестная ошибка',
    //                     loading: false,
    //                 })
    //             )
    //     } else if (newEmail.email && newEmail.step == 2) {
    //         authEditEmail(newEmail)
    //             .then((data) => {
    //                 if (data) {
    //                     setNewEmail({...newEmail, step: 3, error: false, loading: false})
    //                     dispatch(setUser(data))
    //                 }
    //             })
    //             .catch(
    //                 (error) =>
    //                     err &&
    //                     setNewEmail({
    //                         ...newEmail,
    //                         error:
    //                             typeof error?.response?.data?.error === 'string'
    //                                 ? error.response.data.error
    //                                 : 'Неизвестная ошибка',
    //                         loading: false,
    //                     })
    //             )
    //     }
    // }, [newEmail])

    return (
        <>
            <Meta title={t('Редактировать профиль')} />

            <Row>
                <Col md={3}>
                    <MenuProfile eventKey={1} />
                </Col>
                <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="box mb-4">
                            <Row>
                                <Col md={12} className="d-flex align-items-center pb-3">
                                    <div>
                                        <a onClick={() => setEditAvatar(true)} className="edit-avatar me-3">
                                            <img src={image} className="img" />
                                            <div className="upload fs-09 fw-6">
                                                <IoCameraOutline size={25} />
                                            </div>
                                        </a>
                                    </div>
                                    <div>
                                        <h5>{data.firstName}</h5>
                                        <p className="mb-0 fs-09 text-muted">id{user.id}</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t('Имя/Псевдоним')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Введите имя')}
                                            isValid={data?.firstName && !errors.firstName}
                                            isInvalid={errors.firstName}
                                            maxLength={25}
                                            {...register('firstName', {
                                                maxLength: {value: 25, message: 'Максимальное кол-во символов 25'},
                                            })}
                                        />
                                        {errors?.firstName?.message ? (
                                            <Form.Text className="text-danger fs-08">{errors.firstName.message}</Form.Text>
                                        ) : (
                                            <Form.Text className="fs-08">{t('Видят все')}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t('Фамилия')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Введите фамилию')}
                                            isValid={data?.lastName && !errors.lastName}
                                            isInvalid={errors.lastName}
                                            maxLength={30}
                                            {...register('lastName', {
                                                maxLength: {value: 30, message: 'Максимальное кол-во символов 30'},
                                            })}
                                        />
                                        {errors?.lastName?.message ? (
                                            <Form.Text className="text-danger fs-08" >{errors.lastName.message}</Form.Text>
                                        ) : (
                                            <Form.Text className='fs-08'>{t('Видите только вы')}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t('Никнейм')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Введите никнейм')}
                                            isValid={data?.nickname && !errors.nickname}
                                            isInvalid={errors.nickname}
                                            maxLength={50}
                                            minLength={3}
                                            {...register('nickname', {
                                                setValueAs: (e) => e.trim().toLowerCase(),
                                                minLength: {
                                                    value: 3,
                                                    message: 'Минимально 3 символа',
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: 'Максимально 50 символов',
                                                },
                                                pattern: {
                                                    value: /^[a-z0-9_]+$/,
                                                    message: 'Неверный формат никнейма, только a-z0-9_)',
                                                },
                                            })}
                                        />
                                        {errors?.nickname?.message && (
                                            <Form.Text className="text-danger fs-08">{errors.nickname?.message}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t('День рождения')}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            disabled={user?.birthday}
                                            readOnly={user?.birthday}
                                            defaultValue={data?.birthday}
                                            isValid={data?.birthday && !errors.birthday}
                                            isInvalid={errors.birthday}
                                            mine={moment().subtract(100, 'year').toDate()}
                                            max={moment().subtract(18, 'year').toDate()}
                                            {...register('birthday')}
                                        />
                                        {errors?.birthday?.message ? (
                                            <Form.Text className="text-danger fs-08">{errors.birthday.message}</Form.Text>
                                        ) : (
                                            <Form.Text className='fs-08'>{t('Видите только вы')}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>{t('Пол')}</Form.Label>
                                    <Form.Group className="mb-4">
                                        <div className="d-flex">
                                            <Form.Check
                                                type="radio"
                                                value="man"
                                                id="sex-1"
                                                className="me-3"
                                                label={t('Мужской')}
                                                {...register('sex')}
                                            />
                                            <Form.Check
                                                type="radio"
                                                value="woman"
                                                id="sex-2"
                                                label={t('Женский')}
                                                {...register('sex')}
                                            />
                                        </div>
                                        <Form.Text className='fs-08'>{t('Видите только вы')}</Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>{t('О себе')}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            placeholder={t('Пару слов о себе...')}
                                            isValid={data?.about && !errors.about}
                                            isInvalid={errors.about}
                                            maxLength={1500}
                                            {...register('about', {
                                                maxLength: {
                                                    value: 1500,
                                                    message: 'Максимальное кол-во символов 1500',
                                                },
                                            })}
                                        />
                                        {errors?.about?.message && (
                                            <Form.Text className="text-danger fs-08">{errors.about.message}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="box mb-4">
                            <h5>{t('Локализация')}</h5>
                            <p className="fs-08 text-muted">{t('Видите только вы')}</p>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t('Язык')}</Form.Label>
                                        <Select data={locales} value={getLang()} onClick={(e) => editLang(e.lang)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4 position-relative">
                                        <Form.Label>{t('Страна')}</Form.Label>
                                        <AsyncTypeahead
                                            id="country"
                                            defaultInputValue={data?.options?.country ?? ''}
                                            labelKey={(option) => option?.value}
                                            minLength={1}
                                            dropup
                                            emptyLabel={t('Ничего не найдено')}
                                            onSearch={(e) => setValue('options.country', e)}
                                            onChange={(e) => {
                                                if (e[0]?.data?.country) {
                                                    reset({
                                                        ...data,
                                                        options: {
                                                            ...data.options,
                                                            country: e[0].data.country,
                                                        },
                                                    })
                                                }
                                            }}
                                            options={countriesDadata}
                                            searchText={t('Поиск...')}
                                            promptText={t('Введите страну')}
                                            placeholder={t('Введите страну')}
                                            renderMenuItemChildren={(option) => option?.value}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="position-relative">
                                        <Form.Label>{t('Город')}</Form.Label>
                                        <AsyncTypeahead
                                            id="city"
                                            defaultInputValue={data?.options?.city ?? ''}
                                            labelKey={(option) => option?.value}
                                            minLength={1}
                                            dropup
                                            emptyLabel={t('Ничего не найдено')}
                                            onSearch={(e) => setValue('options.city', e)}
                                            onChange={(e) => {
                                                if (e[0]?.data?.country && e[0]?.data?.city) {
                                                    reset({
                                                        ...data,
                                                        options: {
                                                            ...data.options,
                                                            country: e[0].data.country,
                                                            city: e[0].data.city,
                                                        },
                                                    })
                                                }
                                            }}
                                            options={streetsDadata}
                                            searchText={t('Поиск...')}
                                            promptText={t('Введите город')}
                                            placeholder={t('Введите город')}
                                            renderMenuItemChildren={(option) => option?.value}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="box mb-4">
                            <h5 className="mb-4">{t('Дополнительно')}</h5>
                            <div className="d-flex telegram-connect align-items-start">
                                <div className="pe-3 img">
                                    <img src="/images/notification-sale.png" />
                                </div>
                                <div className="w-100">
                                    <h5 className="fw-6 mb-1">{t('Ваш промокод')}</h5>
                                    <Form.Control readOnly defaultValue={user?.uid} />
                                    <Form.Text className="fs-08">{t('Отправьте другу для регистрации')}</Form.Text>
                                </div>
                            </div>
                            <div className="hr" />
                            <div className="d-flex telegram-connect align-items-start pb-4">
                                <div className="pe-3 img">
                                    <img src="/images/social/telegram.svg" />
                                </div>
                                <div className="w-100">
                                    {user?.telegram?.nickname &&
                                    user?.options?.verifyPhoneStatus &&
                                    user?.phone?.length >= 10 ? (
                                        <>
                                            <h5 className="fw-6 mb-3">@{user.telegram.nickname}</h5>
                                            <Form.Check
                                                type="checkbox"
                                                id="telegram-notification"
                                                className="mb-3"
                                                label={t('Включить уведомления')}
                                                defaultChecked={data.telegram.notification}
                                                {...register('telegram.notification')}
                                            />
                                            {/* <Link className="btn btn-sm btn-blue" onClick={() => onTelegram()}>
                                    {t('Отключить')}
                                </Link> */}
                                        </>
                                    ) : user?.phone?.length >= 10 ? (
                                        <>
                                            <h5 className="fw-6 mb-1">{t('Подтвердите номер телефона')}</h5>
                                            <p className="text-muted fs-09 mb-2">
                                                Зайдите в телеграм бот{' '}
                                                <a className="fw-6" href="https://t.me/gikami_bot" target="_blank">
                                                    @gikami_bot
                                                </a>{' '}
                                                и нажмите старт. Предоставьте доступ к номеру телефона.
                                            </p>
                                            <Row className="d-flex align-items-start">
                                                <Col md={8}>
                                                    <Form.Group className="mb-2 mb-sm-0">
                                                        <InputMask
                                                            type="custom"
                                                            defaultValue={data.phone}
                                                            mask={countryMask?.options?.mask}
                                                            placeholder={t('Введите номер телефона')}
                                                            {...register('phone')}
                                                        />
                                                        <Form.Text className='fs-08'>{t('Видите только вы')}</Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        className="btn btn-sm btn-blue mb-2 mb-sm-0 w-100"
                                                        onClick={handleSubmit(onTelegram)}
                                                    >
                                                        {t('Перейти к боту')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <h5 className="fw-6 mb-1">{t('Привязка Telegram')}</h5>
                                            <p className="text-muted fs-09 mb-2">
                                                {t(
                                                    'Подключите Telegram аккаунт для дополнительного входа и уведомлений'
                                                )}
                                            </p>
                                            <Row className="d-flex align-items-start">
                                                <Col md={8}>
                                                    <Form.Group className="mb-2">
                                                        <InputMask
                                                            type="custom"
                                                            mask={countryMask?.options?.mask}
                                                            placeholder={t('Введите номер телефона')}
                                                            {...register('phone')}
                                                        />
                                                        {user?.options?.country?.length < 2 ? (
                                                            <Form.Text className='fs-08'>
                                                                {t('Для ввода доступного номера, заполните страну')}
                                                            </Form.Text>
                                                        ) : (
                                                            <Form.Text className='fs-08'>{t('Видите только вы')}</Form.Text>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        className="btn btn-sm btn-blue mb-2 w-100"
                                                        onClick={handleSubmit(onTelegram)}
                                                    >
                                                        {t('Подключить')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Button
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            disabled={!isValid}
                            className="w-100 mb-3"
                        >
                            {t('Сохранить изменения')}
                        </Button>
                        <Modal show={editAvatar} onHide={setEditAvatar} centered>
                            <Modal.Header closeButton>
                                <Modal.Title as="h5" className="fw-7">
                                    {t('Изменить аватар профиля')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {avatars?.length > 0 &&
                                        avatars.map((e, index) => (
                                            <Col
                                                md={3}
                                                sm={3}
                                                xs={3}
                                                className="d-flex justify-content-center py-3 check-avatar"
                                            >
                                                <a
                                                    className={
                                                        'check-avatar' +
                                                        (index == data?.options?.avatar ? ' active' : '')
                                                    }
                                                    onClick={() => setValue('options.avatar', index)}
                                                >
                                                    <img className="img" src={e} />
                                                </a>
                                            </Col>
                                        ))}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Link onClick={() => setEditAvatar(false)} className="btn btn-secondary btn-sm">
                                    {t('Отмена')}
                                </Link>
                                <Button
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isValid}
                                    className="btn btn-primary btn-sm"
                                >
                                    {t('Сохранить')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default ProfileEdit
