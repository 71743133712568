import {ClientJS} from 'clientjs'
import {Helmet} from 'react-helmet'

const Meta = ({title = null, description = null, image = null, type = 'website'}) => {
    const client = new ClientJS()

    return (
        <Helmet htmlAttributes={{lang: client.getLanguage()}} encodeSpecialCharacters={true}>
            <title>{title ?? process.env.REACT_APP_SITE_NAME}</title>
            <meta name="og:type" content={type} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title ?? process.env.REACT_APP_SITE_NAME} />
            <meta property="og:title" content={title ?? process.env.REACT_APP_SITE_NAME} />
            <meta name="description" content={description ? description.slice(0, 160) : process.env.REACT_APP_SITE_NAME} />
            <meta property="og:description" content={description ? description.slice(0, 160) : process.env.REACT_APP_SITE_NAME} />
            <meta name="twitter:description" content={description ? description.slice(0, 160) : process.env.REACT_APP_SITE_NAME} />
            <meta property="og:image" content={image ?? null} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Gikami" />
            <meta itemprop="name" content={title ?? process.env.REACT_APP_SITE_NAME} />
            <meta itemprop="description" content={description ? description.slice(0, 160) : process.env.REACT_APP_SITE_NAME} />
            <meta itemprop="image" content={image ?? null} />
        </Helmet>
    )
}

export default Meta
