import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {IoEyeOffOutline, IoEyeOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Meta from '../../components/Meta'
import {login} from '../../services/auth'
import {authRegister} from '../../services/auth'
import {useTranslation} from 'react-i18next'
import Button from '../../components/UI/Button'
import {setAuth, setToken, setUser} from '../../store/reducers/authSlice'
import socket from '../../config/socket'

const Registration = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const auth = useSelector((state) => state.auth)
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues: {
            key: '',
        },
    })

    const [viewPassword, setViewPassword] = useState(false)
    const [viewpasswordConfirm, setViewpasswordConfirm] = useState(false)

    useEffect(() => {
        if (auth.isAuth) {
            if (auth?.user?.status === 0) {
                navigate('/activate')
            } else {
                navigate('/account')
            }
        }
    }, [auth.isAuth])

    const onSubmit = useCallback((data) => {
        setLoading(true)
        authRegister(data)
            .then((res) => {
                login({login: data.email, password: data.password})
                    .then((res) => {
                        dispatch(setToken(res.token))
                        dispatch(setUser(res.user))
                        dispatch(setAuth(true))

                        if (res.user.id) {
                            socket.io.opts.query = {userId: res.user.id}
                            socket.connect()
                        }

                        reset()
                        NotificationManager.success('На вашу почту отправлено письмо с кодом подтверждения')
                        navigate('/activate')
                    })
                    .catch((error) => {
                        error?.response?.data?.error &&
                            typeof error?.response?.data?.error === 'string' &&
                            NotificationManager.error(error.response.data.error)
                    })
                    .finally(() => setLoading(false))
            })
            .catch((error) =>
                NotificationManager.error(
                    typeof error?.response?.data?.error === 'string'
                        ? error.response.data.error
                        : t('Неизвестная ошибка')
                )
            )
            .finally(() => setLoading(false))
    }, [])

    return (
        <>
            <Meta
                title={t('Регистрация')}
                description="Зарегистрируйте профиль и начните читать. Регистрация бесплатная."
            />
            <Container size="xxl">
                <Row className="vh-100 pt-3 justify-content-center align-items-center">
                    <Col sm={12} md={7} xl={6}>
                        <h3 className="text-center fw-8 mb-3">{t('Создать профиль')}</h3>
                        <Form onSubmit={handleSubmit(onSubmit)} className="mobile-bottom-padding">
                            <div className="box">
                                <Form.Group className="mb-4 d-none">
                                    <Form.Label>
                                        {t('Номер телефона')}
                                        <span className="required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder={t('Введите номер телефона')}
                                        isValid={!errors.phone}
                                        isInvalid={errors.phone}
                                        {...register('phone', {
                                            maxLength: {value: 30, message: 'Максимальное кол-во символов 30'},
                                        })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        Email<span className="required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        autoFocus
                                        placeholder={t('Введите email')}
                                        isValid={getValues('email') && !errors.email}
                                        isInvalid={errors.email}
                                        {...register('email', {
                                            required: t('Обязательное поле'),
                                            maxLength: {value: 100, message: 'Максимальное кол-во символов 100'},
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: t('Неверный формат Email'),
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                    )}
                                </Form.Group>
                                {/* <Form.Group className="mb-4">
                                <Form.Label>
                                    {t('Никнейм')}
                                    <span className="required">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder={t('Введите никнейм')}
                                    isValid={getValues('nickname') && !errors.nickname}
                                    isInvalid={errors.nickname}
                                    {...register('nickname', {
                                        required: t('Обязательное поле'),
                                        minLength: {value: 3, message: 'Минимальное кол-во символов 3'},
                                        maxLength: {value: 100, message: 'Максимальное кол-во символов 100'},
                                        pattern: {
                                            value: /^[a-z0-9_]+$/,
                                            message: 'Неверный формат никнейма',
                                        },
                                    })}
                                />
                                {errors?.nickname?.message ? (
                                    <Form.Text className="text-danger">{errors?.nickname?.message}</Form.Text>
                                ) : (
                                    <Form.Text>
                                        Только символы латинского алфавита с нижним регистром, цифры и знаки
                                        подчеркивания
                                    </Form.Text>
                                )}
                            </Form.Group> */}
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        {t('Пароль')}
                                        <span className="required">*</span>
                                    </Form.Label>
                                    <InputGroup className="password">
                                        <Form.Control
                                            type={viewPassword ? 'text' : 'password'}
                                            placeholder={t('Введите пароль')}
                                            isValid={getValues('password') && !errors.password}
                                            isInvalid={errors.password}
                                            {...register('password', {
                                                required: t('Обязательное поле'),
                                                minLength: {value: 6, message: 'Минимальное кол-во символов 6'},
                                                maxLength: {value: 250, message: 'Максимальное кол-во символов 250'},
                                                pattern: {
                                                    value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                                                    message:
                                                        'Пароль должен состоять не менее из 6 символов латинского алфавита и цифр',
                                                },
                                            })}
                                        />
                                        <Button
                                            variant="input"
                                            className="btn-input"
                                            onClick={() => setViewPassword(!viewPassword)}
                                        >
                                            {viewPassword ? (
                                                <IoEyeOutline size={20} />
                                            ) : (
                                                <IoEyeOffOutline size={20} />
                                            )}
                                        </Button>
                                    </InputGroup>
                                    {errors.password ? (
                                        <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                                    ) : (
                                        <Form.Text>
                                            Пароль должен состоять не менее из 6 символов латинского алфавита и цифр
                                        </Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        {t('Повторите пароль')}
                                        <span className="required">*</span>
                                    </Form.Label>
                                    <InputGroup className="password">
                                        <Form.Control
                                            type={viewpasswordConfirm ? 'text' : 'password'}
                                            placeholder={t('Повторите пароль')}
                                            isValid={getValues('passwordConfirm') && !errors.passwordConfirm}
                                            isInvalid={errors.passwordConfirm}
                                            {...register('passwordConfirm', {
                                                required: t('Обязательное поле'),
                                                validate: (value) =>
                                                    getValues('password') === value || 'Пароли не совпадают',
                                            })}
                                        />
                                        <Button
                                            className="btn-input"
                                            variant="input"
                                            onClick={() => setViewpasswordConfirm(!viewpasswordConfirm)}
                                        >
                                            {viewpasswordConfirm ? (
                                                <IoEyeOutline size={20} />
                                            ) : (
                                                <IoEyeOffOutline size={20} />
                                            )}
                                        </Button>
                                    </InputGroup>
                                    {errors.passwordConfirm && (
                                        <Form.Text className="text-danger">
                                            {errors.passwordConfirm?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Промокод (Необязательно)')}
                                        {...register('promo')}
                                    />
                                </Form.Group>
                                <Form.Check className="mb-4 d-flex align-items-center">
                                    <Form.Check.Input
                                        id="agree"
                                        isInvalid={errors.agree}
                                        defaultChecked
                                        {...register('agree', {required: t('Обязательное поле')})}
                                    />
                                    <Form.Check.Label htmlFor="agree" className="ms-3 fs-08 text-muted">
                                        Я согласен(-на) на обработку&nbsp;
                                        <a href="/policy" target="_blank" className="text-decoration-underline">
                                            персональных данных
                                        </a>
                                    </Form.Check.Label>
                                </Form.Check>
                                <Button type="submit" isLoading={loading} disabled={!isValid} className="w-100">
                                    {t('Зарегистрироваться')}
                                </Button>
                            </div>
                            <Link to="/login" className="mt-4 mb-4 w-100 btn btn-secondary">
                                {t('Войти в профиль')}
                            </Link>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Registration
