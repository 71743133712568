import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    count: 0,
    message: 0,
    order: 0,
    notification: 0,
    transaction: 0,
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        clearNotification: (state) => {
            state.count = 0
            state.message = 0
            state.order = 0
            state.notification = 0
        },
        setNotification: (state, action) => {
            state.notification = action?.payload?.notification ? Number(action.payload.notification) : 0
            state.message = action?.payload?.message ? Number(action.payload.message) : 0,
            state.transaction = action?.payload?.transaction ? Number(action.payload.transaction) : 0
        },
        updateNotification: (state, action) => {
            if (action?.payload?.message) {
                if (action?.payload?.message == -1) {
                    state.message = 0
                } else {
                    state.message++
                }
            }
            if (action?.payload?.transaction) {
                if (action?.payload?.transaction == -1) {
                    state.transaction = 0
                } else {
                    state.transaction++
                }
            }
            if (action?.payload?.notification) {
                if (action?.payload?.notification == -1) {
                    state.notification = 0
                } else {
                    state.notification++
                }
            }
            let count = state.book + state.order + state.message + state.notification
            state.count = count > 0 ? count : 0
        },
    },
})

export const {updateNotification, clearNotification, setNotification} = notificationSlice.actions

export default notificationSlice.reducer
