import {$api, $authApi} from '.'
import {apiRoutes} from '../config/api'

const getUser = async (id, auth) => {
    const response = auth
        ? await $authApi.get(apiRoutes.USER, {
              params: {id},
          })
        : await $api.get(apiRoutes.USER_NO_AUTH, {
              params: {id},
          })
    return response?.data
}
const getPublic = async (params) => {
    const response = await $authApi.get(apiRoutes.PUBLIC, {params})
    return response?.data
}
const getSubscriptions = async (data) => {
    const response = await $authApi.get(apiRoutes.USER_SUBSCRIPTION, {params: data})
    return response?.data
}
const resetSession = async (data) => {
    const response = await $authApi.delete(apiRoutes.SESSIONS, {data})
    return response?.data
}
export {getUser, resetSession, getSubscriptions, getPublic}
