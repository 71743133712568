import React from 'react'
import Meta from '../../components/Meta'

const TermAuthor = () => {
    return (
        <>
            <Meta title="Правила публикации" />
            <h1>Правила публикации</h1>
            <div className="text mb-5">
                <p>
                    Настоящие Правила являются неотъемлемой частью Пользовательского Соглашения, размещенного по
                    адресу:&nbsp;
                    <a href="https://gik.pw/term-user/" target="_blank" rel="noreferrer noopener">
                        gik.pw/term-user
                    </a>
                </p>
                <br />
                <p>На сайте запрещена к публикации информация, которая:</p>
                <ul>
                    <li>
                        содержит угрозы, дискредитирует, нарушает права, оскорбляет, порочит честь и достоинство или
                        деловую репутацию или нарушает неприкосновенность частной жизни других Пользователей или
                        третьих лиц;
                    </li>
                    <li>
                        является вульгарной или непристойной, содержит порнографические изображения и тексты или
                        сцены сексуального характера с участием несовершеннолетних, инцеста;
                    </li>
                    <li>содержит описание средств и способов суицида, попытки его совершения;</li>
                    <li>рекламу криптовалют;</li>
                    <li>
                        содержит информацию, которая пропагандируют и/или способствует разжиганию расовой,
                        религиозной, этнической ненависти или вражды, политической розни, пропагандирует идеологию
                        фашизма,или расового превосходства, содержат негативные высказывания о какой-либо
                        стране/народе/религии/политике, унижают национальное достоинство, в том числе по признаку
                        национальной принадлежности или политических взглядов;
                    </li>
                    <li>содержит экстремистские материалы;</li>
                    <li>
                        пропагандирует преступную деятельность или содержит советы, инструкции или руководства по
                        совершению преступных действий;
                    </li>
                    <li>
                        содержит информацию ограниченного доступа, включая, но не ограничиваясь, государственной и
                        коммерческой тайной, информацией о частной жизни третьих лиц;
                    </li>
                    <li>носит мошеннический характер.</li>
                </ul>
                <br />
                <p>
                    Пользователь несет личную ответственность за любую информацию, которую размещает на Сайте,
                    сообщает другим Пользователям, а также за любые взаимодействия с другими Пользователями,
                    осуществляемые на свой риск.
                </p>
                <br />
                <p>
                    На сайте нельзя размещать графические материалы (обложки, изображения в записях блогов и т.д.), в
                    которых присутствуют изображения:
                </p>
                <ul>
                    <li>почти или полностью обнаженных людей;</li>
                    <li>людей в прозрачной или чрезмерно откровенной одежде;</li>
                    <li>людей в непристойных или вызывающих позах;</li>
                    <li>женскую грудь, ягодицы или промежность крупным планом.</li>
                </ul>
                <br />
                <p>
                    Любое произведение, которое содержит информацию, не предназначенную для пользователей младше 18
                    лет, должно быть обозначено автором как таковое посредством присуждения данному произведению
                    ценза доступа <b>18+</b> на странице создания произведения.
                </p>
                <p>
                    Если автор размещает на сайте не полный текст или прямо заявляет, что текст будет разамещен
                    неполностью, то он обязан присвоить тексту статус "Написан не полностью".
                </p>
                <p>
                    Запрещается размещать рекламыне ссылки на другие ресурсы, где опубликованы ваши или чьи либо
                    работы. Пустые профили с данными ссылками автоматически замораживаются. По истечению месяца, если
                    автор профиля не свяжется с администрацией сайта для размораживания, данная страница будет
                    удалена без возможности восстановления.
                </p>
                <br />
                <p>
                    Администрация Сайта оставляет за собой право подвергнуть нарушителей правил санкциям.
                    Администрация Сайта имеет право:
                </p>
                <ul>
                    <li>Блокировать доступ к любому произведению, размещенному на сайте.</li>
                    <li>
                        Принудительно назначать произведению ценз доступа <b>18+</b>.
                    </li>
                    <li>Удалять произведение.</li>
                    <li>Блокировать пользователю доступ на сайт.</li>
                    <li>Удалить запись блога.</li>
                </ul>
            </div>
        </>
    )
}

export default TermAuthor
