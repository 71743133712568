import React from 'react'
// import {Col, Row} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {Navigation} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import BookItem from '../components/BookItem'
import Empty from '../components/Empty'
import Meta from '../components/Meta'
import Loader from '../components/UI/Loader'
import {useGetCatalogQuery} from '../services/cache'

const Catalog = () => {
    const isAuth = useSelector((state) => state.auth.isAuth)
    const {data, error, isLoading} = useGetCatalogQuery({auth: isAuth})

    if (isLoading) {
        return <Loader full />
    }

    if (!data || data?.length === 0) {
        return <Empty />
    }

    return (
        <>
            <Meta
                title={process.env.REACT_APP_SITE_DESCRIPTION}
                description={process.env.REACT_APP_SITE_DESCRIPTION}
            />
            {data.map((item) => (
                <div key={item.id}>
                    <h3 className="mb-3">{item.title}</h3>
                    {item?.items?.length > 0 && (
                        <Swiper
                            className="book-slider"
                            modules={[Navigation]}
                            navigation={true}
                            spaceBetween={10}
                            slidesPerView={6}
                            breakpoints={{
                                320: {
                                    slidesPerView: 3,
                                },
                                480: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 4,
                                },
                                1024: {
                                    slidesPerView: 5,
                                },
                                1200: {
                                    slidesPerView: 6,
                                },
                            }}
                        >
                            {item.items.map((e) => {
                                return (
                                    <SwiperSlide key={e.id}>
                                        <BookItem item={e} />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    )}
                </div>
            ))}
        </>
    )
}

export default Catalog
