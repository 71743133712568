import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data: [],
}

const chapterSlice = createSlice({
    name: 'chapter',
    initialState,
    reducers: {
        updateChapter: (state, action) => {
            if (action?.payload?.bookId && action?.payload?.id) {
                let chapterIndex = state.data.findIndex((e) => e.bookId === action.payload.bookId)
                if (chapterIndex != -1) {
                    state.data[chapterIndex] = action.payload
                } else {
                    state.data.push(action.payload)
                }
            }
        },
    },
})

export const {updateChapter} = chapterSlice.actions

export default chapterSlice.reducer
