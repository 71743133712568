import {memo} from 'react'
import {IoBook, IoImage} from 'react-icons/io5'

const Type = memo(({value, fixed = false}) => {
    if (!value) {
        return null
    }
    let className = ' book'
    let icon = <IoBook />

    if (value == 'manhwa') {
        className = ' manhwa'
        icon = <IoImage />
    }

    return <div className={'type' + (fixed ? ' fixed' : '') + (className ? className : '')}>{icon}</div>
})
export default Type
