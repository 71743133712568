import {memo} from 'react'

const Genre = memo(({data, className}) => {
    if (!data || data?.length === 0) {
        return null
    }

    return (
        <div className="tags">
            {data.map((item, index) => (
                <span key={index} className={className ? 'tag fs-09 ' + className : 'tag fs-09'}>
                    {item.title}
                </span>
            ))}
        </div>
    )
})
export default Genre
