import {ClientJS} from 'clientjs'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {IoChevronForwardOutline} from 'react-icons/io5'
import {useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import Meta from '../../components/Meta'
import Loader from '../../components/UI/Loader'
import {customImage, declination, generateNumber} from '../../helpers/all'
import {getUser} from '../../services/user'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Button from '../../components/UI/Button'
import {FreeMode, Navigation} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import BookItem from '../../components/BookItem'
import Empty from '../../components/Empty'
import {updateSubscription} from '../../services/subscription'

const User = () => {
    const {userId} = useParams()
    const {state} = useLocation()
    const {t} = useTranslation()
    const client = new ClientJS()

    const user = useSelector((state) => state.auth.user)

    const [data, setData] = useState({
        loading: true,
        item: state,
    })

    const image = customImage({item: data.item, type: 'user', size: 'mini'})

    const {
        register,
        handleSubmit,
        getValues,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
    })

    useEffect(() => {
        if (userId) {
            getUser(userId, !!user?.id).then((res) => setData((prev) => ({...prev, loading: false, item: res})))
        }
    }, [userId])

    const onSubscription = useCallback(() => {
        updateSubscription({to: userId}).then(() =>
            getUser(userId, !!user?.id).then((res) => setData((prev) => ({...prev, loading: false, item: res})))
        )
    }, [userId, user])

    if (data?.loading && !state?.id) {
        return <Loader full />
    }

    return (
        <>
            <Meta title={data.item?.firstName ? data.item.firstName : 'id' + user.id} />
            <div className="mb-4 box">
                <div className="d-block d-sm-flex align-items-center justify-content-between">
                    <div className="d-flex w-100 align-items-center">
                        <div className="pe-3">
                            <div className="profile-avatar">
                                <img src={image} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <h3 className="mb-1">
                                    {data.item?.firstName ? data.item.firstName : 'id' + user.id}
                                    {data.item?.options?.type && data.item?.options?.type != 'user' && (
                                        <OverlayTrigger
                                            key="verified"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="tooltip-verified">
                                                    {t('Подтвержденный аккаунт')}
                                                </Tooltip>
                                            }
                                        >
                                            <img className="ms-2" width={20} src="/images/verified.svg" />
                                        </OverlayTrigger>
                                    )}
                                </h3>
                                <p className="mb-0 text-muted">
                                    {data.item?.options?.type == 'translator'
                                        ? t('Переводчик')
                                        : data.item?.options?.type == 'author'
                                        ? t('Автор')
                                        : t('Читатель')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        {user?.id && user?.id != data.item?.id ? (
                            data.item?.subscriptionStatus ? (
                                <Button
                                    className="btn-sm btn-secondary w-100 mt-3 mt-sm-0"
                                    onClick={() => onSubscription()}
                                >
                                    {t('Отписаться')}
                                </Button>
                            ) : (
                                <Button className="btn-sm w-100 mt-3 mt-sm-0" onClick={() => onSubscription()}>
                                    {t('Подписаться')}
                                </Button>
                            )
                        ) : (
                            user?.id != data.item?.id && <Link to="/login">{t('Войти в профиль')}</Link>
                        )}
                    </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="px-2 text-center">
                        <b>{generateNumber(data.item?.publicCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                data.item?.publicCount,
                                ['Публикация', 'Публикации', 'Публикаций'].map((e) => t(e))
                            )}
                        </div>
                    </div>
                    <div className="px-2 text-center">
                        <b>{generateNumber(data.item?.subscribersCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                data.item?.subscribersCount,
                                ['Подписчик', 'Подписчика', 'Подписчиков'].map((e) => t(e))
                            )}
                        </div>
                    </div>
                    <div className="px-2 text-center">
                        <b>{generateNumber(data.item?.subscriptionsCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                data.item?.subscriptionsCount,
                                ['Подписка', 'Подписки', 'Подписок'].map((e) => t(e))
                            )}
                        </div>
                    </div>
                    <div className="px-2 text-center">
                        <b>{generateNumber(data.item?.viewCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(data.item?.viewCount, ['Просмотр', 'Просмотра', 'Просмотров'])}
                        </div>
                    </div>
                </div>
            </div>
            {data.item?.about?.length > 0 && (
                <div className="box mb-4 text-muted">
                    <h5 className="mb-3">{t('О себе')}</h5>
                    {data.item.about}
                </div>
            )}
            <div className="box mb-4">
                <h5 className="mb-3">{t('Публикации')}</h5>
                {data?.item?.books?.items?.length > 0 ? (
                    <Swiper
                        className="book-slider"
                        modules={[Navigation, FreeMode]}
                        spaceBetween={10}
                        slidesPerView={'auto'}
             
                    >
                        {data.item.books.items.map((e) => {
                            return (
                                <SwiperSlide key={e.id}>
                                    <BookItem item={e} />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                ) : (
                    <Empty
                        mini
                        text={t('Ничего не опубликовано')}
                        desc={t('Попробуйте зайти позже')}
                        button={
                            <Link className="btn-primary btn-sm" to="/">
                                {t('Перейти на главную')}
                            </Link>
                        }
                    />
                )}
            </div>
            <div className="mt-3">
                {client.isMobileAndroid() && (
                    <a
                        href="https://play.google.com/store/apps/details?id=ru.gikami.app"
                        target="_blank"
                        className="d-flex d-md-none align-items-center justify-content-between app-link mb-3 line-height-100"
                    >
                        <div className="d-flex d-md-none align-items-center">
                            <div className="me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path
                                        d="M15.3099 12.7188L14.0282 11.386L9.78055 15.9436L15.3099 12.7188Z M16.958 11.7576L17.0071 11.729C18.3303 10.9572 18.3303 9.04538 17.0071 8.27366L16.9564 8.2441L15.3199 10L16.958 11.7576Z M15.3082 7.28288L9.78589 4.06215L14.0282 8.61402L15.3082 7.28288Z M4.04121 1.00037L12.6954 10L4.03865 19.0023C2.98068 19.0214 2 18.1809 2 16.9996V3.00301C2 1.82076 2.98226 0.979819 4.04121 1.00037Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <div>
                                <p className="mb-0 fs-07 light-gray">{t('Доступно в')}</p>
                                <p className="mb-0 fs-08 fw-6">Google Play</p>
                            </div>
                        </div>
                        <IoChevronForwardOutline />
                    </a>
                )}
            </div>
        </>
    )
}

export default User
