import React, {useCallback, useState} from 'react'
import {Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {IoEyeOffOutline, IoEyeOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import {useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Meta from '../../components/Meta'
import Button from '../../components/UI/Button'
import socket from '../../config/socket'
import {login} from '../../services/auth'
import {setAuth, setToken, setUser} from '../../store/reducers/authSlice'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {ClientJS} from 'clientjs'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const {
        register,
        handleSubmit,
        getValues,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
    })

    const [viewPass, setViewPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const client = new ClientJS()

    const onSubmit = useCallback(
        (data) => {
            setLoading(true)
            login(data)
                .then((res) => {
                    i18n.changeLanguage(res?.user?.options?.lang ?? client.getLanguage() ?? 'ru-RU')
                    moment.locale(res?.user?.options?.lang ?? client.getLanguage() ?? 'ru-RU')

                    dispatch(setToken(res.token))
                    dispatch(setUser(res.user))
                    dispatch(setAuth(true))

                    if (res.user.id) {
                        socket.io.opts.query = {userId: res.user.id}
                        socket.connect()
                        navigate('/')
                    }
                })
                .catch((error) => {
                    error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                })
                .finally(() => setLoading(false))
        },
        [client, i18n, socket]
    )

    return (
        <>
            <Meta title={t('Войти')} description="Войдите в профиль и начните читать. Регистрация бесплатная." />
            <Container size="xxl">
                <Row className="vh-100 pt-3 justify-content-center align-items-center">
                    <Col sm={12} md={7} xl={6} className="mobile-bottom-padding">
                        <img
                            src="/apple.png"
                            alt={process.env.REACT_APP_SITE_NAME}
                            height={80}
                            className="d-block mb-3 m-auto logo-img"
                        />
                        <h3 className="text-center fw-8 mb-3">{t('Вход')}</h3>
                        <div className="box">
                            <Form.Group className="mb-4">
                                <Form.Label>{t('Логин')}</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    placeholder="Введите никнейм или email"
                                    isValid={getValues('login') && !errors.login}
                                    isInvalid={errors.login}
                                    {...register('login', {
                                        required: 'Обязательное поле',
                                        minLength: {value: 3, message: 'Минимальное кол-во символов 3'},
                                        maxLength: {value: 100, message: 'Максимальное кол-во символов 100'},
                                    })}
                                />
                                {errors.login && (
                                    <Form.Text className="text-danger">{errors.login?.message}</Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>{t('Пароль')}</Form.Label>
                                <InputGroup className="password">
                                    <Form.Control
                                        type={viewPass ? 'text' : 'password'}
                                        placeholder={t('Введите пароль')}
                                        isValid={getValues('password') && !errors.password}
                                        isInvalid={errors.password}
                                        {...register('password', {
                                            required: t('Обязательное поле'),
                                            minLength: {value: 6, message: 'Минимальное кол-во символов 3'},
                                            maxLength: {value: 250, message: 'Максимальное кол-во символов 250'},
                                        })}
                                    />
                                    <Button
                                        variant="input"
                                        className="btn-input"
                                        onClick={() => setViewPass(!viewPass)}
                                    >
                                        {viewPass ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}
                                    </Button>
                                </InputGroup>
                                {errors.password && (
                                    <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                                )}
                            </Form.Group>
                            <Form.Check className="mb-4 d-flex align-items-center">
                                <Form.Check.Input
                                    id="agree"
                                    isInvalid={errors.agree}
                                    defaultChecked
                                    {...register('agree', {required: 'Обязательное поле'})}
                                />
                                <Form.Check.Label htmlFor="agree" className="ms-3 fs-08">
                                    {t('Я согласен(-на) на обработку')}&nbsp;
                                    <a className="text-decoration-underline" target="_blank" href="/policy">
                                        {t('персональных данных')}
                                    </a>
                                </Form.Check.Label>
                            </Form.Check>
                            <Button
                                type="submit"
                                isLoading={loading}
                                onClick={handleSubmit(onSubmit)}
                                disabled={!isValid}
                                className="w-100"
                            >
                                {t('Войти')}
                            </Button>
                            <div className="d-block mt-4 text-center">
                                <Link to="/recovery">{t('Забыли пароль?')}</Link>
                            </div>
                        </div>
                        <div className="hr" />
                        <Link to="/reg" type="button" className="w-100 btn btn-secondary">
                            {t('Создать профиль')}
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Login
