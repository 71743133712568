import {memo} from 'react'
import {IoSend} from 'react-icons/io5'
import {useSelector} from 'react-redux'
import {customImage} from '../../helpers/all'
import Input from '../UI/Input'
import SupportItem from './SupportItem'

const SupportForm = memo(
    ({
        support = false,
        data,
        form,
        input = true,
        placeholder = 'Введите сообщение',
        title = false,
        emptyText = 'Нет сообщений',
        onChange,
        onSubmit,
    }) => {
        const user = useSelector((state) => state.auth.user)
        const image = customImage({item: user, type: 'user', size: 'mini'})

        return (
            <div className="chat">
                {input && (
                    <form className="d-flex align-items-center position-relative pt-2">
                        <div className="me-3">
                            <img src={image} className="avatar" />
                        </div>
                        <div className="input w-100">
                            <Input
                                className="pe-5"
                                value={form.text}
                                placeholder={placeholder}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <a onClick={() => onSubmit()} className="position-absolute end-0 me-2">
                            <IoSend size={22} className={form?.text?.length > 0 ? 'text-success' : 'text-muted'} />
                        </a>
                    </form>
                )}
                {data.length > 0 ? (
                    <div className="chat-body custom-scroll">
                        {data.map((item) => (
                            <SupportItem support={support} {...item} />
                        ))}
                    </div>
                ) : (
                    <div className="w-100 chat-body custom-scroll py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                        {emptyText}
                    </div>
                )}
                {title && <h3 className="mb-3">{title}</h3>}
            </div>
        )
    }
)

export default SupportForm
