import {useSelector} from 'react-redux'
import {S3_LINK, FILE_URL} from '../config/api'

const customPrice = ({value = 0, currency = false, color = '#ffc107', size = 14}) => {
    value = Number.isInteger(Number(value)) ? parseInt(value).toLocaleString() : 0
    return (
        <span className="d-inline-flex price align-items-center">
            <span>{value}&nbsp;</span>
            <span>
                {currency ? (
                    currency
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={size}
                        height={size}
                        viewBox="0 0 100 100"
                        fill="none"
                    >
                        <path
                            fill={color}
                            fillRule="evenodd"
                            d="M77.36 85.142a48.169 48.169 0 0 1-12.327 8.498 44.145 44.145 0 0 0 18.042-8.498H77.36ZM91.591 58.76a47.685 47.685 0 0 1-2.723 9.486h7.346a43.924 43.924 0 0 0 2.98-9.486H91.59ZM44.314 6C19.857 6 0 25.855 0 50.314 0 74.774 19.857 94.63 44.314 94.63a44.166 44.166 0 0 0 31.002-12.661 44.482 44.482 0 0 0 9.044-12.663 43.959 43.959 0 0 0 3.82-12.657 44.648 44.648 0 0 0 0-12.667 43.96 43.96 0 0 0-3.82-12.657 44.48 44.48 0 0 0-9.044-12.663A44.166 44.166 0 0 0 44.314 6Zm0 6.222c21.024 0 38.092 17.069 38.092 38.092 0 21.024-17.068 38.092-38.092 38.092-21.022 0-38.09-17.068-38.09-38.092 0-21.023 17.068-38.092 38.09-38.092Zm0 3.704c-18.977 0-34.386 15.409-34.386 34.388 0 18.98 15.409 34.389 34.386 34.389 18.98 0 34.389-15.41 34.389-34.389 0-18.98-15.41-34.388-34.389-34.388ZM88.868 32.38a47.687 47.687 0 0 1 2.724 9.487h7.601a43.924 43.924 0 0 0-2.98-9.487h-7.345Zm3.235 22.676h7.644a44.62 44.62 0 0 0 0-9.485h-7.644c.307 3.154.307 6.33 0 9.485ZM87.186 71.95a47.988 47.988 0 0 1-6.317 9.487h6.349a44.517 44.517 0 0 0 7.14-9.487h-7.172Zm-4.111-56.464a44.146 44.146 0 0 0-18.042-8.498 48.167 48.167 0 0 1 12.327 8.498h5.715Zm-2.206 3.704a47.99 47.99 0 0 1 6.317 9.486h7.172a44.522 44.522 0 0 0-7.14-9.486h-6.349ZM32.814 30.574v40.61h11.75c5.167 0 8.861-1.037 11.084-3.11 2.24-2.093 3.36-5.01 3.36-8.75 0-2.278-.453-4.278-1.36-6-.89-1.723-2.436-3.065-4.64-4.028-2.203-.963-5.259-1.445-9.166-1.445h-6.305v-13.11h19.138v-4.167h-23.86Zm11.25 36.555h-6.527V51.962h5.722c2.592 0 4.685.232 6.277.695 1.593.463 2.76 1.231 3.5 2.305.741 1.074 1.111 2.528 1.111 4.361 0 2.63-.768 4.593-2.305 5.89-1.518 1.277-4.111 1.916-7.778 1.916Z"
                            clipRule="evenodd"
                        />
                    </svg>
                )}
            </span>
        </span>
    )
}

const getImageURL = ({path = '', size = 'mini', type = 'product'}) => {
    if (path && Array.isArray(path) && path?.length > 0) {
        if (size == 'mini') {
            return S3_LINK + '/' + type + '/mini/' + path[0].media
        } else {
            return S3_LINK + '/' + type + '/' + path[0].media
        }
    } else if (path && path?.length > 0) {
        if (size == 'mini') {
            return S3_LINK + '/' + type + '/mini/' + path
        } else {
            return S3_LINK + '/' + type + '/' + path
        }
    } else if (!type || type == 'product' || type == 'sale') {
        return '/images/empty-product-image.png'
    } else if (type == 'user') {
        return '/images/avatar-full.png'
    }
}

const convertColor = (color, opacity) => {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + _opacity.toString(16).toUpperCase()
}

const convert = (value) => (value ? Math.round(Number(value) * 1000) : 0)

const customWeight = (value) => {
    if (!value) {
        return 0
    }

    value = convert(value)

    let weight = value > 1000 ? (value / 1000).toFixed(1) + 'кг' : value + 'г'

    return weight
}

const statusData = {
    processing: {
        text: 'Обработка',
        statusBg: 'rgba(0,0,0,0.05)',
    },
    reservation: {
        text: 'Предзаказ',
        statusBg: 'rgba(0,0,0,0.05)',
    },
    new: {text: 'Принят', statusBg: 'rgba(0,0,0,0.05)'},
    preparing: {
        text: 'Готовится',
    },
    prepared: {
        text: 'Готов к выдаче',
    },
    delivery: {
        text: 'Доставляется',
    },
    done: {text: 'Завершен', statusBg: 'rgba(0,0,0,0.05)'},
    canceled: {statusBg: 'transparent', text: 'Отменен'},
}

const deliveryData = {
    delivery: 'Доставка',
    pickup: 'Самовывоз',
}

const paymentData = {
    card: 'Банковской картой',
    online: 'Онлайн оплата',
    cash: 'Наличными',
}

const getSettings = (name) => {
    const settings = useSelector((state) => state?.settings?.options)
    let option = settings ? settings[name] ?? false : false
    return option
}

const getCount = (cart) => {
    if (cart && cart?.length > 0) {
        let value = 0
        cart.map((item) => item?.cart?.count && (value += Number(item.cart.count)))
        return value
    }
}

const declination = (number, words) => {
    if (number % 10 === 1 && (number % 100 < 10 || number % 100 > 20)) {
        return words[0] // Именительный падеж
    } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 > 20)) {
        return words[1] // Родительный падеж
    } else {
        return words[2] // Все остальные случаи - дательный падеж
    }
}

const setCssColor = (name, value) => {
    document.documentElement.style.setProperty(name, value)
}
const locales = [
    {
        title: 'Русский',
        image: '/images/country/russia.png',
        lang: ['ru_RU', 'ru-RU', 'ru'],
    },
    {
        title: 'English',
        image: '/images/country/united-states.png',
        lang: ['en_US', 'en-US', 'en'],
    },
]
const countries = [
    {
        title: 'Россия',
        image: '/images/country/russia.png',
        lang: 'ru',
        length: 11,
        options: {
            mask: '+7(999)999-99-99',
        },
    },
    {
        title: 'Казахстан',
        image: '/images/country/kazakhstan.png',
        lang: 'kz',
        variant: ['+77', '+76'],
        length: 11,
        options: {
            mask: '+7(999)999-99-99',
        },
    },
    {
        title: 'Беларусь',
        image: '/images/country/belarus.png',
        lang: 'be',
        length: 12,
        variant: ['+375'],
        options: {
            mask: '+375(99)999-99-99',
        },
    },
    {
        title: 'Узбекистан',
        image: '/images/country/uzbekistan.png',
        lang: 'uz',
        length: 12,
        variant: ['+998'],
        options: {
            mask: '+999(99)999-99-99',
        },
    },
    {
        title: 'Туркменистан',
        image: '/images/country/turkmenistan.png',
        lang: 'tk',
        length: 12,
        variant: ['+993'],
        options: {
            mask: '+999(99)999-99-99',
        },
    },
    {
        title: 'Таджикистан',
        image: '/images/country/tajikistan.png',
        lang: 'tg',
        length: 12,
        variant: ['+992'],
        options: {
            mask: '+999(99)999-99-99',
        },
    },
    {
        title: 'Кыргызстан',
        image: '/images/country/kyrgyzstan.png',
        lang: 'ky',
        length: 12,
        variant: ['+996'],
        options: {
            mask: '+999(99)999-99-99',
        },
    },
    {
        title: 'Армения',
        image: '/images/country/armenia.png',
        lang: 'hy',
        length: 12,
        variant: ['+374'],
        options: {
            mask: '+374(99)999-99-99',
        },
    },
    {
        title: 'Азербайджан',
        image: '/images/country/azerbaijan.png',
        lang: 'az',
        length: 12,
        variant: ['+994'],
        options: {
            mask: '+999(99)999-99-99',
        },
    },
]

const avatars = [
    '/images/avatar/0.png',
    '/images/avatar/1.png',
    '/images/avatar/2.png',
    '/images/avatar/3.png',
    '/images/avatar/4.png',
    '/images/avatar/5.png',
    '/images/avatar/6.png',
    '/images/avatar/7.png',
    '/images/avatar/8.png',
    '/images/avatar/9.png',
    '/images/avatar/10.png',
    '/images/avatar/11.png',
    '/images/avatar/12.png',
    '/images/avatar/13.png',
    '/images/avatar/14.png',
    '/images/avatar/15.png',
    '/images/avatar/16.png',
    '/images/avatar/17.png',
    '/images/avatar/18.png',
    '/images/avatar/19.png',
    '/images/avatar/20.png',
    '/images/avatar/21.png',
]
const customImage = ({item, type = 'book', size = ''}) => {
    let link = `${FILE_URL}/${type}/${size ? size + '/' : ''}`
    if (item?.media) {
        return link + item.media
    } else if (item?.medias && item?.medias[0]?.media) {
        return link + item.medias[0].media
    } else if (item && item['medias.media']) {
        return link + item['medias.media']
    } else if (item && item?.options?.avatar) {
        let image = avatars[item.options.avatar] ?? avatars[0]
        return image
    } else {
        return '/images/avatar/0.png'
    }
}

const generateNumber = (value) => {
    let num = Number(value)
    if (!num || num === 0) return 0
    let SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E']
    let tier = Math.floor(Math.log10(Math.abs(num)) / 3)
    if (tier === 0) return num.toString()
    let suffix = SI_SYMBOL[tier]
    let scale = Math.pow(10, tier * 3)
    let scaled = num / scale
    return scaled.toFixed(1) + suffix
}

const generateUrl = (str) => {
    if (str?.length > 0) {
        var url = str.replace(/[\s]+/gi, '-')
        url = translit(url)
        url = url.replace(/[^0-9a-z_]+/gi, '').toLowerCase()
        return url
    }
}

const translit = (str) => {
    var ru =
        'А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я'.split(
            '-'
        )
    var en =
        "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split(
            '-'
        )
    var res = ''
    for (var i = 0, l = str.length; i < l; i++) {
        var s = str.charAt(i),
            n = ru.indexOf(s)
        if (n >= 0) {
            res += en[n]
        } else {
            res += s
        }
    }
    return res
}

const ages = [
    {
        title: 'Не выбрано',
        value: null,
    },
    {
        title: '0+',
        value: '0',
    },
    {
        title: '6+',
        value: '6',
    },
    {
        title: '12+',
        value: '12',
    },
    {
        title: '16+',
        value: '16',
    },
    {
        title: '18+',
        value: '18',
    },
]

const genres = [
    { title: 'Фантастика', name: 'fantastika' },
    { title: 'Научная фантастика', name: 'nauchnayafantastika' },
    { title: 'Космическая опера', name: 'kosmicheskayaopera' },
    { title: 'Киберпанк', name: 'kiberpunk' },
    { title: 'Постапокалиптика', name: 'postapokaliptika' },
    { title: 'Стимпанк', name: 'stimpank' },
    { title: 'Фэнтези', name: 'fentezi' },
    { title: 'Эпическое фэнтези', name: 'epicheskoefentezi' },
    { title: 'Городское фэнтези', name: 'gorodskoefentezi' },
    { title: 'Фэнтези с элементами мифологии', name: 'fentezismitologiej' },
    { title: 'Фэнтези с элементами темного фэнтези', name: 'fentezitmnofentezi' },
    { title: 'Детектив', name: 'detektiv' },
    { title: 'Полицейский детектив', name: 'policejskidetektiv' },
    { title: 'Шпионский детектив', name: 'shpionskidetektiv' },
    { title: 'Юридический детектив', name: 'yuridicheskijdetektiv' },
    { title: 'Триллер', name: 'triller' },
    { title: 'Психологический триллер', name: 'psihologicheskijtriller' },
    { title: 'Технотриллер', name: 'tehnotriller' },
    { title: 'Роман', name: 'roman' },
    { title: 'Исторический роман', name: 'istoricheskijroman' },
    { title: 'Психологический роман', name: 'psihologicheskijroman' },
    { title: 'Черный роман', name: 'chernyjroman' },
    { title: 'Любовный роман', name: 'lyubovnyjroman' },
    { title: 'Политический роман', name: 'politicheskijroman' },
    { title: 'Поэзия', name: 'poeziya' },
    { title: 'Драма', name: 'drama' },
    { title: 'Комедия', name: 'komediya' },
    { title: 'Автобиография', name: 'avtobiografiya' },
    { title: 'Биография', name: 'biografiya' },
    { title: 'Исторический', name: 'istoricheskij' },
    { title: 'Научно-популярное', name: 'nauchnopopulyarnoe' },
    { title: 'Приключения', name: 'priklyucheniya' },
    { title: 'Религиозное', name: 'religioznoe' },
    { title: 'Ужасы', name: 'uzhasy' },
    { title: 'Зомби', name: 'zombi' },
    { title: 'Мистические ужасы', name: 'misticheskieuzhasy' },
    { title: 'Психологические ужасы', name: 'psihologicheskieuzhasy' },
    { title: 'Мистика', name: 'mistika' },
    { title: 'Готика', name: 'gotika' },
    { title: 'Эзотерика', name: 'ezoterika' },
    { title: 'Шаманская мистика', name: 'shamanskayamistika' },
    { title: 'Эротика', name: 'erotika' },
    { title: 'Публицистика', name: 'publitsistika' },
    { title: 'Эссе', name: 'esse' },
    { title: 'Полемика', name: 'polevika' },
    { title: 'Сатира', name: 'satira' },
    { title: 'Ирония', name: 'ironiya' },
    { title: 'Сарказм', name: 'sarkazm' },
    { title: 'Пародия', name: 'parodia' },
    { title: 'Травелог', name: 'travelop' },
    { title: 'Путешествия по странам', name: 'puteshestviyastran' },
    { title: 'Путешествия по городам', name: 'puteshestviyagorod' },
    { title: 'Пешие путешествия', name: 'peshieputeshestviya' },
    { title: 'Утопия', name: 'utopiya' },
    { title: 'Антиутопия', name: 'antiutopiya' },
    { title: 'Технократическая утопия', name: 'tehnocraticheskayautopiya' },
    { title: 'Социалистическая утопия', name: 'socialisticheskayautopiya' },
    { title: 'Феминистская литература', name: 'feministskayaliteratura' },
    { title: 'Постколониальный феминизм', name: 'postkolonialnyjfeminism' },
    { title: 'Интерсекциональный феминизм', name: 'intersekcionalnyjfeminism' },
  ];

export {
    generateNumber,
    locales,
    avatars,
    countries,
    setCssColor,
    customPrice,
    getImageURL,
    convertColor,
    customWeight,
    statusData,
    deliveryData,
    paymentData,
    getSettings,
    getCount,
    declination,
    customImage,
    generateUrl,
    ages,
    genres,
}
