import {memo, useState} from 'react'
import {AiOutlineStar, AiFillStar} from 'react-icons/ai'

const Stars = memo(({maxStars = 10, initialValue = 1, value, ...props}) => {
    const [hoverIndex, setHoverIndex] = useState(null) // Для отслеживания наведения

    const handleRatingPress = (index) => {
        props.onClick(index + 1) // Передаем выбранную оценку в props
    }

    const handleRatingHover = (index) => {
        setHoverIndex(index) // Сохраняем индекс при наведении
    }

    const handleRatingLeave = () => {
        setHoverIndex(null) // Сбрасываем индекс при уходе курсора
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            {Array(maxStars)
                .fill(null)
                .map((_, index) => {
                    index = index + 1
                    return (
                        <div
                            key={index}
                            className={`px-1 flex-1 d-flex align-items-center justify-content-center flex-column ${
                                (hoverIndex !== null && index <= hoverIndex) || index < value ? 'hover-star' : '' // Исправленное условие
                            }`}
                            onMouseEnter={() => handleRatingHover(index)}
                            onMouseLeave={handleRatingLeave}
                            onClick={() => handleRatingPress(index)}
                        >
                            <div>
                                {index < value ? (
                                    <AiFillStar size={26} className="filled-star" />
                                ) : (
                                    <AiOutlineStar size={26} />
                                )}
                            </div>
                            <div className={`fs-07 ${index + 1 === value ? 'text-white fw-6' : 'text-muted'}`}>
                                {index}
                            </div>
                        </div>
                    )
                })}
        </div>
    )
})

export default Stars
