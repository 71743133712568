import moment from 'moment'
import React, {memo} from 'react'
import {IoChatbubblesOutline, IoCheckmarkSharp, IoChevronForwardOutline, IoEyeOutline} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {customPrice, generateNumber} from '../helpers/all'
import {Badge} from 'react-bootstrap'

const ChapterItem = memo(({item, book, onClick}) => {
    const isPublic = item?.start ? moment(item.start).isBefore() : moment(item.createdAt).isBefore()
    const hourPublic = item?.start ? moment().diff(item.start, 'hour') : moment().diff(item.createdAt, 'hour')

    return (
        <Link
            to={`/public/${book?.uid ?? book?.id}/read/${item.id}`}
            onClick={onClick && onClick}
            state={{item, book}}
            className="chapter-item d-flex justify-content-between align-items-center"
        >
            <div>
                <div className="d-flex align-items-center mb-1">
                    <div>
                        {item?.options?.moment && (
                            <Badge className="badge-sm me-2" title={item.options.moment}>
                                !
                            </Badge>
                        )}
                    </div>
                    <div>
                        <b className="d-flex">{item.title}</b>
                    </div>
                </div>
                <p className="mb-0 d-flex align-items-center ">
                    {isPublic && (
                        <>
                            <span className="fs-08 text-muted d-flex align-items-center me-2 line-height-100">
                                <IoEyeOutline className="me-1" />
                                {generateNumber(item.view)}
                            </span>
                            <span className="fs-08 text-muted d-flex align-items-center me-2 line-height-100">
                                <IoChatbubblesOutline className="me-1" />
                                {generateNumber(item.commentCount)}
                            </span>
                        </>
                    )}
                    {!isPublic ? (
                        <span className="fs-08 text-warning line-height-100">
                            Публикация {moment(item.start).calendar().toLocaleLowerCase()}
                        </span>
                    ) : hourPublic >= 0 && hourPublic <= 16 && isPublic ? (
                        <span className="fs-08 text-success line-height-100">Новая</span>
                    ) : (
                        <span className="fs-08 text-muted line-height-100">
                            {moment(item?.start ?? item.createdAt).calendar()}
                        </span>
                    )}
                </p>
            </div>
            <div className="d-flex align-items-center">
                {item?.orders?.length === 0 && item?.price > 0 ? (
                    <span className="fs-09 fw-bold me-3 text-warning">{customPrice({value: item?.price ?? 0})}</span>
                ) : (
                    item?.price > 0 && <IoCheckmarkSharp className="text-success me-3" size={18} />
                )}
                <IoChevronForwardOutline color="#999" size={18} />
            </div>
        </Link>
    )
})

export default ChapterItem
