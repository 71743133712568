import React, {memo, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Meta from '../../../components/Meta'
// import {authEditEmail, logout} from '../../services/auth'
import {Col, Row} from 'react-bootstrap'
// import {Link} from 'react-router-dom'
import moment from 'moment'
import {IoDesktopOutline, IoPhonePortraitOutline, IoTrashOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import CustomModal from '../../../components/CustomModal'
import MenuProfile from '../../../components/MenuProfile'
import Button from '../../../components/UI/Button'
import Loader from '../../../components/UI/Loader'
import {useGetSessionsQuery} from '../../../services/cache'
import {resetSession} from '../../../services/user'

const SessionItem = memo(({data, active = false, onClick = false}) => {
    let osName = data.osName.toLowerCase()
    let isDesktop = osName == 'windows' || osName == 'linux' || osName == 'mac'

    return (
        <a className={'session' + (active ? ' mb-0' : '')} onClick={() => onClick && onClick({...data, isDesktop})}>
            <div className="pe-3">
                {isDesktop ? <IoDesktopOutline size={25} /> : <IoPhonePortraitOutline size={25} />}
            </div>
            <div>
                <div>
                    {data?.manufacturer && data?.modelName
                        ? `${data.manufacturer} ${data.modelName}`
                        : `${data?.osName} ${data?.osVersion}`}
                </div>
                <div className="text-muted fs-08">
                    {data?.brand}
                    {active ? (
                        <>
                            {' '}
                            - <span className="text-success">Активный</span>
                        </>
                    ) : (
                        data?.updatedAt && <> - {moment(data.updatedAt).fromNow()}</>
                    )}
                </div>
            </div>
        </a>
    )
})

const Sessions = () => {
    const {t, i18n} = useTranslation()
    const {data, error, isLoading, refetch} = useGetSessionsQuery()
    const [session, setSession] = useState({show: false, data: false})
    const [confirmation, setСonfirmation] = useState({show: false, data: false})

    const active = data && data[0] ? data[0] : false
    const all = data && data[0] ? data.slice(1) : false

    const onReset = useCallback(
        (data) => {
            resetSession(data)
                .then((data) => {
                    NotificationManager.success(
                        t(data?.all ? 'Сессии кроме актуальной очищенны' : 'Сессия успешно очищенна')
                    )
                    refetch()
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
                .finally(() => {
                    setСonfirmation({show: false, data: false})
                    setSession({show: false, data: false})
                })
        },
        [data]
    )

    return (
        <>
            <Meta title={t('История активности')} />
            <Row>
                <Col md={3}>
                    <MenuProfile eventKey={2} />
                </Col>
                <Col>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="box mb-4">
                                <h3 className="mb-3">{t('Текущий сеанс')}</h3>
                                <SessionItem
                                    data={active}
                                    active
                                    onClick={(data) => setSession({show: true, data})}
                                />
                                <a
                                    className={'session mt-3 mb-0'}
                                    onClick={() => setСonfirmation({show: true, data: {all: true}})}
                                >
                                    <div className="pe-3">
                                        <IoTrashOutline size={25} className="text-danger" />
                                    </div>
                                    <div>
                                        <div className="text-danger">Завершить другие сеансы</div>
                                        <div className="text-muted fs-08">
                                            Выйти на всех устройствах, кроме этого
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="box">
                                <h3 className="mb-3">{t('Другие сеансы')}</h3>
                                {isLoading ? (
                                    <Loader />
                                ) : all?.length > 0 ? (
                                    all.map((e) => (
                                        <SessionItem
                                            key={e.id}
                                            data={e}
                                            onClick={(data) => setSession({show: true, data})}
                                        />
                                    ))
                                ) : (
                                    <span className="text-muted">Других сеансов нет</span>
                                )}
                            </div>
                        </>
                    )}
                </Col>
            </Row>
            <CustomModal
                show={session.show}
                setShow={() => setSession((prev) => ({...prev, show: false}))}
                title={t('Сеанс')}
            >
                <div className="mb-3 circle m-auto">
                    {session.data?.isDesktop ? <IoDesktopOutline size={35} /> : <IoPhonePortraitOutline size={35} />}
                </div>
                <div className="text-center">
                    <div className="fw-7">
                        {session.data?.manufacturer && session.data?.modelName
                            ? `${session.data.manufacturer} ${session.data.modelName}`
                            : `${session.data?.osName} ${session.data?.osVersion}`}
                    </div>
                    <div>
                        {session.data?.updatedAt && (
                            <span className="text-muted fs-09">
                                {moment(session.data.updatedAt).calendar().toLowerCase()}
                            </span>
                        )}
                    </div>
                </div>
                <hr />
                <div className="mb-3">
                    <div className="text-muted fs-08">Название</div>
                    {session.data?.brand}
                </div>
                <div className="mb-3">
                    <div className="text-muted fs-08">IP Адрес</div>
                    {session.data?.ip}
                </div>
                <a className="session mt-3 mb-0" onClick={() => setСonfirmation({show: true, data: session.data})}>
                    <div className="pe-3">
                        <IoTrashOutline size={22} className="text-danger" />
                    </div>
                    <div className="text-danger">Завершить сеанс</div>
                </a>
            </CustomModal>
            <CustomModal
                show={confirmation.show}
                setShow={() => setСonfirmation((prev) => ({...prev, show: false}))}
                title={t('Завершение сеанса')}
                footer={
                    <>
                        <Button
                            className="me-3 btn-secondary btn-sm"
                            onClick={() => setСonfirmation((prev) => ({...prev, show: false}))}
                        >
                            Отмена
                        </Button>
                        <Button className="btn-primary btn-sm" onClick={() => onReset(confirmation.data)}>
                            Завершить
                        </Button>
                    </>
                }
            >
                {t(
                    confirmation.data?.all
                        ? 'Вы уверены, что хотите завершить все сеансы кроме актуальной?'
                        : 'Вы уверены, что хотите завершить этот сеанс?'
                )}
            </CustomModal>
        </>
    )
}

export default Sessions
