import React, {useCallback, useEffect} from 'react'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {NotificationManager} from 'react-notifications'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Meta from '../../components/Meta'
import {authActivate, checkAuth} from '../../services/auth'
import InputCode from '../../components/UI/InputCode'
import {setUser} from '../../store/reducers/authSlice'

const Activate = () => {
    const auth = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        control,
        formState: {errors, isValid},
        setValue,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValue: {
            key: false,
        },
    })

    useEffect(() => {
        if (auth.isAuth) {
            if (auth?.user?.status != 0) {
                navigate('/profile')
            }
        } else {
            navigate('/login')
        }
    }, [auth.isAuth])

    const onSubmit = useCallback(
        (data) => {
            authActivate(data)
                .then(() => {
                    NotificationManager.success('Вы успешно активировали аккаунт')
                    checkAuth()
                        .then((data) => data?.id && dispatch(setUser(data)))
                        .finally(() => navigate('/profile'))
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
        },
        [auth.user]
    )

    return (
        <>
            <Meta title="Активация аккаунта" />
            <Container size="xxl">
                <Row className="vh-100 justify-content-center align-items-center">
                    <Col sm={12} md={7} xl={6}>
                        <h3 className="text-center fw-8 mb-3">Введите код подтверждения</h3>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3">
                                <InputCode length={6} autoFocus={true} onChange={(e) => setValue('key', e)} />
                            </Form.Group>
                            <Button type="submit" disabled={!isValid} className="w-100">
                                Отправить
                            </Button>
                            <Link className="w-100 btn btn-secondary mt-3">Выйти из профиля</Link>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Activate
