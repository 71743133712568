import {memo} from 'react'

const Age = memo(({value, fixed = false}) => {
    if (!value) {
        return null
    }
    let color = null
    if (value >= 16) {
        color = ' age-16'
    }
    if (value >= 18) {
        color = ' age-18'
    }
    if (value >= 21) {
        color = ' age-21'
    }
    return <div className={'age' + (fixed ? ' fixed' : '') + (color ? color : '')}>{value}+</div>
})
export default Age
