import React from 'react'
import Meta from '../../components/Meta'

const TermUser = () => {
    return (
        <>
            <Meta title="Пользовательское соглашение" />
            <h1>Пользовательское соглашение</h1>
            <div className="text mb-5">
                <p>
                    Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем
                    gik.pw (далее Gikami или Администрация) с одной стороны и пользователем сайта с другой.
                </p>
                <p>
                    Пользователь обязан полностью ознакомиться с Соглашением до момента регистрации на Gikami.
                    Регистрация Пользователя на Gikami означает полное и безоговорочное принятие Пользователем
                    настоящих условий Соглашения в соответствии со ст. 438 Гражданского кодекса Российской Федерации.
                </p>
                <br />
                <h3 className="mb-2">Предмет соглашения</h3>
                <p>
                    <strong>
                        Администрация предоставляет пользователю право на размещение на сайте следующей информации:
                    </strong>
                    <br />- Текстовой информации
                    <br />- Аудиоматериалов
                    <br />- Видеоматериалов
                    <br />- Фотоматериалов
                    <br />- Ссылок на материалы, размещенные на других сайтах
                </p>
                <br />
                <h3 className="mb-2">Права и обязанности сторон</h3>
                <p>
                    <strong>Пользователь имеет право:</strong>
                    <br />- осуществлять поиск информации на сайте
                    <br />- получать информацию на сайте
                    <br />- создавать информацию для сайта
                    <br />- распространять информацию на сайте
                    <br />- комментировать контент, выложенный на сайте
                    <br />- изменять рейтинг пользователей
                    <br />- использовать информацию сайта в личных некоммерческих целях
                </p>
                <br />
                <p>
                    <strong>Администрация имеет право:</strong>
                    <br />- по своему усмотрению и необходимости создавать, изменять, отменять правила
                    <br />- ограничивать доступ к любой информации на сайте
                    <br />- создавать, изменять, удалять информацию
                    <br />- удалять учетные записи
                </p>
                <br />
                <p>
                    <strong>Пользователь обязуется:</strong>
                    <br />- не копировать информацию с других источников
                    <br />- не распространять информацию, которая направлена на пропаганду войны, разжигание
                    национальной, расовой или религиозной ненависти и вражды, а также иной информации, за
                    распространение которой предусмотрена уголовная или административная ответственность
                    <br />- не нарушать работоспособность сайта
                    <br />- не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и
                    тому же лицу
                    <br />- не совершать действия, направленные на введение других Пользователей в заблуждение
                    <br />- не регистрировать учетную запись от имени или вместо другого лица за исключением случаев,
                    предусмотренных законодательством РФ
                    <br />- не размещать материалы рекламного, эротического, порнографического или оскорбительного
                    характера, а также иную информацию, размещение которой запрещено или противоречит нормам
                    действующего законодательства РФ
                    <br />- не использовать скрипты (программы) для автоматизированного сбора информации и/или
                    взаимодействия с Сайтом и его Сервисами
                </p>
                <br />
                <p>
                    <b>Администрация обязуется:</b>
                    <br />- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по
                    независящим от Администрации причинам.
                    <br />- осуществлять разностороннюю защиту учетной записи Пользователя
                    <br />- защищать информацию, распространение которой ограничено или запрещено законами путем
                    вынесения предупреждения либо удалением учетной записи пользователя, нарушившего правила
                    <br />- предоставить всю доступную информацию о Пользователе уполномоченным на то органам
                    государственной власти в случаях, установленных законом
                </p>
                <br />
                <h3 className="mb-2">Ответственность сторон</h3>
                <p>
                    - пользователь лично несет полную ответственность за распространяемую им информацию
                    <br />- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
                    <br />- в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение,
                    стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой
                    Пользователем, а также бесперебойную работу информационного ресурса
                </p>
                <br />
                <h3 className="mb-2">Условия действия соглашения</h3>
                <p>
                    Данное Соглашение вступает в силу при любом использовании данного сайта.
                    <br />
                    Соглашение перестает действовать при появлении его новой версии.
                    <br />
                    Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по
                    своему усмотрению.
                    <br />
                    Администрация не оповещает пользователей об изменении в Соглашении.
                </p>
            </div>
        </>
    )
}

export default TermUser
