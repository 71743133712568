import React, {useCallback, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
    IoArrowUndoSharp,
    IoCheckmark,
    IoCheckmarkSharp,
    IoCloseSharp,
    IoCreateOutline,
    IoSend,
} from 'react-icons/io5'
import {useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import {Element, scroller} from 'react-scroll'
import Empty from '../components/Empty'
import Meta from '../components/Meta'
import Loader from '../components/UI/Loader'
import CommentItem from '../components/comment/CommentItem'
import socket from '../config/socket'
import {
    createComment,
    createCommentLike,
    createCommentReport,
    deleteComment,
    editComment,
    getComments,
} from '../services/comment'
import {NotificationManager} from 'react-notifications'

const Comment = ({noAuth = false}) => {
    const {t} = useTranslation()
    const {chapterId, commentId} = useParams()
    const {state} = useLocation()

    const isAuth = useSelector((state) => state.auth.isAuth)

    const [comments, setComments] = useState({show: false, loading: true, chapter: null, items: state?.items ?? []})
    const {
        control,
        formState: {errors},
        handleSubmit,
        register,
        reset,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            editId: null,
            editText: null,
            text: '',
            answerId: null,
            answerText: null,
            bookId: comments?.book?.id,
            chapterId: comments?.chapter?.id,
        },
    })
    const data = useWatch({control})

    const onLoad = useCallback(() => {
        getComments({chapterId}, noAuth)
            .then((data) => {
                if (data?.chapter?.id) {
                    socket.emit('create', 'chapter/' + data.chapter.id)
                }
                setComments((res) => ({
                    ...res,
                    ...data,
                    loading: false,
                }))
                if (data.chapter.id && data.book.id) {
                    reset({chapterId: data.chapter.id, bookId: data.book.id})
                }
            })
            .catch(() => setComments((res) => ({...res, loading: false})))
            .finally(
                () =>
                    commentId &&
                    scroller.scrollTo(commentId, {
                        smooth: true,
                    })
            )
    }, [chapterId, noAuth, commentId])

    useEffect(() => {
        onLoad()
    }, [chapterId, noAuth])

    useEffect(() => {
        if (isAuth) {
            socket.on('comment', (data) => {
                if (data) {
                    setComments((prev) => ({
                        ...prev,
                        items: [data, ...prev.items],
                    }))
                }
            })
            socket.on('edit', (data) => {
                if (data) {
                    setComments((prev) => {
                        let index = prev.items.findIndex((e) => e.id == data.id)
                        prev.items[index] = data
                        return {...prev}
                    })
                }
            })
            return () => {
                socket.off('comment')
                socket.off('edit')
            }
        }
    }, [isAuth])

    const onSubmit = useCallback(
        (data) => {
            data.text = data.text.trim()
            if (data?.editId) {
                editComment(data).then(() =>
                    reset({...data, editId: null, editText: null, answerId: null, answerText: null, text: ''})
                )
            } else {
                createComment(data).then(() =>
                    reset({...data, editId: null, editText: null, answerId: null, answerText: null, text: ''})
                )
            }
        },
        [comments]
    )

    const onDelete = useCallback(
        (e) => {
            let newDataComments = comments.items.filter((item) => item.id != e.id)
            setComments((res) => ({
                ...res,
                items: newDataComments,
            }))
            deleteComment(e)
        },
        [comments.items]
    )

    const onAnswer = useCallback(
        (e) => {
            reset({
                ...data,
                editId: null,
                editText: null,
                text: '',
                answerId: e.id,
                answerText: e?.text?.length > 120 ? e.text.slice(0, 120) + '...' : e.text,
            })
        },
        [data]
    )

    const onEdit = useCallback(
        (e) => {
            reset({
                ...data,
                answerId: null,
                answerText: null,
                editId: e.id,
                editText: e.text,
                text: e.text,
            })
        },
        [data]
    )

    const onLike = (e) =>
        createCommentLike(e).then((count) =>
            setComments((prev) => {
                let index = prev.items.findIndex((comment) => comment.id == e.id)
                prev.items[index].likeCount = count
                prev.items[index].likeStatus = prev.items[index]?.likeStatus ? false : true
                return {...prev}
            })
        )

    const onReport = (e) =>
        createCommentReport(e).then(() => NotificationManager.success('Жалоба успешно отправлена'))

    if (comments?.loading) {
        return <Loader full />
    }

    if (!comments?.chapter?.id) {
        return (
            <Empty
                text="Такой публикации нет"
                desc="Попробуйте зайти позже"
                button={
                    <Link className="btn-primary" to="/">
                        Перейти в каталог
                    </Link>
                }
            />
        )
    }

    if (noAuth) {
        return (
            <Empty
                text={t('Сначала авторизуйтесь')}
                desc={t('Доступ только для зарегистрированных пользователей')}
                button={
                    <Link className="btn-primary" to="/login">
                        {t('Войти в профиль')}
                    </Link>
                }
            />
        )
    }

    return (
        <>
            <Meta title={'Комментарии к ' + comments?.chapter?.title ?? 'главе'} />
            <div className="comment-box">
                <div className="chat">
                    <div className="d-flex align-items-center position-relative pt-2">
                        <div className="input w-100">
                            <Form.Control
                                type="text"
                                placeholder={t('Введите комментарий')}
                                className="pe-5"
                                maxLength={1500}
                                {...register('text', {
                                    maxLength: {value: 1500, message: 'Максимальное кол-во символов 1500'},
                                })}
                            />
                            {errors?.text?.message && (
                                <Form.Text className="text-danger">{errors.text.message}</Form.Text>
                            )}
                        </div>
                        <a onClick={handleSubmit(onSubmit)} className="position-absolute end-0 me-2">
                            {data?.editId ? (
                                <IoCheckmarkSharp
                                    size={22}
                                    className={data?.text?.length > 0 ? 'text-danger' : 'text-muted'}
                                />
                            ) : (
                                <IoSend
                                    size={22}
                                    className={data?.text?.length > 0 ? 'text-danger' : 'text-muted'}
                                />
                            )}
                        </a>
                    </div>
                    {data?.answerId && data?.answerText && (
                        <div className="answer">
                            <div className="text">
                                <div>
                                    <IoArrowUndoSharp size={24} className="text-danger me-2" />
                                </div>
                                <div>
                                    <div className="text-danger fw-6">{t('Ответ')}</div>
                                    <div>{data.answerText}</div>
                                </div>
                            </div>
                            <div className="close">
                                <a onClick={() => reset({...data, answerId: null, answerText: null})}>
                                    <IoCloseSharp size={20} />
                                </a>
                            </div>
                        </div>
                    )}
                    {data?.editId && data?.editText && (
                        <div className="answer">
                            <div className="text">
                                <div>
                                    <IoCreateOutline size={24} className="text-danger me-2" />
                                </div>
                                <div>
                                    <div className="text-danger fw-6">{t('Редактирование')}</div>
                                    <div>{data.editText}</div>
                                </div>
                            </div>
                            <div className="close">
                                <a onClick={() => reset({...data, editId: null, editText: null, text: ''})}>
                                    <IoCloseSharp size={20} />
                                </a>
                            </div>
                        </div>
                    )}
                    {comments?.items?.length > 0 ? (
                        <Element name="container" id="containerElement" className="chat-body custom-scroll">
                            {comments.items.map((item) => (
                                <CommentItem
                                    {...item}
                                    onDelete={onDelete}
                                    onAnswer={onAnswer}
                                    onEdit={onEdit}
                                    onReport={onReport}
                                    onLike={onLike}
                                />
                            ))}
                        </Element>
                    ) : (
                        <div className="w-100 chat-body custom-scroll py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                            {t('Нет комментариев')}
                        </div>
                    )}
                    <h3 className="mb-3">{t('Комментарии')}</h3>
                </div>
            </div>
        </>
    )
}

export default Comment
