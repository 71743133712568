import React from 'react'
import Meta from '../../components/Meta'

const Offer = ({noAuth = false}) => {
    return (
        <>
            <Meta title="Оферта" />
            <h1>Оферта</h1>
            <div className="text mb-5">
                <h3 className="mb-3">
                    <strong>Общие положения</strong>
                </h3>
                <ol>
                    <li>
                        Настоящая публичная оферта урегулирует виртуальный контакт пользователей с интернет-сайтом
                        Gikami (далее по тексту – Сайт), принадлежащим самозанятому Назметдинов Ильяс Равелевич, ИНН
                        166024526683, а также способ создания виртуальных Личных балансов и внесения и вывода средств
                        из них.
                    </li>
                    <li>
                        Сторонами публичной оферты являются, Назметдинов Ильяс Равелевич ИНН 166024526683, с одной
                        стороны, и любой пользователь – физическое лицо, зарегистрированное на Сайте.
                    </li>
                    <li>
                        Пользователь заявляет, что ознакомлен с Общими условиями пользования Сайтом и любое активное
                        действие с его стороны, или сохранение пассивного поведения после того как интернет-сайт
                        Gikami включен в браузере, представляет собой электронное волеизъявление, что он согласен с
                        этими Общими условиями пользования.
                    </li>
                </ol>{' '}
                <br />
                <h3 className="mb-3">
                    <strong>Пояснение некоторых терминов</strong>
                </h3>
                <p>Пользователи заявляют, что ознакомлены и согласны с определениями следующих терминов:</p>
                <p>
                    Интернет-сайт - совокупность интернет-страниц, с общей заглавной интернет-страницей, которая
                    включается в вашем браузере, при написании электронного адреса Gikami;
                </p>
                <p>
                    Пользователь интернет-страницы или сайта - физическое лицо, зарегистрированное на Сайте согласно
                    условиям настоящей Публичной оферты, достигшее возраста, допустимого законодательством РФ для
                    принятия условий настоящей оферты.
                </p>
                <p>Ограничения по возрасту</p>
                <p>
                    Лица, не достигшие 14-летнего возраста, не могут быть пользователями интернет - страницы или
                    сайта. Сайт не несёт ответственности за неверные данные в связи с возрастом пользователей.
                </p>
                <p>
                    Когда Пользователь является несовершеннолетним в соответствии с законодательством страны,
                    гражданином которой он является, то имеет право использовать услуги Сайта, если располагает
                    письменным согласием родителя/опекуна. Принимая данную Публичную оферту, Пользователь
                    подтверждает наличие у него такого разрешения. Сайт сохраняет за собой право потребовать от
                    пользователя нотариально заверенную копию такого согласия.
                </p>
                <p>
                    Интерфейс – комбинация графических объектов, ассоциированных с программным кодом, посредством
                    которого пользователь осуществляет коммуникацию с интернет-сайтом доступным ему способом.
                </p>
                <p>
                    Информационная система/Система – это любое отдельное устройство или совокупность взаимосвязанных
                    или сходных устройств, которое в целях исполнения определенной программы обеспечивает
                    автоматическую обработку данных.
                </p>
                <p>Интернет-страница - составная и обособленная часть веб-сайта.</p>
                <p>
                    IP-Адрес (IP address) - уникальный идентификационный номер, ассоциирующийся с компьютером,
                    Интернет-страницей или ресурсом пользователя, способом, позволяющим локализировать его во
                    всемирной сети Интернет.
                </p>
                <p>
                    Пароль - код из букв, цифр и знаков, который вместе с логином индивидуализирует пользователя и
                    который вместе с логином служит для доступа в его пользовательский профиль.
                </p>
                <p>
                    Логин – выбранный пользователем уникальный код из букв, цифр и знаков, посредством которых он
                    индивидуализируется в отношениях с сайтом;
                </p>
                <p>
                    Авторский контент - объект авторского права в виде текста, аудио или изображений, предоставленный
                    и опубликованный Пользователем на Сайте.
                </p>
                <p>
                    Произведение – авторского контента объект авторского права в виде законченного по своему
                    содержанию текста, предоставленный и опубликованный Пользователем на Сайте.
                </p>
                <p>
                    Публикация или доведение до всеобщего сведения – предоставление Пользователем любым лицам
                    возможности в любое время, из любого места, посредством сети Интернет доступа к Авторскому
                    контенту, размещенному на Сайте.
                </p>
                <p>Автор - Пользователь Сайта, разместивший для публичного доступа Авторский контент.</p>
                <p>
                    Личный баланс - база данных, предоставляющая информацию пользователю о накопленных суммах,
                    которые он может использовать для различных платных услуг на Сайте.
                </p>{' '}
                <br />
                <h3 className="mb-3">
                    <strong>Содержание публичной оферты</strong>
                </h3>
                <ol>
                    <li>
                        Назметдинов Ильяс Равелевич ИНН 166024526683 предоставляет пользователям доступ к сайту
                        Gikami (далее по тексту – Сайт), содержащему авторские контенты.
                    </li>
                    <li>Доступ к Сайту предоставляется на безвозмездной основе.</li>
                    <li>
                        Доступ к некоторому Авторскому контенту закрыт и является платным. Доступ к такому Авторскому
                        контенту приобретается путем оплаты в пользу Автора, а Сайт взимает комиссию за
                        предоставление услуги.
                    </li>
                    <li>
                        На сайте присутствует возможность приобретения платных услуг, которые не влияют на наличие
                        доступа Пользователя к бесплатному и платному контенту Авторов. Оплата таких услуг носит
                        добровольный характер.
                    </li>
                    <li>
                        Сайт принимает деньги пользователей в виде пополнения Личного баланса на сайте Gikami,
                        предназначенного для оплаты услуг на Сайте, изменения рейтингов Авторов на Сайте, а также
                        оплаты другим пользователям на сайте.
                    </li>
                    <li>
                        Все внесенные деньги на Личные балансы Пользователей сформируют балансы, которые можно
                        использовать по всему Сайту. Баланс Пользователя будет использован при финансовых
                        транзакциях, которые он хочет совершить. Если данный баланс не достаточен для проведения
                        транзакции, Сайт потребует от Пользователя выбрать дополнительный способ оплаты для покрытия
                        разницы.
                    </li>
                    <li>
                        Сайт не является банком, поэтому Личные балансы Пользователей не являются вкладами и на них
                        не начисляются проценты. Личные балансы не застрахованы и не гарантированы каким-либо из
                        принятых национальным законодательством РФ способов для гарантии по вкладам.
                    </li>
                </ol>{' '}
                <br />
                <h3 className="mb-3">
                    <strong>Права пользователя</strong>
                </h3>
                <ol>
                    <li>
                        Любой Пользователь имеет право на бесплатный доступ к ресурсам Сайта, за исключением случаев,
                        когда данная информация и/или информационный ресурс распространяется согласно договору
                        платных услуг.
                    </li>
                    <li>Любой Пользователь имеет право на регистрацию под одним Логином и паролем на Сайте.</li>
                    <li>
                        Любой Пользователь имеет право на доступ под своим логином и паролем к ресурсам, которые Сайт
                        предоставляет при регистрации.
                    </li>
                    <li>
                        Любой Пользователь имеет право на защиту данных, информации и всех ресурсов, которые он
                        предоставляет сайту.
                    </li>
                    <li>
                        Любой Пользователь имеет право вносить деньги на свой Личный баланс, выводить деньги из него
                        по заявке по образцу, обязывая Сайт осуществлять переводы в по его поручению. Пользователь не
                        имеет право продавать или передавать иным способом свой Личный баланс или его часть кому-либо
                        вне Сайта.
                    </li>
                </ol>{' '}
                <br />
                <h3 className="mb-3">
                    <strong>Права Сайта</strong>
                </h3>
                <ol>
                    <li>
                        Сайт имеет право хранить всю информацию, которую авторы публикуют (включая текст, фотографии,
                        ссылки и другие информационные ресурсы), и предоставлять ее для бесплатного и/или платного
                        использования пользователями Сайта.
                    </li>
                    <li>
                        Сайт имеет право удалять размещенную информацию по сигналу надзорного или судебного органа, а
                        также в случаях, когда налицо основательные сомнения о нарушении авторских прав на
                        содержание, размещенное Пользователем услуг.
                    </li>
                    <li>
                        Сайт имеет право изменять Личный баланс по поручению Пользователя в момент, в который он
                        использует услуги сайта.
                    </li>
                </ol>
                <h3 className="mb-3">
                    <strong>Защита персональных данных</strong>
                </h3>
                <ol>
                    <li>
                        Сайт является администратором персональных данных согласно Закону о защите персональных
                        данных. Сайт предпринимает необходимые меры и несет ответственность за защиту информации
                        Пользователя, ставшей ему известной в связи с его регистрацией на интернет-странице Сайта, за
                        исключением случаев непреодолимой силы, случайного события или злоумышленных действий со
                        стороны третьих лиц. Сайт не несет ответственности за размещение персональных данных,
                        предоставленных ему третьими лицами при исполнении их законных обязанностей.
                    </li>
                    <li>
                        Сайт вправе собирать и использовать и иную информацию своих Пользователей. Информация может
                        содержать и-мейл адрес (электронную почту), IP-адрес, с которых осуществляется доступ и пр.
                        Пользователь предоставляет информацию добровольно при использовании ресурсов.
                    </li>
                    <li>
                        Принимая настоящую Публичную оферту, Пользователь предоставляет Назметдинову Ильясу
                        Равелевичку, свое согласие на обработку предоставленных Пользователем персональных данных при
                        использовании средств автоматизации, включающих в себя: фамилию, имя, отчество, реквизиты
                        документа, удостоверяющего личность, постоянный адрес (прописку) и место жительства,
                        контактный телефон для открытия, на условиях договора публичной оферты, личного аккаунта на
                        сайте.
                    </li>
                </ol>
                <p>
                    Под обработкой персональных данных подразумеваются действия (операции), предусматривающие сбор,
                    систематизацию, накопление, хранение, уточнение (обновление, изменение), использование,
                    обезличивание, блокирование, уничтожение персональных данных.
                </p>
                <p>
                    В целях исполнения договора публичной оферты, стороной которого является Пользователь – субъект
                    персональных данных, Пользователь дает свое согласие Сайту на трансграничную передачу своих
                    персональных данных.
                </p>{' '}
                <br />
                <h3 className="mb-3">
                    <strong>Урегулирование споров</strong>
                </h3>
                <ol>
                    <li>
                        При возникновении споров и разногласий Пользователь должен обратиться к Сайту с конкретной
                        жалобой по адресу: info@gik.pw Сайт обязуется в течение 2 недель рассмотреть жалобу
                        Пользователя и приложить все усилия для разрешения спора или разногласия.
                    </li>
                    <li>
                        Если споры не будут улажены путем переговоров, они подлежат разрешению в порядке,
                        определенном законодательством РФ.
                    </li>
                </ol>
            </div>
        </>
    )
}

export default Offer
