import React from 'react'
import Meta from '../../components/Meta'

const Copyright = ({noAuth = false}) => {
    return (
        <>
            <Meta title="Оферта" />
            <h1>Авторское право</h1>
            <div className="text mb-5">
                <p>
                    Содержание Сайта Gikami (gik.pw) (далее – Сайт) составляют тексты, графические
                    изображения, элементы дизайна, видео, аудио, программы для ЭВМ и другие объекты (далее –
                    Контент). Если явно не указано иное, Контент Сайта представляет собой Контент, созданный на Сайте
                    или загруженный на Сайт Пользователями.
                    <br />
                    Применительно к защите интеллектуальных прав в связи с пользовательским Контентом на Сайте
                    выступает информационным посредником и в соответствии со ст. 1253.1 Гражданского Кодекса РФ и ст.
                    15.7 Федерального закона «Об информации, информационных технологиях и о защите информации»
                    принимает меры по пресечению соответствующих нарушений по заявлениям правообладателей.
                    <br />
                    Если Вы обнаружили на Сайте Контент, использование которого, по Вашему мнению, является
                    незаконным, пожалуйста, ознакомьтесь с настоящим документом. Просим Вас с пониманием отнестись к
                    тому, что в отсутствие необходимого объема информации о предполагаемом нарушении и принадлежности
                    прав мы не можем должным образом отреагировать на Ваше заявление.
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>О сервисе</strong>
                </h3>
                <p>
                    Сервис gik.pw — это клуб любителей манги, где пользователи предоставляют свои переводы
                    манги, получая возможность загружать и читать мангу и делится ею с другими членами клуба.
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>Поддержка борьбы с незаконным контентом</strong>
                </h3>
                <p>
                    К сожалению, нельзя исключить случаи, когда Пользователи могут с помощью Сайта незаконно хранить,
                    передавать, распространять и предоставлять доступ к информации и объектам интеллектуальной
                    собственности. Администрация Сайта не вправе принимать на себя функции правоохранительных либо
                    судебных органов и объективно не в состоянии оценить, является тот или иной контент, размещенный
                    на Сайте, законным или нет. В случае возникновения спорной ситуации заявителю следует обращаться
                    в правоохранительные органы и суды.
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>Законность</strong>
                </h3>
                <p>
                    Администрация Сайта соблюдает российское законодательство и не осуществляет контроль и цензуру
                    отношений, связанных с применением Пользователями технических возможностей Сайта. Пользователи
                    вправе в соответствии с законодательством свободно использовать возможности Сайта для обмена
                    информацией, в том числе в рамках обсуждения творчества любимых авторов и исполнителей, качества
                    и использования товаров и услуг третьих лиц.
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>Защита авторских прав</strong>
                </h3>
                <p>
                    Мы соблюдаем Закон о защите авторских прав в цифровую эпоху (DMCA) и другие действующие законы об
                    авторских правах и быстро удаляем неправомерно размещенные переводы манги, получив
                    соответствующее уведомление о нарушении прав.
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>Уведомление о нарушении авторских прав</strong>
                </h3>
                <p>
                    Если вы считаете, что перевод манги на сайте gik.pw нарушает ваши авторские права, вы можете
                    написать уведомление о нарушении прав и отправить его на адрес info@gik.pw и продублировать
                    его через сервис обратной связи, мы же в свою очередь обязуемся осуществит блокировку
                    перечисленного в уведомлении материала
                    <br />
                    Часть 512(c) Акта об авторских правах (Digital Millennium Copyright Act) предъявляет определенные
                    требования к форме этого уведомления. В соответствии с этим, оно должно быть предоставлено в
                    письменном виде и обязательно содержать следующую информацию:
                    <ul>
                        <li>
                            Материальную или электронную подпись лица, имеющего право представлять интересы человека,
                            чьи авторские права на материал были нарушены.
                        </li>
                        <li>
                            Указание на работы (материалы), в отношении которых нарушены права. В случае, когда
                            уведомление сообщает о нарушениях авторского права на несколько таких материалов
                            (текстов, изображений), в нем должны быть приведены указания на каждый из них.
                        </li>
                        <li>
                            Указание на материал, нарушающий авторские права или являющийся субъектом поведения,
                            нарушающего авторские права, каковой материал должен быть удален с серверов
                             gik.pw или заблокирован для доступа. Вы также должны предоставить исходный адрес
                            (URL) этого материала.
                        </li>
                        <li>
                            Информация о том, как связаться с вами, например адрес электронной почты, почтовый адрес
                            или номер телефона.
                        </li>
                        <li>
                            Утверждение, что вы действительно считаете, что использование данного материала в таком
                            виде не разрешено ни владельцем авторских прав, ни его агентом, ни законодательством США.
                        </li>
                        <li>
                            Заявление, сделанное ввиду возможных штрафных санкций за предоставление ложных сведений,
                            что представленные вами факты не содержат ошибок и искажений, и вы имеете право
                            действовать от лица владельца авторских прав.
                        </li>
                    </ul>
                </p>
                <br />
                <h3 className="mb-3">
                    <strong>Удаление или блокирование доступа к материалу</strong>
                </h3>
                <p>
                    После получения указанного выше уведомления о нарушнии в течение 3 (трех) рабочих дней сервис
                    gik.pw либо предпринимает меры по ограничению доступа к спорным материалам, либо, если
                    информации в требовании недостаточно или существуют сомнения в полномочиях заявителя, связывается
                    с лицом, направившим уведомление, для получения дополнительной информации.
                    <br />
                    При определенных обстоятельствах gik.pw прекращает предоставление сервиса владельцам
                    аккаунтов, которые были замечены в многократных нарушениях авторских прав.
                </p>
            </div>
        </>
    )
}

export default Copyright
