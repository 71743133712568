import React, {useLayoutEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import BookItem from '../../components/BookItem'
import Meta from '../../components/Meta'
import Loader from '../../components/UI/Loader'
import {getPublic} from '../../services/user'
import Empty from '../../components/Empty'

const Public = () => {
    const {t} = useTranslation()

    const [data, setData] = useState({
        loading: true,
        items: [],
    })

    useLayoutEffect(() => {
        getPublic().then((res) => setData((prev) => ({...prev, ...res, loading: false})))
    }, [])

    if (data?.loading) {
        return <Loader full />
    }

    if (!data?.items || data.items?.length === 0) {
        return <Empty />
    }

    return (
        <>
            <Meta title={t('Мои публикации')} />
            <h3 className="mb-3">{t('Мои публикации')}</h3>

            <Row xxl={6} xl={6} lg={5} md={4} sm={3} xs={3} className="gx-3">
                {data.items.map((e) => (
                    <Col key={e.id}>
                        <BookItem item={e} />
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default Public
