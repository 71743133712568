import moment from 'moment'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {customImage} from '../helpers/all'

const SubscriptionItem = memo(({item}) => {
    const {t} = useTranslation()

    const image = customImage({item: item.to, type: 'book'})

    return (
        <Link
            to={`/user/${item.to?.id}`}
            state={item.to}
            className="notification-item d-flex align-items-center"
        >
            <div className="img">
                <img src={image} alt={item.to?.firstName} />
            </div>
            <figcaption className="ps-3 w-100">
                <p className="fw-6 mb-1">{item.to?.firstName}</p>
                <p className="text-muted mb-1">{item.to?.about}</p>
            </figcaption>
        </Link>
    )
})

export default SubscriptionItem
