import React from 'react'
import {Outlet, ScrollRestoration, useParams} from 'react-router-dom'
import Footer from '../components/Footer'
import {Col} from 'react-bootstrap'
import Header from '../components/Header'

const AppLayout = () => {
    const {chapterId} = useParams()

    return (
        <>
            <ScrollRestoration />
            {!chapterId && <Header />}
            <main>
                <Col xxl={7} xl={9} lg={10} md={11} sm={12} className="px-3 m-auto">
                    <Outlet />
                </Col>
            </main>
            <Footer />
        </>
    )
}

export default AppLayout
