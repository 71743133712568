import moment from 'moment'
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {customImage} from '../helpers/all'

const NotificationItem = memo(({item}) => {
    const {t} = useTranslation()

    if (item?.type == 'like') {
        console.log(item)
        return (
            <Link
                to={`/public/${item?.bookId}/read/${item?.chapterId}/comment/${item?.commentId}`}
                className="notification-item d-flex align-items-center"
            >
                <div className="img">
                    <img src="/images/notification-like.png" />
                </div>
                <figcaption className="ps-3 w-100">
                    <p className="fw-6 mb-1">{t(item?.title)}</p>
                    <p className="text-muted mb-1">{item?.desc}</p>
                    <p className="fs-07 text-muted mb-0">{moment(item?.createdAt).format('DD MMMM YYYY kk:mm')}</p>
                </figcaption>
            </Link>
        )
    }

    if (item?.type == 'comment') {
        return (
            <Link
                to={`/public/${item?.bookId}/read/${item?.chapterId}/comment/${item?.commentId}`}
                className="notification-item d-flex align-items-center"
            >
                <div className="img">
                    <img src="/images/notification-comment.png" />
                </div>
                <figcaption className="ps-3 w-100">
                    <p className="fw-6 mb-1">{t(item?.title)}</p>
                    <p className="text-muted mb-1">{item?.desc}</p>
                    <p className="fs-07 text-muted mb-0">{moment(item?.createdAt).format('DD MMMM YYYY kk:mm')}</p>
                </figcaption>
            </Link>
        )
    }

    const image = customImage({item: item.book, type: 'book'})

    return (
        <Link
            to={
                item?.chapter?.id
                    ? `/public/${item.book?.uid ?? item.book.id}/read/${item.chapter.id}`
                    : item?.book?.id
                    ? `/public/${item.book?.uid ?? item.book.id}`
                    : ''
            }
            state={{item: item.book}}
            className="notification-item d-flex align-items-center"
        >
            <div className="img">
                <img src={image} alt={item?.title} />
            </div>
            <figcaption className="ps-3 w-100">
                <p className="fw-6 mb-1">{t(item?.title)}</p>
                <p className="text-muted mb-1">{item?.desc}</p>
                <p className="fs-07 text-muted mb-0">{moment(item?.createdAt).format('DD MMMM YYYY kk:mm')}</p>
            </figcaption>
        </Link>
    )
})

export default NotificationItem
