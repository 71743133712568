import React, {useCallback} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Meta from '../../../components/Meta'
import {editAccount} from '../../../services/account'
import {Badge, Col, Form, Row} from 'react-bootstrap'
import {setUser} from '../../../store/reducers/authSlice'
import {IoMoon, IoSunny} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import MenuProfile from '../../../components/MenuProfile'
import Button from '../../../components/UI/Button'
import {setTheme, updateOptions} from '../../../store/reducers/settingsSlice'

const Theme = () => {
    const {t} = useTranslation()

    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const theme = useSelector((state) => state.settings.theme)
    const options = useSelector((state) => state.settings.options)

    const {
        control,
        formState: {isValid, errors},
        handleSubmit,
        register,
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstName: user.firstName ?? '',
            phone: user.phone ?? '',
            sex: user.sex ?? '',
            nickname: user.nickname ?? '',
            about: user?.about ?? '',
            birthday: user?.birthday ?? '',
            telegram: {
                notification: user?.telegram?.notification ?? false,
            },
            options: {
                lang: user?.options?.lang ?? 'ru',
                city: user?.options?.city ?? '',
                country: user?.options?.country ?? '',
                avatar: user?.options?.avatar ?? 0,
            },
        },
    })
    const data = useWatch({control})

    const onSubmit = useCallback(
        (data) => {
            editAccount(data)
                .then((data) => {
                    data?.id && dispatch(setUser(data))
                    NotificationManager.success(t('Изменения успешно сохранены'))
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
            setEditAvatar(false)
        },
        [data]
    )

    return (
        <>
            <Meta title={t('Тема')} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3}>
                        <MenuProfile eventKey={3} />
                    </Col>
                    <Col>
                        <div className="box">
                            <h3 className="mb-3">{t('Тема')}</h3>
                            <Row>
                                <Col md={12}>
                                    <p>
                                        <a
                                            onClick={() =>
                                                dispatch(setTheme(!theme || theme == 'dark' ? 'light' : 'dark'))
                                            }
                                        >
                                            {!theme || theme == 'dark' ? (
                                                <div className="d-flex align-items-center sun">
                                                    <IoSunny className="me-2" size={22} />
                                                    <span className="fs-09 fw-5 line-height-100">
                                                        {t('Светлая')}
                                                        <Badge pill className="ms-2">
                                                            Beta
                                                        </Badge>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center moon">
                                                    <IoMoon className="me-2" size={22} />
                                                    <span className="fs-09 fw-5 line-height-100">{t('Темная')}</span>
                                                </div>
                                            )}
                                        </a>
                                    </p>
                                    <Form.Label>
                                        {t('Размер текста')}
                                        <Badge>{options?.fontSize}</Badge>
                                    </Form.Label>
                                    <Form.Range
                                        className="mb-4"
                                        value={options?.fontSize}
                                        onChange={(e) =>
                                            dispatch(updateOptions({...options, fontSize: Number(e.target.value)}))
                                        }
                                        step={1}
                                        min={15}
                                        max={19}
                                    />
                                    <Form.Label className="mb-3">{t('Цвет текста')}</Form.Label>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <a
                                            className={
                                                'color-item color-item-1' +
                                                (options?.color === '#fff' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#fff'}))}
                                        />
                                        <a
                                            className={
                                                'color-item color-item-2' +
                                                (options?.color === '#ddd' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#ddd'}))}
                                        />
                                        <a
                                            className={
                                                'color-item color-item-3' +
                                                (options?.color === '#999' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#999'}))}
                                        />
                                        <a
                                            className={
                                                'color-item color-item-4' +
                                                (options?.color === '#888' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#888'}))}
                                        />
                                        <a
                                            className={
                                                'color-item color-item-5' +
                                                (options?.color === '#777' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#777'}))}
                                        />
                                        <a
                                            className={
                                                'color-item color-item-6' +
                                                (options?.color === '#111' ? ' active' : '')
                                            }
                                            onClick={() => dispatch(updateOptions({...options, color: '#111'}))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default Theme
