import React, {memo} from 'react'
import Nav from 'react-bootstrap/Nav'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

const MenuProfile = memo(({eventKey = 1}) => {
    const {t} = useTranslation()
    return (
        <>
            <Nav className="profile-menu box mb-3 mb-sm-0" activeKey={eventKey}>
                <Nav.Item>
                    <Nav.Link as={Link} eventKey="1" to="/profile/edit">
                        {t('Основное')}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} eventKey="2" to="/profile/edit/sessions">
                        {t('История')}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} eventKey="3" to="/profile/edit/theme">
                        {t('Тема')}
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </>
    )
})

export default MenuProfile
