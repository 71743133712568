import React, {useCallback, useEffect} from 'react'
import {Alert, Badge, Col, Form, OverlayTrigger, Popover, Row, Tooltip} from 'react-bootstrap'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NotificationManager} from 'react-notifications'
import DragDropFile from '../components/DragDropFile'
import Meta from '../components/Meta'
import Button from '../components/UI/Button'
import Select from '../components/UI/Select'
import {ages, generateUrl, genres} from '../helpers/all'
import {IoHelpCircleOutline} from 'react-icons/io5'
import {createBook} from '../services/book'
import {useSelector} from 'react-redux'

const Add = () => {
    const {t} = useTranslation()
    const user = useSelector((state) => state.auth.user)

    const genresData = genres
        .map((e, index) => ({title: e.title, value: index}))
        .sort(function (a, b) {
            if (a.title < b.title) {
                return -1
            }
            if (a.title > b.title) {
                return 1
            }
            return 0
        })

    const {
        control,
        formState: {isValid, errors},
        handleSubmit,
        register,
        setValue,
        getValues,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            genre: [],
            options: {
                translation: user?.options?.type == 'translator' ? true : false,
            },
        },
    })

    const data = useWatch({control})

    const onSubmit = useCallback(
        (data) => {
            if (data?.tabs?.length > 10) {
                return NotificationManager.error(t('Максимально 10 тегов'))
            }
            if (data?.title?.length === 0) {
                return NotificationManager.error(t('Введите название'))
            }
            if (data?.desc?.length === 0) {
                return NotificationManager.error(t('Введите описание'))
            }
            if (!data?.age) {
                return NotificationManager.error(t('Укажите возростное ограничение'))
            }
            if (!data?.genre?.length === 0) {
                return NotificationManager.error(t('Укажите хотя бы один жанр'))
            }
            if (data?.options?.translation && !data?.author && !data?.source) {
                return NotificationManager.error(t('Укажите автора и источник'))
            }
            if (!data?.agree) {
                return NotificationManager.error(t('Подтвердите условия публикации'))
            }
            createBook(data)
                .then(() => {
                    NotificationManager.success(t('Публикация добавлена'))
                })
                .catch(
                    (error) =>
                        error?.response?.data?.error &&
                        typeof error?.response?.data?.error === 'string' &&
                        NotificationManager.error(error.response.data.error)
                )
        },
        [data]
    )

    const onGenre = useCallback(
        (value, index) => {
            if (data.genre && data.genre.includes(value)) {
                return NotificationManager.error(t('Данный жанр уже добавлен'))
            }
            setValue(`genre.${index}`, value)
        },
        [data]
    )

    const onGenreDelete = useCallback(
        (value) => {
            if (data.genre && data.genre.includes(value)) {
                let newArray = data.genre.filter((e) => e !== value)
                setValue('genre', newArray)
            }
        },
        [data]
    )

    useEffect(() => {
        setValue('uid', generateUrl(data.title))
    }, [data?.title])

    return (
        <>
            <Meta title={t('Новая публикация')} description={t('Публикация своих произведения')} />
            <Alert data-bs-theme="dark" variant="danger">
                <Alert.Heading>Доступ к публикация ограничен</Alert.Heading>
                Скоро данный раздел будет доступен для публикации всем пользователям.
            </Alert>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <Row>
                        <Col md="auto">
                            <div className="d-flex justify-content-center align-items-center position-sticky top-header flex-column text-center">
                                <DragDropFile
                                    col={12}
                                    className="book-image mb-1"
                                    title={t('Выберите обложку')}
                                    accept="image/png, image/jpeg, image/webp"
                                    aspect={330 / 500}
                                    maxAspect={330 / 500}
                                    file={getValues('file')}
                                    onChange={(e) => setValue('file', e)}
                                    onDelete={(e) => setValue('file', e.file)}
                                    info={
                                        <>
                                            <p className="fs-09 mb-2">
                                                {t('1. Изображения с возростным огранечением будут зацензурены.')}
                                            </p>
                                            <p className="fs-09 mb-2">
                                                {t('2. Только форматы jpg, jpeg, png, webp.')}
                                            </p>
                                            <p className="fs-09 mb-2">{t('3. Размер обложки 330х500.')}</p>
                                            <p className="fs-09 mb-0">{t('4. Максимальный размер файла 2 МБ.')}</p>
                                        </>
                                    }
                                />
                                <div className="fs-07 text-muted">330х500</div>
                            </div>
                        </Col>
                        <Col>
                            {/* <Col md={7}>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <DragDropFile
                                            title={t('Выберите файл (Word)')}
                                            accept="application/msword"
                                            info={
                                                <>
                                                    <p className="fs-09 mb-2">
                                                        {t(
                                                            '1. Вы можете разделить текст на главы, заголовками любого размера.'
                                                        )}
                                                    </p>
                                                    <p className="fs-09 mb-2">
                                                        {t('2. Только формат файлов word.')}
                                                    </p>
                                                    <p className="fs-09 mb-2">
                                                        {t('3. Максимальный размер файла 2 МБ.')}
                                                    </p>
                                                    <p className="fs-09 mb-0">
                                                        {t(
                                                            '4. Максимальное количество символов в одной главе 200 000.'
                                                        )}
                                                    </p>
                                                </>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col> */}
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Название')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Введите название')}
                                            isValid={data?.title && !errors.title}
                                            isInvalid={errors.title}
                                            maxLength={100}
                                            {...register('title', {
                                                required: 'Обязательное поле',
                                                maxLength: {value: 100, message: 'Максимальное кол-во символов 100'},
                                            })}
                                        />

                                        <div className="d-flex justify-content-between align-items-center text-muted">
                                            <div>
                                                {errors?.title?.message && (
                                                    <Form.Text className="text-danger">
                                                        {errors.title.message}
                                                    </Form.Text>
                                                )}
                                            </div>
                                            <div>
                                                <Form.Text>{data?.title?.length} из 100</Form.Text>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Ссылка')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('Введите ссылку')}
                                            isValid={data?.uid && !errors.uid}
                                            isInvalid={errors.uid}
                                            maxLength={100}
                                            minLength={3}
                                            {...register('uid', {
                                                setValueAs: (e) => e && e.trim().toLowerCase(),
                                                minLength: {
                                                    value: 3,
                                                    message: 'Минимально 3 символа',
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: 'Максимально 100 символов',
                                                },
                                                pattern: {
                                                    value: /^[a-z0-9_]+$/,
                                                    message: 'Неверный формат ссылки, только a-z0-9_)',
                                                },
                                            })}
                                        />
                                        {errors?.uid?.message && (
                                            <Form.Text className="text-danger">{errors.uid?.message}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Аннотация')}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={6}
                                            placeholder={t('Опишите кратко свое произведение...')}
                                            isValid={data?.desc && !errors.desc}
                                            isInvalid={errors.desc}
                                            maxLength={1000}
                                            {...register('desc', {
                                                required: 'Обязательное поле',
                                                maxLength: {
                                                    value: 1000,
                                                    message: 'Максимальное кол-во символов 1000',
                                                },
                                            })}
                                        />
                                        <div className="d-flex justify-content-between align-items-center text-muted">
                                            <div>
                                                {errors?.desc?.message && (
                                                    <Form.Text className="text-danger">
                                                        {errors.desc.message}
                                                    </Form.Text>
                                                )}
                                            </div>
                                            <div>
                                                <Form.Text>{data?.desc?.length} из 1000</Form.Text>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="d-flex justify-content-between">
                                                    {t('Возрастной рейтинг')}

                                                    <OverlayTrigger
                                                        trigger="click"
                                                        rootClose
                                                        placement="bottom"
                                                        overlay={
                                                            <Popover id="info" className="p-3 yes-select">
                                                                Список норм, которые регулируют доступ к информации,
                                                                основываясь на возрастной классификации
                                                            </Popover>
                                                        }
                                                    >
                                                        <a>
                                                            <IoHelpCircleOutline size={23} color="#999" />
                                                        </a>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Select
                                                    close={false}
                                                    title={t('Выберите рейтинг')}
                                                    data={ages}
                                                    value={data.age}
                                                    onClick={(e) => setValue('age', e.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Основной жанр')}</Form.Label>
                                                <Select
                                                    title={t('Выберите жанр')}
                                                    data={genresData}
                                                    value={data?.genre[0]}
                                                    onClick={(e) => onGenre(e.value, 0)}
                                                    onDelete={(e) => onGenreDelete(e.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {data?.genre && data?.genre[0] && (
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        {t('Дополнительный жанр (Необязательно)')}
                                                    </Form.Label>
                                                    <Select
                                                        title={t('Выберите жанр')}
                                                        data={genresData}
                                                        value={data.genre[1]}
                                                        onClick={(e) => onGenre(e.value, 1)}
                                                        onDelete={(e) => onGenreDelete(e.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                        {data?.genre && data?.genre[1] && (
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        {t('Дополнительный жанр (Необязательно)')}
                                                    </Form.Label>
                                                    <Select
                                                        title={t('Выберите жанр')}
                                                        data={genresData}
                                                        value={data.genre[2]}
                                                        onClick={(e) => onGenre(e.value, 2)}
                                                        onDelete={(e) => onGenreDelete(e.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>{t('Теги')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t('Вводите теги через запятую...')}
                                                    isInvalid={errors.tags}
                                                    maxLength={200}
                                                    {...register('tags', {
                                                        setValueAs: (e) =>
                                                            e
                                                                .trim()
                                                                .toLowerCase()
                                                                .split(',')
                                                                .filter((tag) => tag.trim() !== ''),
                                                        maxLength: {
                                                            value: 200,
                                                            message: 'Максимальное кол-во символов 200',
                                                        },
                                                    })}
                                                />
                                                {errors?.tags?.message && (
                                                    <Form.Text className="text-danger">
                                                        {errors.tags.message}
                                                    </Form.Text>
                                                )}
                                                {data?.tags?.length > 0 &&
                                                    data.tags.map((e, index) => (
                                                        <Badge
                                                            bg={index > 11 ? 'danger' : 'secondary'}
                                                            className="me-2 mt-2"
                                                        >
                                                            {e}
                                                        </Badge>
                                                    ))}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="translation"
                                                    className="d-inline-block"
                                                    label={t('Я делаю перевод')}
                                                    {...register('options.translation')}
                                                />
                                            </div>

                                            {data?.options?.translation && (
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t('Имя автора произведения')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={t('Введите имя автора')}
                                                                isValid={data?.author && !errors.author}
                                                                isInvalid={errors.author}
                                                                maxLength={100}
                                                                {...register('author', {
                                                                    required: 'Обязательное поле',
                                                                    maxLength: {
                                                                        value: 100,
                                                                        message: 'Максимальное кол-во символов 100',
                                                                    },
                                                                })}
                                                            />
                                                            {errors?.author?.message && (
                                                                <Form.Text className="text-danger">
                                                                    {errors.author.message}
                                                                </Form.Text>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>{t('Источник')}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={t('Введите источник')}
                                                                isValid={data?.source && !errors.source}
                                                                isInvalid={errors.source}
                                                                maxLength={100}
                                                                {...register('source', {
                                                                    required: 'Обязательное поле',
                                                                    maxLength: {
                                                                        value: 100,
                                                                        message: 'Максимальное кол-во символов 100',
                                                                    },
                                                                })}
                                                            />
                                                            {errors?.source?.message && (
                                                                <Form.Text className="text-danger">
                                                                    {errors.source.message}
                                                                </Form.Text>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="coauthor"
                                                    className="d-inline-block"
                                                    label={t(
                                                        data?.options?.translation ? 'Есть помощник' : 'Есть соавтор'
                                                    )}
                                                    {...register('options.coauthor')}
                                                />
                                            </div>

                                            {data?.options?.coauthor && (
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                {t('Никнейм, email или номер телефона')}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={t(
                                                                    'Введите никнейм, email или номер телефона'
                                                                )}
                                                                isValid={
                                                                    data?.options?.coauthor?.login &&
                                                                    !errors?.options?.coauthor?.login
                                                                }
                                                                isInvalid={errors?.options?.coauthor?.login}
                                                                maxLength={100}
                                                                {...register('options.coauthor.login', {
                                                                    required: 'Обязательное поле',
                                                                    maxLength: {
                                                                        value: 100,
                                                                        message: 'Максимальное кол-во символов 100',
                                                                    },
                                                                })}
                                                            />
                                                            {errors?.options?.coauthor?.login?.message ? (
                                                                <Form.Text className="text-danger">
                                                                    {errors.options.coauthor.login.message}
                                                                </Form.Text>
                                                            ) : (
                                                                <Form.Text>
                                                                    {t('Пользователь должен быть зарегистрирован')}
                                                                </Form.Text>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                        <Col md={12}>
                                            <Form.Label>{t('Доступ')}</Form.Label>
                                            <div className="d-flex mb-4">
                                                <Form.Check
                                                    type="radio"
                                                    value="free"
                                                    id="pay-1"
                                                    defaultChecked
                                                    className="me-3"
                                                    label={t('Бесплатно')}
                                                    {...register('pay')}
                                                />
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-free">
                                                            {t('Скоро будет доступно')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>
                                                        <Form.Check
                                                            disabled
                                                            type="radio"
                                                            value="paid"
                                                            id="pay-2"
                                                            label={t('Платно за главу')}
                                                            className="me-3"
                                                            {...register('pay')}
                                                        />
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-free">
                                                            {t('Скоро будет доступно')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>
                                                        <Form.Check
                                                            disabled
                                                            type="radio"
                                                            value="subscription"
                                                            id="pay-3"
                                                            label={t('По подписке')}
                                                            {...register('pay')}
                                                        />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Form.Check
                                type="checkbox"
                                id="public"
                                className="mb-3 d-inline-block"
                                label={t('Опубликовать сразу')}
                                defaultChecked={true}
                                {...register('public')}
                            />

                            <Form.Check className="mb-3 d-flex align-items-center">
                                <Form.Check.Input
                                    id="agree"
                                    isInvalid={errors.agree}
                                    defaultChecked
                                    {...register('agree', {required: t('Обязательное поле')})}
                                />
                                <Form.Check.Label htmlFor="agree" className="ms-2 fs-08">
                                    Я принимаю&nbsp;
                                    <a href="/term-author" target="_blank" className="text-decoration-underline">
                                        условия и правила публикации
                                    </a>
                                </Form.Check.Label>
                            </Form.Check>
                            <Button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                // disabled={!isValid}
                                disabled={true}
                                className="w-100 mt-3"
                            >
                                {t('Скоро будет доступно')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default Add
