import React, {memo} from 'react'
import {NavLink} from 'react-router-dom'
import {
    IoBook,
    IoBookOutline,
    IoHome,
    IoHomeOutline,
    IoImage,
    IoImageOutline,
    IoNotifications,
    IoNotificationsOutline,
    IoPerson,
    IoPersonOutline,
} from 'react-icons/io5'
import Container from 'react-bootstrap/Container'
import {customImage} from '../helpers/all'
import {useSelector} from 'react-redux'

const MobileNav = memo(() => {
    const user = useSelector((state) => state.auth.user)
    const isAuth = useSelector((state) => state.auth.isAuth)
    const image = customImage({item: user, type: 'user', size: 'mini'})
    const notification = useSelector((state) => state.notification.notification)
    const message = useSelector((state) => state.notification.message)
    return isAuth ? (
        <nav className="mobile-nav">
            <Container className="h-100 d-lg-flex align-items-center">
                <ul className="list-unstyled">
                    <li>
                        <NavLink to="/" children={({isActive}) => (isActive ? <IoHome /> : <IoHomeOutline />)} end />
                    </li>
                    <li>
                        <NavLink
                            to="/novella"
                            children={({isActive}) => (isActive ? <IoBook /> : <IoBookOutline />)}
                        />
                    </li>
                    <li>
                        <NavLink
                            to="/manhwa"
                            children={({isActive}) => (isActive ? <IoImage /> : <IoImageOutline />)}
                        />
                    </li>
                    <li>
                        <NavLink
                            to="/notifications"
                            children={({isActive}) => {
                                return (
                                    <div className="position-relative">
                                        {isActive ? <IoNotifications /> : <IoNotificationsOutline />}
                                        {notification > 0 && (
                                            <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill">
                                                {notification}
                                            </span>
                                        )}
                                    </div>
                                )
                            }}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={isAuth ? '/profile' : '/login'}
                            children={({isActive}) => {
                                return (
                                    <div className="position-relative">
                                        {isAuth ? (
                                            <img src={image} className="footer-avatar" />
                                        ) : isActive ? (
                                            <IoPerson />
                                        ) : (
                                            <IoPersonOutline />
                                        )}
                                        {message > 0 && (
                                            <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill">
                                                {message}
                                            </span>
                                        )}
                                    </div>
                                )
                            }}
                        />
                    </li>
                </ul>
            </Container>
        </nav>
    ) : (
        <nav className="mobile-nav">
            <Container className="h-100 d-lg-flex align-items-center">
                <ul className="list-unstyled">
                    <li>
                        <NavLink to="/" children={({isActive}) => (isActive ? <IoHome /> : <IoHomeOutline />)} end />
                    </li>
                    <li>
                        <NavLink
                            to="/novella"
                            children={({isActive}) => (isActive ? <IoBook /> : <IoBookOutline />)}
                        />
                    </li>
                    <li>
                        <NavLink
                            to="/manhwa"
                            children={({isActive}) => (isActive ? <IoImage /> : <IoImageOutline />)}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={isAuth ? '/profile' : '/login'}
                            children={({isActive}) => {
                                return (
                                    <div className="position-relative">
                                        {isAuth ? (
                                            <img src={image} className="footer-avatar" />
                                        ) : isActive ? (
                                            <IoPerson />
                                        ) : (
                                            <IoPersonOutline />
                                        )}
                                        {message > 0 && (
                                            <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill">
                                                {message}
                                            </span>
                                        )}
                                    </div>
                                )
                            }}
                        />
                    </li>
                </ul>
            </Container>
        </nav>
    )
})

export default MobileNav
