import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import {ClientJS} from 'clientjs'
import moment from 'moment'
import React, {useEffect, useLayoutEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import './assets/styles/style.min.css'
import AppRouter from './routes/AppRouter'
import {checkAuth} from './services/auth'
import {setAuth, setUser} from './store/reducers/authSlice'
import {setNotification, updateNotification} from './store/reducers/notificationSlice'
import socket from './config/socket'
import axios from 'axios'
import {updateIp} from './store/reducers/settingsSlice'

const App = () => {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.auth.token)
    const isAuth = useSelector((state) => state.auth.isAuth)
    const theme = useSelector((state) => state.settings.theme)
    const {i18n} = useTranslation()
    const client = new ClientJS()

    useEffect(() => {
        document.documentElement.dataset.theme = theme ?? 'dark'
    }, [theme])

    useLayoutEffect(() => {
        ;(async () => {
            await axios.get('https://ip.yooapp.ru').then(({data}) => data?.ip && dispatch(updateIp(data.ip)))

            if (token) {
                checkAuth().then((data) => {
                    i18n.changeLanguage(data?.options?.lang ?? client.getLanguage() ?? 'ru-RU')
                    moment.locale(data?.options?.lang ?? client.getLanguage() ?? 'ru-RU')

                    if (data?.notification || data?.message || data?.transaction) {
                        dispatch(
                            setNotification({
                                notification: data?.notification ?? 0,
                                message: data?.message ?? 0,
                                transaction: data?.transaction ?? 0,
                            })
                        )
                    }
                    data?.id && dispatch(setUser(data))
                    data?.id && dispatch(setAuth(true))
                })
            } else {
                i18n.changeLanguage(client.getLanguage() ?? 'ru-RU')
                moment.locale(client.getLanguage() ?? 'ru-RU')
            }
        })()
    }, [token])

    useEffect(() => {
        if (isAuth) {
            socket.on('notifications', (data) => {
                dispatch(updateNotification(data))
            })
            return () => {
                socket.off('notifications')
            }
        }
    }, [isAuth])

    return <AppRouter />
}
export default App
