import {apiRoutes} from '../config/api'
import {$api, $authApi} from './index'

const createGeneralMessage = async (data) => {
    const response = await $authApi.post(apiRoutes.GENERAL_MESSAGES, data)
    return response?.data
}
const createGeneralMessageReport = async (data) => {
    const response = await $authApi.post(apiRoutes.GENERAL_MESSAGES_REPORT, data)
    return response?.data
}
const editGeneralMessage = async (data) => {
    const response = await $authApi.put(apiRoutes.GENERAL_MESSAGES, data)
    return response?.data
}
const deleteGeneralMessage = async (data) => {
    const response = await $authApi.delete(apiRoutes.GENERAL_MESSAGES, {data})
    return response?.data
}
const getGeneralMessages = async (data, noAuth) => {
    const response = await $authApi.get(apiRoutes.GENERAL_MESSAGES, {params: data})
    return response?.data
}
const getGeneralMessage = async (data) => {
    const response = await $authApi.get(apiRoutes.GENERAL_MESSAGES, {params: data})
    return response?.data
}

export {
    getGeneralMessage,
    getGeneralMessages,
    deleteGeneralMessage,
    editGeneralMessage,
    createGeneralMessageReport,
    createGeneralMessage,
}
