import axios from 'axios'
import {ClientJS} from 'clientjs'
import {BASE_URL} from '../config/api'
import {store} from '../store'
import {logout, refreshAuth} from './auth'
import socket from '../config/socket'
import { setAuth, setToken, setUser } from '../store/reducers/authSlice'

const client = new ClientJS()

const DEVICE = JSON.stringify({
    brand: client.getBrowser(),
    osName: client.getOS(),
    osVersion: client.getOSVersion(),
    language: client.getLanguage() ?? 'ru-RU',
})

const $api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})

$api.interceptors.request.use(
    async (config) => {
        const state = store.getState()
        config.headers.ip = state?.settings?.ip ?? '0.0.0.0'
        config.headers.device = DEVICE
        return config
    },
    (error) => Promise.reject(error)
)

const $authApi = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})

$authApi.interceptors.request.use(
    async (config) => {
        const state = store.getState()
        config.headers.ip = state?.settings?.ip ?? '0.0.0.0'
        const token = state?.auth?.token ?? false
        if (token) {
            config.headers.authorization = `Access ${token}`
        }
        config.headers.device = DEVICE
        return config
    },
    (error) => Promise.reject(error)
)

$authApi.interceptors.response.use(
    (config) => {
        return config
    },
    async (error) => {
        const {config, response} = error
        const originalRequest = config
        if (response?.status === 401 && originalRequest && !originalRequest._isRetry) {
            originalRequest._isRetry = true
            return store.dispatch(refreshAuth()).then(() => $authApi(originalRequest))
        } else if (response?.status === 403) {
            store.dispatch(setUser(false))
            store.dispatch(setAuth(false))
            store.dispatch(setToken(false))
         
            return socket.disconnect()
        }
        return Promise.reject(error)
    }
)

export {$api, $authApi}
