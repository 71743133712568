import {memo} from 'react'
import Button from './UI/Button'
import {logout} from '../services/auth'
import {useDispatch} from 'react-redux'
import Header from './Header'
import Footer from './Footer'
import socket from '../config/socket'
import {setAuth, setToken, setUser} from '../store/reducers/authSlice'
import {useNavigate} from 'react-router-dom'

const Empty = memo(({text, mini, desc, image = false}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <main
                className={
                    'empty d-flex flex-column align-items-center justify-content-center' + (mini ? ' mini' : '')
                }
            >
                {image ? (
                    <div className="mb-4">{image()}</div>
                ) : (
                    <img src="/images/no-results.png" width={130} className="mb-4" />
                )}
                <div>
                    <p className="text-center h5">{text ?? 'Произошла непредвиденная ошибка'}</p>

                    {desc ? (
                        <p className="text-center text-muted">{desc}</p>
                    ) : (
                        <p className="text-center text-muted">
                            Попробуйте очистить кеш браузера. <br />
                            Если данная ошибка до сих пор остается, то попробуйте сбросить все данные. <br />
                            После данного действия, вам придется перезайти в свой профиль.
                        </p>
                    )}

                    <div className="d-flex justify-content-center mt-4">
                        <Button
                            className="btn-sm"
                            onClick={() =>
                                logout().finally(() => {
                                    socket.disconnect()
                                    dispatch(setToken(false))
                                    dispatch(setAuth(false))
                                    dispatch(setUser(false))
                                    localStorage.removeItem('persist:root')
                                    navigate('/')
                                })
                            }
                        >
                            Сбросить данные
                        </Button>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
})
export default Empty
