import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import translate from '../config/translate.json'

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: (cb) => {
        return cb('ru')
    },
    init: () => {},
    cacheUserLanguage: () => {},
}

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        compatibilityJSON: 'v3',
        resources: translate,
        fallbackLng: 'ru',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
