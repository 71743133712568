import React from 'react'
import Meta from '../components/Meta'

const About = () => {
    return (
        <>
            <Meta title="О нас" />
            <h2 className="fw-6 mb-4">
                <b className="main-color">Gikami</b> - мечтай, воображай, воплощай!
            </h2>
            <div className="text fs-11 mb-4">
                Gikami – это платформа, где твои идеи оживают. Хочешь поделиться своим стихом, опубликовать перевод
                любимого произведения, записать аудиокнигу собственного сочинения? Gikami – идеальное место для
                реализации твоих творческих замыслов.
                <br />
                <br />
                <b>С Gikami ты можешь:</b>
                <br />
                <br />
                1. Публиковать свои произведения: Делись стихами, прозой, эссе, переводами и другими творческими
                работами с аудиторией.
                <br />
                2. Создавать аудиокниги: Записывай свои произведения в формате аудиокниг и делись ими с миром.
                <br />
                3. Найти свою аудиторию: Получай обратную связь от читателей и слушателей, общайся с
                единомышленниками, становись частью творческого сообщества.
                <br />
                4. Зарабатывать на своем творчестве: Установи свою цену на произведения, монетизируй свои работы и
                получайте прибыль.
                <br />
                <br />
                Gikami – это больше, чем просто платформа. Это пространство, где идеи обретают жизнь, где голоса
                слышат, где таланты раскрываются. Присоединяйся к Gikami и стань частью творческой революции!
            </div>
        </>
    )
}

export default About
