import React, {useMemo, memo} from 'react'
import {useTranslation} from 'react-i18next'
import {HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight} from 'react-icons/hi2'

import {useSearchParams} from 'react-router-dom'
import Empty from './Empty'

const DataTable = memo(({data, footer, pagination, emptyText, paramsValue, renderItem = false}) => {
    const [searchParams, setSearchParams] = useSearchParams(paramsValue)
    const {t} = useTranslation()
    const footerView = useMemo(() => {
        return footer ? (
            <div className="p-4">{footer}</div>
        ) : (
            pagination && pagination.totalPages > 0 && (
                <div className="px-2 pt-3 fs-08 d-flex justify-content-center align-items-center sticky pagination">
                    <span className="me-4">
                        {t('Страница')} {pagination.currentPage} {t('из')} {pagination.totalPages}
                    </span>
                    <button className="me-2" onClick={() => setSearchParams(searchParams.delete('page'))}>
                        <HiChevronDoubleLeft
                            size={16}
                            className={Number(pagination.currentPage) <= 1 ? 'text-muted' : 'text-dark'}
                        />
                    </button>
                    <button
                        className="me-4"
                        onClick={() => {
                            if (Number(pagination.currentPage) >= 3) {
                                searchParams.set('page', Number(pagination.currentPage) - 1)
                            } else {
                                searchParams.delete('page')
                            }
                            setSearchParams(searchParams)
                        }}
                    >
                        <HiChevronLeft
                            size={16}
                            className={Number(pagination.currentPage) <= 1 ? 'text-muted' : 'text-dark'}
                        />
                    </button>
                    <button
                        className="me-2"
                        onClick={() => {
                            if (Number(pagination.currentPage) < Number(pagination.totalPages)) {
                                searchParams.set('page', Number(pagination.currentPage) + 1)
                                setSearchParams(searchParams)
                            }
                        }}
                    >
                        <HiChevronRight
                            size={16}
                            className={
                                Number(pagination.currentPage) >= Number(pagination.totalPages)
                                    ? 'text-muted'
                                    : 'text-dark'
                            }
                        />
                    </button>
                    <button
                        onClick={() => {
                            if (Number(pagination.currentPage) < Number(pagination.totalPages)) {
                                searchParams.set('page', pagination.totalPages)
                                setSearchParams(searchParams)
                            }
                        }}
                    >
                        <HiChevronDoubleRight
                            size={16}
                            className={
                                Number(pagination.currentPage) >= Number(pagination.totalPages)
                                    ? 'text-muted'
                                    : 'text-dark'
                            }
                        />
                    </button>
                </div>
            )
        )
    }, [pagination])

    const body = useMemo(() => {
        if (!data || data?.length === 0) {
            return <Empty />
        }
        return data && data.map((item, index) => renderItem && renderItem(item, index))
    }, [data])

    return (
        <>
            {body}
            {footerView}
        </>
    )
})
export default DataTable
