import {apiRoutes} from '../config/api'
import {$api, $authApi} from './index'

const getBook = async (id, auth) => {
    const response = auth
        ? await $authApi.get(apiRoutes.BOOK, {
              params: {id},
          })
        : await $api.get(apiRoutes.BOOK_NO_AUTH, {
              params: {id},
          })

    return response?.data
}

const createBookmark = async (id) => {
    const response = await $authApi.post(apiRoutes.BOOKMARK, {id})
    return response?.data
}

const createBook = async (data) => {
    const response = await $authApi.post(apiRoutes.BOOKS, data)
    return response?.data
}

export {getBook, createBookmark, createBook}
