import React, {useCallback, useLayoutEffect, useState} from 'react'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {IoEyeOffOutline, IoEyeOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Meta from '../../components/Meta'
import InputCode from '../../components/UI/InputCode'
import {Timer} from '../../helpers/timer'
import {authNewKeyRecovery, authPasswordRecovery} from '../../services/auth'

const RestorePassword = () => {
    const isAuth = useSelector((state) => state.auth.isAuth)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [endTimer, setEndTimer] = useState(false)
    const {
        control,
        formState: {isValid, errors},
        handleSubmit,
        setValue,
        register,
        reset,
    } = useForm({
        defaultValues: {
            step: 1,
            key: null,
        },
    })
    const data = useWatch({control})
    const [viewPassword, setViewPassword] = useState(false)
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

    useLayoutEffect(() => {
        if (isAuth) {
            navigate('/profile')
        }
    }, [])

    const onSubmit = useCallback((data) => {
        authPasswordRecovery(data)
            .then(() => {
                reset({...data, step: data.step + 1})
                data.step == 1 ||
                    (data.step == 3 &&
                        NotificationManager.success(
                            data.step == 1
                                ? 'Код подтверждения отправлен'
                                : data.step == 3 && 'Пароль успешно изменен'
                        ))
            })
            .catch((error) =>
                NotificationManager.error(
                    typeof error?.response?.data?.error === 'string'
                        ? error.response.data.error
                        : 'Неизвестная ошибка'
                )
            )
    }, [])

    const onNewKey = () => {
        authNewKeyRecovery(data)
        setEndTimer(false)
    }

    if (data.step === 2) {
        return (
            <main>
                <Container size="xxl">
                    <Row className="vh-100 justify-content-center align-items-center">
                        <Col sm={12} md={7} xl={6}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-center fw-6 mb-3">{t('Введите код подтверждения')}</h3>
                                <div className="box">
                                    <InputCode length={6} autoFocus={true} onChange={(e) => setValue('key', e)} />
                                    <Form.Group className="mb-3">
                                        {errors.key ? (
                                            <Form.Text className="text-danger">{errors.key?.message}</Form.Text>
                                        ) : (
                                            <Form.Text>
                                                {t('Мы отправили код подтверждения на указанную почту')}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                    {endTimer ? (
                                        <Button className="w-100 mb-3" onClick={() => onNewKey()}>
                                            {t('Отправить код повторно')}
                                        </Button>
                                    ) : (
                                        <Link className="btn btn-secondary w-100 mb-3">
                                            {t('Повторно отправить код через')}{' '}
                                            <Timer onEnd={() => setEndTimer(true)} /> {t('сек')}
                                        </Link>
                                    )}
                                    <Button
                                        type="submit"
                                        onPress={handleSubmit(onSubmit)}
                                        disabled={data?.key?.length < 6}
                                        className="w-100"
                                    >
                                        {t('Отправить')}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </main>
        )
    }

    if (data.step === 3) {
        return (
            <main>
                <Container size="xxl">
                    <Row className="vh-100 pt-3 justify-content-center align-items-center">
                        <Col sm={12} md={7} xl={6}>
                            <Form onSubmit={handleSubmit(onSubmit)} className="mobile-bottom-padding">
                                <h3 className="text-center fw-6 mb-3">{t('Придумайте пароль')}</h3>
                                <div className="box">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Пароль')}</Form.Label>
                                        <InputGroup className="password">
                                            <Form.Control
                                                type={viewPassword ? 'text' : 'password'}
                                                placeholder="Введите пароль"
                                                isValid={data.password && !errors.password}
                                                isInvalid={errors.password}
                                                {...register('password', {
                                                    required: 'Обязательное поле',
                                                    minLength: {value: 6, message: 'Минимальное кол-во символов 6'},
                                                    maxLength: {
                                                        value: 250,
                                                        message: 'Максимальное кол-во символов 250',
                                                    },
                                                    pattern: {
                                                        value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                                                        message:
                                                            'Пароль должен состоять не менее из 6 символов латинского алфавита, цифр и спецсимволов',
                                                    },
                                                })}
                                            />
                                            <Button
                                                variant="input"
                                                className="btn-input"
                                                onClick={() => setViewPassword(!viewPassword)}
                                            >
                                                {viewPassword ? (
                                                    <IoEyeOutline size={20} />
                                                ) : (
                                                    <IoEyeOffOutline size={20} />
                                                )}
                                            </Button>
                                        </InputGroup>
                                        {errors.password ? (
                                            <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                                        ) : (
                                            <Form.Text>
                                                {t(
                                                    'Пароль должен состоять не менее из 6 символов латинского алфавита, цифр и спецсимволов'
                                                )}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('Повторите пароль')}</Form.Label>
                                        <InputGroup className="password">
                                            <Form.Control
                                                type={viewConfirmPassword ? 'text' : 'password'}
                                                placeholder="Повторите пароль"
                                                isValid={data.confirmPassword && !errors.confirmPassword}
                                                isInvalid={errors.confirmPassword}
                                                {...register('confirmPassword', {
                                                    required: 'Обязательное поле',
                                                    validate: (value) =>
                                                        data.password === value || 'Пароли не совпадают',
                                                })}
                                            />
                                            <Button
                                                variant="input"
                                                className="btn-input"
                                                onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                                            >
                                                {viewConfirmPassword ? (
                                                    <IoEyeOutline size={20} />
                                                ) : (
                                                    <IoEyeOffOutline size={20} />
                                                )}
                                            </Button>
                                        </InputGroup>
                                        {errors.confirmPassword && (
                                            <Form.Text className="text-danger">
                                                {errors.confirmPassword?.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        disabled={!isValid}
                                        onClick={handleSubmit(onSubmit)}
                                        className="w-100"
                                    >
                                        {t('Отправить')}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </main>
        )
    }

    return (
        <>
            <Meta title={t('Восстановить пароль')} description="Восстановите пароль и начнинайте читать." />
            <Container size="xxl">
                <Row className="vh-100 justify-content-center align-items-center">
                    <Col sm={12} md={7} xl={6}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <h3 className="text-center fw-6 mb-3">{t('Восстановить пароль')}</h3>
                            <div className="box">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Введите email')}
                                        isValid={data.email && !errors.email}
                                        isInvalid={errors.email}
                                        {...register('email', {
                                            required: t('Обязательное поле'),
                                            minLength: {value: 3, message: 'Минимальное кол-во символов 3'},
                                            maxLength: {value: 100, message: 'Максимальное кол-во символов 100'},
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: 'Неверный формат Email',
                                            },
                                        })}
                                    />
                                    {errors.email ? (
                                        <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                    ) : (
                                        <Form.Text className="fs-08">
                                            На вашу почту будет отправлено письмо с инструкцией. Если вы не получили
                                            письмо, проверьте папку спам или напишите в тех поддержку.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                                <Button
                                    type="submit"
                                    disabled={!isValid}
                                    onClick={() => setValue('step', 1)}
                                    className="w-100"
                                >
                                    {t('Отправить')}
                                </Button>
                            </div>
                            <div className="d-block mt-4 text-center">
                                <Link to="/login">{t('Вспомнили пароль?')}</Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RestorePassword
