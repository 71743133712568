import React, {useLayoutEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Meta from '../../components/Meta'
import Loader from '../../components/UI/Loader'
import {getSubscriptions} from '../../services/user'
import {useSearchParams} from 'react-router-dom'
import DataTable from '../../components/DataTable'
import SubscriptionItem from '../../components/SubscriptionItem'

const Subscriptions = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {t} = useTranslation()

    const [data, setData] = useState({
        loading: true,
        items: [],
    })

    useLayoutEffect(() => {
        getSubscriptions({to: true}).then((res) => setData((prev) => ({...prev, ...res, loading: false})))
    }, [])

    if (data?.loading) {
        return <Loader full />
    }

    return (
        <>
            <Meta title={t('Мои подписки')} />
            <h3 className="mb-3">{t('Мои подписки')}</h3>
            <DataTable
                paramsValue={searchParams}
                data={data?.items}
                pagination={data?.pagination}
                renderItem={(item) => <SubscriptionItem item={item} />}
            />
        </>
    )
}

export default Subscriptions
