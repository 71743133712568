import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { cache } from '../services/cache'
import addressSlice from './reducers/addressSlice'
import authSlice from './reducers/authSlice'
import cartSlice from './reducers/cartSlice'
import chapterSlice from './reducers/chapterSlice'
import checkoutSlice from './reducers/checkoutSlice'
import notificationSlice from './reducers/notificationSlice'
import settingsSlice from './reducers/settingsSlice'

const rootReducer = combineReducers({
    chapter: chapterSlice,
    settings: settingsSlice,
    notification: notificationSlice,
    auth: authSlice,
    cart: cartSlice,
    checkout: checkoutSlice,
    address: addressSlice,
    [cache.reducerPath]: cache.reducer,
})

import { encryptTransform } from 'redux-persist-transform-encrypt'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
const encryptor = encryptTransform({ secretKey: 'gikami' })

const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(cache.middleware),
})
setupListeners(store.dispatch)
const persistor = persistStore(store)

export { persistor }
export default store
