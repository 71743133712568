import React, {memo} from 'react'

const Loader = memo(({className, full = false, button = false, size = 50, color, speed = '1s'}) => {
    const Icon = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
                {!button && <img src="/logo.png" width={25} height={25} className="position-absolute" />}
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width={button ? 25 : size}
                    height={button ? 25 : size}
                    viewBox="0 0 38 38"
                    stroke={color ? color : button ? '#fff' : '#f33344'}
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur={speed}
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
    if (full) {
        return (
            <div className="loader">
                <Icon />
            </div>
        )
    }
    return <Icon />
})

export default Loader
