import {apiRoutes} from '../config/api'
import {$api, $authApi} from './index'

const createComment = async (data) => {
    const response = await $authApi.post(apiRoutes.COMMENTS, data)
    return response?.data
}
const createCommentLike = async (data) => {
    const response = await $authApi.post(apiRoutes.COMMENTS_LIKE, data)
    return response?.data
}
const createCommentReport = async (data) => {
    const response = await $authApi.post(apiRoutes.COMMENTS_REPORT, data)
    return response?.data
}
const editComment = async (data) => {
    const response = await $authApi.put(apiRoutes.COMMENTS, data)
    return response?.data
}
const deleteComment = async (data) => {
    const response = await $authApi.delete(apiRoutes.COMMENTS, {data})
    return response?.data
}
const getComments = async (data, noAuth) => {
    const response = noAuth
        ? await $api.get(apiRoutes.COMMENTS_NO_AUTH, {params: data})
        : await $authApi.get(apiRoutes.COMMENTS, {params: data})
    return response?.data
}
const getComment = async (data) => {
    const response = await $authApi.get(apiRoutes.COMMENTS, {params: data})
    return response?.data
}

export {createComment, createCommentLike, createCommentReport, editComment, getComment, deleteComment, getComments}
