import {apiRoutes} from '../config/api'
import {$api, $authApi} from './index'

const getChapter = async (id, auth) => {
    const response = auth
        ? await $authApi.get(apiRoutes.CHAPTER, {
              params: {id},
          })
        : await $api.get(apiRoutes.CHAPTER_NO_AUTH, {
              params: {id},
          })

    return response?.data
}

const getChapters = async (bookId) => {
    const response = await $authApi.get(apiRoutes.CHAPTERS, {
        params: {bookId},
    })
    return response?.data
}

export {getChapter, getChapters}
