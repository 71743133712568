import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    options: {fontSize: 15, backgroundColor: null, color: null},
    ip: '0.0.0.0',
    theme: 'dark',
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateOptions: (state, action) => {
            state.options = action.payload
        },
        updateIp: (state, action) => {
            state.ip = action.payload
        },
        setTheme: (state, action) => {
            state.theme = action.payload
        },
    },
})

export const {updateOptions, updateIp, setTheme} = settingsSlice.actions

export default settingsSlice.reducer
