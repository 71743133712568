import 'moment/locale/ru'
import {createRoot} from 'react-dom/client'
import {NotificationContainer} from 'react-notifications'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import 'swiper/css'
import 'swiper/css/navigation'
import App from './App'
import './helpers/i18n'
import store, {persistor} from './store'

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
            <NotificationContainer />
        </PersistGate>
    </Provider>
)
