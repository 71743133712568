import {memo} from 'react'
import {Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Button from './UI/Button'
import {useTranslation} from 'react-i18next'

const Alert = memo(({title, desc, onClick, btnText, isLoading = false, ...props}) => {
    const {t} = useTranslation()
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title as="h5" className="fw-7" id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{desc}</Modal.Body>
            <Modal.Footer>
                <Link onClick={props.onHide} className="btn btn-secondary btn-sm">
                    {t('Отмена')}
                </Link>
                <Button isLoading={isLoading} className="btn btn-primary btn-sm" onClick={onClick}>
                    {t(btnText ?? 'Ок')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
})

export default Alert
