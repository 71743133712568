import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useSearchParams} from 'react-router-dom'
import DataTable from '../../components/DataTable'
import Empty from '../../components/Empty'
import Meta from '../../components/Meta'
import OrderItem from '../../components/OrderItem'
import Loader from '../../components/UI/Loader'
import {useGetOrdersQuery} from '../../services/cache'

const Orders = () => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const {data, error, isLoading} = useGetOrdersQuery(searchParams.get('page'))

    if (isLoading) {
        return <Loader full />
    }

    if (!data?.items || data?.items?.length === 0) {
        return (
            <Empty
                text={t('Покупок нет')}
                button={
                    <Link className="btn-primary" to="/">
                        {t('Перейти в каталог')}
                    </Link>
                }
            />
        )
    }

    return (
        <section className="notifications">
            <Meta title={t('История покупок')} />
            <h3 className="mb-3">{t('История покупок')}</h3>
            <DataTable
                paramsValue={searchParams}
                data={data.items}
                pagination={data.pagination}
                renderItem={(item) => <OrderItem item={item} />}
            />
        </section>
    )
}

export default Orders
