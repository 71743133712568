import React from 'react'
import {Modal} from 'react-bootstrap'
import {IoClose} from 'react-icons/io5'

const CustomModal = ({
    className = '',
    classNameBody = '',
    classNameFooter = '',
    setShow,
    show = false,
    size,
    classNameHeader,
    title,
    children,
    footer,
}) => {
    const closeModal = () => setShow(false)

    return (
        <Modal className={className} show={show} onHide={closeModal} centered size={size}>
            <Modal.Header closeButton className={classNameHeader}>
                {title ? <h5>{title}</h5> : null}
            </Modal.Header>
            <Modal.Body className={classNameBody}>{children}</Modal.Body>
            {footer && <Modal.Footer className={classNameFooter}>{footer}</Modal.Footer>}
        </Modal>
    )
}

export default CustomModal
