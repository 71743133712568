const SITE_URL = 'https://gik.pw'
const ADS_URL = 'https://ads.gik.pw'
const BASE_URL = 'https://api.gik.pw'
const IO_URL = 'https://io.gik.pw'
const DADATA_URL_STREET = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
const DADATA_URL_ADDRESS = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address'
const DADATA_TOKEN = '6487a33dcdff42183e8f0c4aa3ad43acdc5a7b62'
const FILE_URL = BASE_URL + '/file'
const S3_LINK = 'https://gikami.hb.bizmrg.com'
const S3 = {
    userAvatar: S3_LINK + '/user/avatar/',
}
const apiRoutes = {
    GET_PROMO: '/promo/one',

    REGISTRATION: '/auth/registration',
    ACTIVATE: '/auth/activate',
    NEW_KEY_ACTIVATE: '/auth/newKeyActivate',
    NEW_KEY_RECOVERY: '/auth/newKeyRecovery',
    LOGIN: '/auth/login',
    CHECK: '/auth/check',
    REFRESH: '/auth/refresh',
    LOGOUT: '/auth/logout',
    RECOVERY: '/auth/recovery',
    EDIT_EMAIL: '/user/editEmail',
    LOGIN_TELEGRAM: '/auth/loginTelegram',
    LOGIN_VK: '/auth/loginVk',

    SAVE_PUSHTOKEN: '/auth/pushToken',

    USER_EDIT: '/user/edit',
    ADS: '/ads',
    ORDERS: '/order',
    ORDER: '/order/one',

    USER: '/user/',

    SESSIONS: '/user/session',

    NOTIFICATIONS: '/notification',

    HOME: '/category/home',

    CATEGORIES: '/category',
    CATEGORY: '/category/one',

    BOOKS: '/book',
    BOOK: '/book/one',

    BOOKMARK: '/book/bookmark',
    BOOKMARKS: '/book/bookmark/all',

    CHAPTER: '/chapter/one',
    CHAPTERS: '/chapter',

    CART: '/cart',

    MESSAGES: '/message',
    MESSAGE: '/message/one',

    GENERAL_MESSAGES: '/generalMessage',
    GENERAL_MESSAGE: '/generalMessage/one',
    GENERAL_MESSAGES_REPORT: '/generalMessage/report',

    COMMENTS: '/comment',
    COMMENT: '/comment/one',

    COMMENTS_LIKE: '/comment/like',
    COMMENTS_REPORT: '/comment/report',

    SEARCH: '/search',

    SALES: '/sale',
    SALES_PRODUCTS: '/sale/products',
    SALE: '/sale/one',
    GIFTS: '/sale/gifts',

    FAVORITES: '/favorite',

    DOCUMENTS: '/document',
    DOCUMENT: '/document/one',

    TRANSACTIONS: '/transaction',

    ACHIEVEMENT: '/achievement/one',
    ACHIEVEMENTS: '/achievement',

    // No auth
    BOOK_NO_AUTH: '/book/noauth/one',
    CHAPTER_NO_AUTH: '/chapter/noauth/one',
    COMMENTS_NO_AUTH: '/comment/noauth',
    CATEGORIES_NO_AUTH: '/category/noauth',
    USER_NO_AUTH: '/user/noauth',

    PUBLIC: '/user/public',

    USER_SUBSCRIPTION: '/user/subscription',

    SUBSCRIPTION: '/subscription',
}

export {
    ADS_URL,
    IO_URL,
    BASE_URL,
    SITE_URL,
    FILE_URL,
    DADATA_URL_STREET,
    DADATA_URL_ADDRESS,
    DADATA_TOKEN,
    apiRoutes,
    S3,
    S3_LINK,
}
