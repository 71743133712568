import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import DataTable from '../components/DataTable'
import Empty from '../components/Empty'
import Meta from '../components/Meta'
import NotificationItem from '../components/NotificationItem'
import Loader from '../components/UI/Loader'
import {useGetNotificationsQuery} from '../services/cache'
import {updateNotification} from '../store/reducers/notificationSlice'

const Notifications = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {data, error, isLoading} = useGetNotificationsQuery(searchParams.get('page'))

    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateNotification({notification: -1}))
    }, [])

    if (isLoading) {
        return <Loader full />
    }

    if (!data) {
        return (
            <Empty
                text={t('Уведомлений нет')}
                desc={t('Пока ничего не происходило')}
                button={
                    <Link className="btn-primary" onClick={() => onLoad()}>
                        {t('Обновить страницу')}
                    </Link>
                }
            />
        )
    }

    return (
        <section className="notifications">
            <Meta title={t('Уведомления')} />
            <h3 className="mb-3">{t('Уведомления')}</h3>
            <DataTable
                paramsValue={searchParams}
                data={data?.items}
                pagination={data?.pagination}
                renderItem={(item) => <NotificationItem item={item} />}
            />
        </section>
    )
}

export default Notifications
