import {memo} from 'react'

const Empty = memo(({text, mini, desc, image = false, button}) => {
    return (
        <main
            className={'empty d-flex flex-column align-items-center justify-content-center' + (mini ? ' mini' : '')}
        >
            {image ? (
                <div className="mb-4">{image()}</div>
            ) : (
                <img src="/images/no-results.png" width={130} className="mb-4" />
            )}
            <div>
                <p className="text-center h5">{text ?? 'Ничего не найдено'}</p>
                {desc && <p className="text-center text-muted">{desc}</p>}
                {button && <div className="d-flex justify-content-center mt-4">{button}</div>}
            </div>
        </main>
    )
})
export default Empty
