import {apiRoutes} from '../config/api'
import {$authApi} from './index'

const getBookmarks = async () => {
    const response = await $authApi.get(apiRoutes.BOOKMARKS)
    return response?.data
}
const getBookmark = async (data) => {
    const response = await $authApi.get(apiRoutes.BOOKMARK, {params: data})
    return response?.data
}

export {getBookmarks, getBookmark}
