import React, {memo} from 'react'
// import {LazyLoadImage} from 'react-lazy-load-image-component'
import {AiFillStar} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {customImage} from '../helpers/all'
import Age from './Age'
import Status from './Status'
import Type from './Type'

const BookItem = memo(({item}) => {
    const image = customImage({item, type: 'book'})
    return (
        <div className="product-item">
            <Link to={`/public/${item?.uid ?? item.id}`} state={item} className="stretched-link">
                <div className="img">
                    <div className="info-item d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                            {item?.type && <Type fixed value={item.type} />}
                            {item?.age && <Age fixed value={item.age} />}
                        </div>
                        {item?.chapters?.length > 0 && item?.chapters[0]?.start && (
                            <Status value={item.chapters[0].start} />
                        )}
                    </div>
                    <div className="info-item-bottom">
                        <span className="star d-inline-flex align-items-center line-height-100 me-1">
                            <AiFillStar className="icon fs-08" />
                            <span className="fw-6 fs-08 d-flex ms-1 text-white">{item?.rating ?? 0}</span>
                        </span>
                    </div>
                    <img src={image} alt={item?.title} />
                </div>
                <figcaption>{item?.title}</figcaption>
            </Link>
        </div>
    )
})

export default BookItem
