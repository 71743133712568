import React from 'react'
import {Navigate} from 'react-router-dom'
import {useSelector} from 'react-redux'

const AuthRoute = ({children, activate = false}) => {
    const user = useSelector((state) => state.auth.user)
    return user?.id ? activate && user?.status === 0 ? <Navigate to="/activate" /> : children : <Navigate to="/" />
}

export default AuthRoute
