import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import DataTable from '../../components/DataTable'
import Empty from '../../components/Empty'
import Meta from '../../components/Meta'
import TransactionItem from '../../components/TransactionItem'
import Loader from '../../components/UI/Loader'
import socket from '../../config/socket'
import {useGetTransactionsQuery} from '../../services/cache'
import {updateNotification} from '../../store/reducers/notificationSlice'

const Transactions = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {data, error, isLoading, refetch} = useGetTransactionsQuery(searchParams.get('page'))
    const {t} = useTranslation()
    const user = useSelector((state) => state.auth.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateNotification({transaction: -1}))
    }, [])

    useEffect(() => {
        socket.on('transactions/' + user.id, (data) => {
            if (data?.transactions?.length > 0) {
                refetch()
            }
        })
        return () => {
            socket.off('transactions/' + user.id)
        }
    }, [])

    if (isLoading) {
        return <Loader full />
    }

    if (!data?.items || data?.items?.length === 0) {
        return (
            <Empty
                text={t('Операций нет')}
                button={
                    <Link className="btn-primary" onClick={() => navigate(-1)}>
                        {t('Вернуться назад')}
                    </Link>
                }
            />
        )
    }

    return (
        <section className="notifications">
            <Meta title={t('История операций')} />
            <h3 className="mb-3">{t('История операций')}</h3>
            <DataTable
                paramsValue={searchParams}
                data={data.items}
                pagination={data.pagination}
                renderItem={(item) => <TransactionItem item={item} />}
            />
        </section>
    )
}

export default Transactions
