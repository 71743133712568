import React from 'react'
import Meta from '../../components/Meta'

const Dmca = ({noAuth = false}) => {
    return (
        <>
            <Meta title="Оферта" />
            <h1>DMCA</h1>
            <div className="text mb-5">
                <p>
                    Digital Millennium Copyright Act ("DMCA") Policy
                    <br />
                    gik.pw is an online service provider as defined in the Digital Millennium Copyright Act. We
                    provide legal copyright owners with the ability to self-publish on the internet by uploading,
                    storing and displaying various media utilizing our services. We do not monitor, screen or
                    otherwise review the media which is uploaded to our servers by users of the service. We take
                    copyright violation very seriously and will vigorously protect the rights of legal copyright
                    owners. If you are the copyright owner of content which appears on the gik.pw website and
                    you did not authorize the use of the content you must notify gik.pw in writing in order for
                    us to identify the allegedly infringing content and take action.
                    <br />
                    <br />
                    <b>I. DESIGNATED AGENT</b>
                    <br />
                    The Company's Designated Agent to receive notification of alleged infringement under the DMCA is:
                    <br />
                    Email: info@gik.pw
                    <br />
                    Upon receipt of proper notification of claimed infringement, Company will follow the procedures
                    outlined herein and in the DMCA.
                    <br />
                    <br />
                    <b>II. COMPLAINT NOTICE PROCEDURES FOR COPYRIGHT OWNERS</b>
                    <br />
                    The following elements must be included in your copyright infringement claim:
                    <br />
                    1. An electronic or physical signature of the copyright owner or a person authorized to act on
                    behalf of the owner of an exclusive right that is allegedly infringed.
                    <br />
                    2. Identification of the copyrighted work claimed to have been infringed, or if multiple
                    copyrighted works at a single online site are covered by a single notice, a representative list
                    of such works at that site.
                    <br />
                    3. Identification of the material that is claimed to be infringing or to be the subject of
                    infringing activity and that is to be removed or access to which is to be disabled, and
                    information reasonably sufficient to permit the Company to locate the material.
                    <br />
                    4. Information reasonably sufficient to permit the Company to contact the complaining party, such
                    as an address, telephone number, and, if available, an electronic mail address at which the
                    complaining party may be contacted.
                    <br />
                    5. A statement that the complaining party has a good faith belief that use of the material in the
                    manner complained of is not authorized by the copyright owner, its agent, or the law.
                    <br />
                    6. A statement that the information in the notice is accurate, and under penalty of perjury, that
                    the complaining party is authorized to act on behalf of the owner of an exclusive right that is
                    allegedly infringed.
                    <br />
                    Failure to include all of the above information may result in a delay of the processing or the
                    DCMA notification.
                    <br />
                    <br />
                    <b>III. NOTICE AND TAKE DOWN</b>
                    <br />
                    Procedure: It is expected that all users of any part of the Company system will comply with
                    applicable copyright laws. However, if the Company receives proper notification of claimed
                    copyright infringement it will respond expeditiously by removing, or disabling access to, the
                    material that is claimed to be infringing or to be the subject of infringing activity provided
                    all such claims have been investigated and determined to be valid by Company in Company's sole
                    and absolute discretion. Company will comply with the appropriate provisions of the DMCA in the
                    event a counter notification is received.
                    <br />
                    <br />
                    <b>IV. REPEAT INFRINGES</b>
                    <br />
                    Under appropriate circumstances, Company may, in its discretion, terminate authorization of users
                    of its system or network who are repeat infringes.
                    <br />
                    <br />
                    <b>V. ACCOMMODATION OF STANDARD TECHNICAL MEASURES</b>
                    <br />
                    It is Company policy to accommodate and not interfere with standard technical measures it
                    determines are reasonable under the circumstances, i.e., technical measures that are used by
                    copyright owners to identify or protect copyrighted works.
                </p>
            </div>
        </>
    )
}

export default Dmca
