import moment from 'moment'
import React, {memo} from 'react'
import {Link} from 'react-router-dom'
import {customPrice} from '../helpers/all'

const OrderItem = memo(({item = null}) => {
    return (
        <div className="transaction-item d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <div className="img">
                    {item?.status ? <img src="/images/yes.png" /> : <img src="/images/no.png" />}
                </div>
                <div className="ps-3">
                    <Link className="stretched-link" to={`/chapter/${item.chapterId}`} >
                        <p className="fw-6 mb-2">
                            {item.book}
                            {item.chapter ? ' - ' + item.chapter : null}
                        </p>
                        <p className="fs-09 text-muted mb-0">{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                    </Link>
                </div>
            </div>
            <div className="fw-6">{customPrice({value: item.total, size: 19, color: '#fff'})}</div>
        </div>
    )
})

export default OrderItem
