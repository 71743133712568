import React, {useState, useCallback} from 'react'
import {Col, Modal, Row, Form, NavLink, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {
    IoBookOutline,
    IoBookmarkOutline,
    IoBriefcaseOutline,
    IoCardOutline,
    IoChatbubbleOutline,
    IoChevronForwardOutline,
    IoLogOutOutline,
    IoPeopleCircleOutline,
    IoPeopleOutline,
    IoRibbonOutline,
    IoSettingsOutline,
} from 'react-icons/io5'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Meta from '../../components/Meta'
import Button from '../../components/UI/Button'
import {customImage, customPrice, declination, generateNumber} from '../../helpers/all'
import {logout} from '../../services/auth'
import socket from '../../config/socket'
import {setAuth, setToken, setUser} from '../../store/reducers/authSlice'
import Alert from '../../components/Alert'
import {useForm, useWatch} from 'react-hook-form'
import {createTransaction} from '../../services/transaction'
import {ClientJS} from 'clientjs'

const Profile = () => {
    const {t} = useTranslation()
    const client = new ClientJS()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.auth.user)
    const message = useSelector((state) => state.notification.message)
    const order = useSelector((state) => state.notification.order)
    const transaction = useSelector((state) => state.notification.transaction)
    const image = customImage({item: user, type: 'user', size: 'mini'})
    const [loading, setLoading] = useState(false)
    const [exitShow, setExitShow] = useState(false)
    const [payShow, setPayShow] = useState(false)
    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
    })

    const form = useWatch({control})

    const onPay = useCallback(
        (data) => {
            setLoading(true)
            createTransaction(data)
                .then((res) => res?.link && window.location.replace(res.link))
                .finally(() => setLoading(false))

            socket.on('pay/' + user.id, (e) => {
                if (e?.transaction?.status) {
                    setStatus(e.transaction.status)
                }
                if (e?.user?.id) {
                    dispatch(setUser(e.user))
                }
            })
            return () => {
                socket.off('pay/' + user.id)
            }
        },
        [user]
    )

    return (
        <>
            <Meta title="Профиль" />
            <div className="mb-4 box">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex w-100 align-items-center">
                        <div className="pe-3">
                            <div className="profile-avatar">
                                <img src={image} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <h3 className="mb-1">
                                    {user?.firstName ? user.firstName : 'id' + user.id}
                                    {user?.options?.type && user?.options?.type != 'user' && (
                                        <OverlayTrigger
                                            key="verified"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="tooltip-verified">
                                                    {t('Подтвержденный аккаунт')}
                                                </Tooltip>
                                            }
                                        >
                                            <img className="ms-2" width={20} src="/images/verified.svg" />
                                        </OverlayTrigger>
                                    )}
                                </h3>
                                <p className="mb-0 text-muted">
                                    {user?.about?.length > 0
                                        ? user.about
                                        : user?.phone?.length > 0
                                        ? user.phone.replace(/^(.{2})([0-9]{5})/, '$1*****')
                                        : user?.email}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to="/profile/edit" className="icon-hover">
                            <IoSettingsOutline size={25} />
                        </Link>
                    </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="px-1 text-center">
                        <b>{generateNumber(user.publicCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                user.publicCount,
                                ['Публикация', 'Публикации', 'Публикаций'].map((e) => t(e))
                            )}
                        </div>
                    </div>
                    <Link className="px-1 text-center" to="subscriptions">
                        <b>{generateNumber(user.subscribersCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                user.subscribersCount,
                                ['Подписчик', 'Подписчика', 'Подписчиков'].map((e) => t(e))
                            )}
                        </div>
                    </Link>
                    <Link className="px-1 text-center" to="subscriptions">
                        <b>{generateNumber(user.subscriptionsCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(
                                user.subscriptionsCount,
                                ['Подписка', 'Подписки', 'Подписок'].map((e) => t(e))
                            )}
                        </div>
                    </Link>
                    <div className="px-1 text-center">
                        <b>{generateNumber(user.viewCount)}</b>
                        <div className="fs-08 text-muted">
                            {declination(user.viewCount, ['Просмотр', 'Просмотра', 'Просмотров'])}
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-card mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <div>
                            <p className="fs-08 mb-1">{t('Ваш баланс')}</p>
                            <h3 className="mb-0">{customPrice({value: user?.price, color: '#fff', size: 21})}</h3>
                        </div>
                        {user?.options?.type != 'user' && (
                            <div className="ms-4">
                                <p className="fs-08 mb-1">{t('Ваш доход')}</p>
                                <h3 className="mb-0">{customPrice({value: user?.income, currency: '₽'})}</h3>
                            </div>
                        )}
                    </div>
                    <div>
                        <Button className="btn-sm btn-white" onClick={() => setPayShow(true)}>
                            {t('Пополнить')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-4 box px-0">
                {user?.options?.type != 'user' && (
                    <>
                        <Link
                            to="public"
                            className="d-flex flex-row align-items-center justify-content-between link"
                        >
                            <span className="d-flex flex-row align-items-center">
                                <IoBriefcaseOutline className="me-3" size={20} />
                                {t('Мои публикации')}
                            </span>
                            <div className="d-flex flex-row align-items-center">
                                <IoChevronForwardOutline />
                            </div>
                        </Link>
                    </>
                )}
                <Link to="subscriptions" className="d-flex flex-row align-items-center justify-content-between link">
                    <span className="d-flex flex-row align-items-center">
                        <IoPeopleOutline className="me-3" size={20} />
                        {t('Мои подписки')}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                        <IoChevronForwardOutline />
                    </div>
                </Link>
                <Link to="orders" className="d-flex flex-row align-items-center justify-content-between link">
                    <span className="d-flex flex-row align-items-center">
                        <IoBookOutline className="me-3" size={20} />
                        {t('История покупок')}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                        {order > 0 && <Badge className="me-3">{order}</Badge>}
                        <IoChevronForwardOutline />
                    </div>
                </Link>
                <Link to="transactions" className="d-flex flex-row align-items-center justify-content-between link">
                    <span className="d-flex flex-row align-items-center">
                        <IoCardOutline className="me-3" size={20} />
                        {t('История пополнений')}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                        {transaction > 0 && <Badge className="me-3">{transaction}</Badge>}
                        <IoChevronForwardOutline />
                    </div>
                </Link>
                <NavLink
                    disabled
                    to="bookmarks"
                    className="text-muted d-flex flex-row align-items-center justify-content-between link"
                >
                    <span className="d-flex flex-row align-items-center">
                        <IoBookmarkOutline className="me-3" size={20} />
                        {t('Избранное')}
                    </span>
                    <IoChevronForwardOutline />
                </NavLink>
                <NavLink
                    disabled
                    className="text-muted d-flex flex-row align-items-center justify-content-between link"
                >
                    <span className="d-flex flex-row align-items-center">
                        <IoRibbonOutline className="me-3" size={20} />
                        {t('Достижения')}
                    </span>
                    <IoChevronForwardOutline />
                </NavLink>
                <Link to="support" className="d-flex flex-row align-items-center justify-content-between link">
                    <span className="d-flex flex-row align-items-center">
                        <IoChatbubbleOutline className="me-3" size={20} />
                        {t('Чат с поддержкой')}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                        {message > 0 && <Badge className="me-3">{message}</Badge>}
                        <IoChevronForwardOutline />
                    </div>
                </Link>

                <a
                    onClick={() => setExitShow(!exitShow)}
                    className="d-flex flex-row align-items-center justify-content-between link text-danger"
                >
                    <span className="d-flex flex-row align-items-center">
                        <IoLogOutOutline className="me-3" size={20} />
                        {t('Выйти из аккаунта')}
                    </span>
                    <IoChevronForwardOutline />
                </a>
            </div>

            <div className="mt-3">
                {client.isMobileAndroid() && (
                    <a
                        href="https://play.google.com/store/apps/details?id=ru.gikami.app"
                        target="_blank"
                        className="d-flex d-md-none align-items-center justify-content-between app-link mb-3 line-height-100"
                    >
                        <div className="d-flex d-md-none align-items-center">
                            <div className="me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path
                                        d="M15.3099 12.7188L14.0282 11.386L9.78055 15.9436L15.3099 12.7188Z M16.958 11.7576L17.0071 11.729C18.3303 10.9572 18.3303 9.04538 17.0071 8.27366L16.9564 8.2441L15.3199 10L16.958 11.7576Z M15.3082 7.28288L9.78589 4.06215L14.0282 8.61402L15.3082 7.28288Z M4.04121 1.00037L12.6954 10L4.03865 19.0023C2.98068 19.0214 2 18.1809 2 16.9996V3.00301C2 1.82076 2.98226 0.979819 4.04121 1.00037Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <div>
                                <p className="mb-0 fs-07 light-gray">{t('Доступно в')}</p>
                                <p className="mb-0 fs-08 fw-6">Google Play</p>
                            </div>
                        </div>
                        <IoChevronForwardOutline />
                    </a>
                )}
            </div>
            <Alert
                show={exitShow}
                title={t('Подтверждение')}
                desc={t('Вы точно хотите выйти из аккаунта?')}
                onHide={() => setExitShow(!exitShow)}
                btnText="Выйти"
                onClick={() => {
                    logout().finally(() => {
                        socket.disconnect()
                        dispatch(setToken(false))
                        dispatch(setAuth(false))
                        dispatch(setUser(false))
                        setExitShow(false)
                        navigate('/')
                    })
                }}
            />
            <Modal show={payShow} onHide={setPayShow} centered>
                <Modal.Header closeButton closeVariant="white">
                    <Modal.Title as="h5" className="fw-7">
                        {t('Пополнить счет')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{customPrice({value: 1, color: '#fff', size: 17})} = 1₽</p>
                    <Form.Group className="mb-3">
                        <Form.Control
                            autoFocus
                            type="number"
                            placeholder={t('Введите сумму пополнения')}
                            isValid={getValues('price') && !errors.price}
                            isInvalid={errors.login}
                            {...register('price', {
                                required: t('Обязательное поле'),
                                max: {value: 50000, message: `${t('Максимум')} 50 000`},
                                min: {value: 1, message: `${t('Минимум')} 1`},
                            })}
                        />
                        {errors.price && <Form.Text className="text-danger">{errors.price?.message}</Form.Text>}
                    </Form.Group>
                    <Form.Check className="d-flex align-items-center">
                        <Form.Check.Input
                            id="agree"
                            isInvalid={errors.agree}
                            defaultChecked
                            {...register('agree', {required: t('Обязательное поле')})}
                        />
                        <Form.Check.Label htmlFor="agree" className="ms-3 fs-08">
                            {t('Я согласен(-на) с условиями')}&nbsp;
                            <a className="text-decoration-underline" target="_blank" href="/offer">
                                {t('договора оферты')}
                            </a>
                        </Form.Check.Label>
                    </Form.Check>
                </Modal.Body>
                <Modal.Footer>
                    <Link onClick={() => setPayShow(false)} className="btn btn-secondary btn-sm">
                        {t('Отмена')}
                    </Link>
                    <Button
                        isLoading={loading}
                        disabled={!isValid}
                        onClick={handleSubmit(onPay)}
                        className="btn btn-primary btn-sm"
                    >
                        {t('Перейти к оплате')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Profile
