import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import Meta from '../components/Meta'

const NotFound = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <Meta title={t('Страница не найдена')} />
            <div>
                <h2 className="text-center fs-40 mb-4">404</h2>
                <h1 className="text-center">{t('Страница не найдена')}</h1>
                <div className="d-flex">
                    <a onClick={() => navigate(-1)} className="btn btn-dark mx-auto">
                        {t('Вернуться назад')}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NotFound
